import fb from '@/utils/firebase';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export const signOut = createAsyncThunk('user/logout', () => fb.auth.signOut());

const settings = {
  pomodoroWorkTime: 45,
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: settings,
  reducers: {},
});

export default settingsSlice;
