import React, { useState } from 'react';
import { Subtask } from '@/types/task.type';
import { Box, Center, CloseIcon, IconButton, Input, Row } from 'native-base';
import produce from 'immer';

type SubtaskItemProps = {
  subtask: Subtask;
  onRemove: (subtask: Subtask) => void;
  onChange: (subtask: Subtask) => void;
};

const SubtaskItem = ({ subtask, onRemove, onChange }: SubtaskItemProps) => {
  const [title, setTitle] = useState(subtask.title);

  const onEndEditing = () => {
    onChange({ ...subtask, title });
  };

  const onRemovePress = () => {
    onRemove({ ...subtask, title });
  };

  return (
    <Row justifyContent={'space-between'}>
      <Row flex={1}>
        <Input
          variant="unstyled"
          value={title}
          onChangeText={setTitle}
          fontSize="md"
          onBlur={onEndEditing}
          textDecorationLine={
            subtask.status === 'completed' ? 'line-through' : 'none'
          }
          blurOnSubmit
          mx="2"
          flex="1"
        />
      </Row>

      <Center>
        <IconButton onPress={onRemovePress} icon={<CloseIcon />} />
      </Center>
    </Row>
  );
};

type Props = {
  subtasks: Subtask[];
  setSubtasks: (subtasks: Subtask[]) => void;
};

export default function SubtasksEditor({ subtasks, setSubtasks }: Props) {
  const onChange = (subtask: Subtask) => {
    setSubtasks(
      produce(subtasks, (draft) => {
        const idx = draft.findIndex((item) => item.title === subtask.title);
        draft[idx] = subtask;
      }),
    );
  };
  const onRemove = (subtask: Subtask) => {
    setSubtasks(
      produce(subtasks, (draft) => {
        return draft.filter((item) => item.title !== subtask.title);
      }),
    );
  };

  return (
    <Box>
      {subtasks.map((item, index) => (
        <SubtaskItem
          key={index}
          subtask={item}
          onChange={onChange}
          onRemove={onRemove}
        />
      ))}
    </Box>
  );
}
