import React, { useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { Box, Button, Image, Row, Text } from 'native-base';
import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
  DrawerItemList,
} from '@react-navigation/drawer';
import { Appbar } from 'react-native-paper';

import TabNavigator from './TabNavigator';
import SettingsContainer from '@/Containers/Settings';
import { IntroContainer } from '@/Containers';
import AppStateExample from '@/Containers/Dev/Appstate';
import DevScreen from '@/Containers/DevContainer';
import * as Updates from 'expo-updates';
import LogoutButton from '@/components/LogoutButton';
import YouTrackIntegrationScreen from '@/Containers/YouTrackScreen';
import { DrawerParamList } from '@/types/navigation.type';
import fb from '@/utils/firebase';

const Drawer = createDrawerNavigator<DrawerParamList>();

type UpdateState = 'stale' | 'available' | 'updated';

// TODO https://reactnavigation.org/docs/drawer-navigator/#header

// TODO https://reactnavigation.org/docs/drawer-navigator/#providing-a-custom-drawercontent
function CustomDrawerContent(props: DrawerContentComponentProps) {
  const [updateState, setUpdateState] = useState<UpdateState>('stale');

  const checkUpdate = () => {
    if (!__DEV__)
      Updates.checkForUpdateAsync()
        .then((res) => {
          if (res.isAvailable) setUpdateState('available');
        })
        .catch(console.warn);
  };

  const fetchUpdate = () => {
    Updates.fetchUpdateAsync()
      .then((res) => {
        if (res.isNew) setUpdateState('updated');
      })
      .catch(console.error);
  };

  useEffect(() => {
    checkUpdate();
  }, []);

  const updateSwitch = {
    stale: {
      label: 'Check update',
      action: checkUpdate,
    },
    available: {
      label: 'Load update',
      action: fetchUpdate,
    },
    updated: {
      label: 'Reload to apply update',
      action: () => Updates.reloadAsync(),
    },
  }[updateState];

  const user = fb.auth.currentUser;

  return (
    <DrawerContentScrollView {...props}>
      <Box>
        <Row alignItems="center" pl="3" mb="4">
          <Image
            src={user?.photoURL ?? undefined}
            size="xs"
            borderRadius={'full'}
          />
          <Text p="2">{fb.auth.currentUser?.displayName}</Text>
        </Row>
      </Box>
      {/* <Row>{fb.auth.currentUser}</Row> */}

      <DrawerItemList {...props} />
      {/* <DrawerItem
        label="Help"
        // onPress={() => Linking.openURL('https://sites.google.com/view/tempotm')}
        onPress={() => Linking.openURL('https://tastik.app')}
      /> */}
      {/* <Button
        // title="Go somewhere"
        onPress={() => {
          // Navigate using the `navigation` prop that you received
          props.navigation.navigate('SomeScreen');
        }}
      >
        Go somewhere
      </Button> */}
      <Button onPress={updateSwitch.action}>{updateSwitch.label}</Button>

      <Text>Channel: {Updates.releaseChannel}</Text>
      <Text>UpdateId: {Updates.updateId}</Text>
      <Text>Update time: {Updates.manifest?.createdAt}</Text>
      <LogoutButton />
    </DrawerContentScrollView>
  );
}

const drawerContent = (props) => <CustomDrawerContent {...props} />;

export default function SideNav() {
  return (
    <Drawer.Navigator
      drawerContent={drawerContent}
      screenOptions={{
        headerShown: true,
        swipeEnabled: false,
      }}
    >
      <Drawer.Screen
        name="Tabs"
        component={TabNavigator}
        options={{
          headerShown: false,
          drawerItemStyle: { borderRightWidth: 2, borderRightColor: 'blue' },
          // drawerInactiveBackgroundColor: 'blue',
          // drawerLa
        }}
      />
      <Drawer.Screen name="YouTrack" component={YouTrackIntegrationScreen} />
      {/* <Drawer.Screen name="Settings" component={SettingsContainer} />
      <Drawer.Screen name="Login" component={LoginScreen} />
      <Drawer.Screen name="Signup" component={SignupScreen} />
      <Drawer.Screen name="Intro" component={IntroContainer} />
      <Drawer.Screen name="AppStateExample" component={AppStateExample} /> */}
      {/* {__DEV__ && <Drawer.Screen name="Dev" component={DevScreen} />} */}
    </Drawer.Navigator>
  );
}
