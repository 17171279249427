import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { ICalendar } from '@/types/types';
import { RootState } from '../store';
import fb from '@/utils/firebase';

export const signOut = createAsyncThunk('user/logout', () => fb.auth.signOut());

const calendarsAdapter = createEntityAdapter<ICalendar>();

const calendarsSlice = createSlice({
  name: 'calendars',
  initialState: calendarsAdapter.getInitialState(),
  reducers: {
    setAll: calendarsAdapter.setAll,
  },
});

export const calendarsSelectors = calendarsAdapter.getSelectors<RootState>(
  (state) => state.calendars,
);

// export const calendarsSelectors = calendarsAdapter.getSelectors();
export const { setAll } = calendarsSlice.actions;
export default calendarsSlice;
