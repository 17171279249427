import React, { useEffect, useState } from 'react';
import {
  VStack,
  Box,
  Divider,
  Heading,
  Input,
  Icon,
  Center,
  Modal,
  Button,
  FormControl,
  Text,
  FlatList,
} from 'native-base';
import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import { useTasks, useTasksById } from '@/hooks/useTask';
import { TouchableOpacity } from 'react-native';
import { noop } from '@/utils';
import { PrioritizedTask } from '@/types/task.type';

export function SearchBar() {
  return (
    <VStack
      my="4"
      space={5}
      w="100%"
      maxW="300px"
      divider={
        <Box px="2">
          <Divider />
        </Box>
      }
    >
      <VStack w="100%" space={5} alignSelf="center">
        <Heading fontSize="lg">Cupertino</Heading>
        <Input
          placeholder="Search"
          variant="filled"
          width="100%"
          borderRadius="10"
          py="1"
          px="2"
          borderWidth="0"
          InputLeftElement={
            <Icon
              ml="2"
              size="4"
              color="gray.400"
              as={<Ionicons name="ios-search" />}
            />
          }
        />
      </VStack>

      <VStack w="100%" space={5} alignSelf="center">
        <Heading fontSize="lg">Material</Heading>
        <Input
          placeholder="Search People & Places"
          width="100%"
          borderRadius="4"
          py="3"
          px="1"
          fontSize="14"
          InputLeftElement={
            <Icon
              m="2"
              ml="3"
              size="6"
              color="gray.400"
              as={<MaterialIcons name="search" />}
            />
          }
          InputRightElement={
            <Icon
              m="2"
              mr="3"
              size="6"
              color="gray.400"
              as={<MaterialIcons name="mic" />}
            />
          }
        />
      </VStack>
    </VStack>
  );
}

type SearchModalProps = {
  visible: boolean;
  onClose: () => void;
};

export function TaskSearchModal({ visible, onClose }: SearchModalProps) {
  // const [modalVisible, setModalVisible] = React.useState(false);

  return (
    <>
      <Modal
        isOpen={visible}
        onClose={() => onClose()}
        avoidKeyboard
        // justifyContent="flex-end"
        // bottom="4"
        size="lg"
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Header>Add dependency</Modal.Header>
          <Modal.Body>
            Enter email address and we'll send a link to reset your password.
            <FormControl mt="3">
              <FormControl.Label>Email</FormControl.Label>
              <Input />
            </FormControl>
          </Modal.Body>
          <Modal.Footer>
            <Button
              flex="1"
              onPress={() => {
                onClose();
              }}
            >
              Proceed
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>

      {/* <VStack space={8} alignItems="center">
        <Button
          w="104"
          onPress={() => {
            setModalVisible(!modalVisible);
          }}
        >
          Open Modal
        </Button>
        <Text textAlign="center">
          Open modal and focus on the input element to see the effect.
        </Text>
      </VStack> */}
    </>
  );
}

type InlineSearchProps = React.ComponentPropsWithoutRef<typeof Modal> & {
  visible: boolean;
  onRequestClose: () => void;
  // onSelect?: (task?: PrioritizedTask) => void;
  onSave?: (tasks: string[]) => void;
  excludeIds?: string[];
};

export default function InlineSearch({
  // onSelect = noop,
  visible,
  excludeIds = [],
  onRequestClose = noop,
  onSave = noop,
  ...props
}: InlineSearchProps) {
  const tasks = useTasks();
  const excludedTasks = useTasksById(excludeIds);
  const [query, setQuery] = useState('');
  const [_selectedTasks, setSelectedTasks] = useState<PrioritizedTask[]>([]);
  const selectedTasks = [...excludedTasks, ..._selectedTasks];
  const selectedIds = new Set(selectedTasks.map((t) => t.id));
  const results = tasks
    .filter((t) => !excludeIds.includes(t.id))
    .filter((t) => !selectedIds.has(t.id))
    .filter((t) => t.title.toLowerCase().includes(query.toLowerCase()));

  const [listVisible, setListVisible] = useState(false);

  // if (!visible) return null;

  useEffect(() => {}, [excludeIds]);

  return (
    <Modal
      isOpen={visible}
      onClose={() => onRequestClose()}
      // avoidKeyboard
      // justifyContent="flex-end"
      // bottom="4"
      size="lg"
      {...props}
    >
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Add dependency</Modal.Header>
        <Modal.Body>
          {/* Enter email address and we'll send a link to reset your password. */}
          {selectedTasks.length > 0 && <Text mb="2">Selected tasks</Text>}
          {selectedTasks.map((t) => (
            <TouchableOpacity
              key={t.id}
              onPress={() =>
                setSelectedTasks((selected) =>
                  selected.filter((st) => st.id !== t.id),
                )
              }
            >
              <Text>{t.title}</Text>
            </TouchableOpacity>
          ))}
          <FormControl mt="3">
            <FormControl.Label>Task</FormControl.Label>
            <Input
              placeholder="Task name"
              autoFocus
              variant="filled"
              onChangeText={setQuery}
              onFocus={() => setListVisible(true)}
              // onBlur={() => setListVisible(false)}
            />
          </FormControl>
          {listVisible && (
            <FlatList
              position="relative"
              right={-10}
              data={results}
              renderItem={({ item }) => (
                <TouchableOpacity
                  onPress={() =>
                    setSelectedTasks((selected) => [...selected, item])
                  }
                >
                  <Text>{item.title}</Text>
                </TouchableOpacity>
              )}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            flex="1"
            onPress={() => {
              onSave([...selectedIds]);
              onRequestClose();
            }}
          >
            Proceed
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}
