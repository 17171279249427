import { useMemo } from 'react'
import { DefaultTheme as PaperDefaultTheme } from 'react-native-paper'
import { useTheme } from '@/Theme'

/** Integrate app theme into paper UI framework */
const usePaperTheme = () => {
  const { Colors, darkMode } = useTheme()
  const theme = useMemo(
    () => ({
      ...PaperDefaultTheme,
      colors: {
        ...PaperDefaultTheme.colors,
        ...Colors,
      },
      dark: darkMode,
    }),
    [Colors, darkMode],
  )
  return theme
}

export default usePaperTheme
