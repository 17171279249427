import { useAppTheme } from '@/Context/AppThemeContext';
import useAuth from '@/hooks/useAuth';
import { getActiveRouteName, navigationRef } from '@/Navigators/navigation';
import { useAppSelector } from '@/Store/store';
import { Colors } from '@/Theme/Variables';
import {
  useFlipper,
  useReduxDevToolsExtension,
} from '@react-navigation/devtools';
import { NavigationContainer } from '@react-navigation/native';
import color from 'color';
import { StatusBar } from 'expo-status-bar';
import { Spinner, useTheme, useToken } from 'native-base';
import React, { useMemo, useRef, useState } from 'react';
// import { StatusBar } from 'react-native';
import { Provider as PaperProvider } from 'react-native-paper';
import AuthenticatedUserStack from './AuthenticatedUserStack';
import AuthStack from './AuthStack';
import { DefaultTheme as PaperDefaultTheme } from 'react-native-paper';
import fb from '@/utils/firebase';

// let MainNavigator: FunctionComponent | null

//@ts-ignore For some reason there is no types for Hermes
console.log('isHermes:', !!global.HermesInternal);

import useTimeout from 'src/hooks/useTimeout';

const Theme = {
  ...PaperDefaultTheme,

  colors: {
    ...PaperDefaultTheme,
    primary: '#6200ee',
    accent: '#03dac4',
    background: '#f6f6f6',
    surface: 'white',
    error: '#B00020',
    text: 'black',
    onSurface: '#000000',
    disabled: color('black').alpha(0.26).rgb().string(),
    placeholder: color('black').alpha(0.54).rgb().string(),
    backdrop: color('black').alpha(0.5).rgb().string(),
    notification: 'red',
    ...Colors,
  },
};

// @refresh reset
const RootNavigator = () => {
  // const { appTheme, paperTheme } = useAppTheme() as ThemeContextValue
  const { Layout, NavigationTheme, Colors, paperTheme } = useAppTheme();
  const [background, error, warning] = useToken('colors', [
    'design.background',
    'error.500',
    'warning.500',
  ]);
  const theme = useTheme();

  useReduxDevToolsExtension(navigationRef);
  useFlipper(navigationRef);

  const { colors } = NavigationTheme;
  // console.log(paperTheme);
  // const { timerStatus } = useAppStatus();
  // TODO: Check on Android;
  const timerStatus = useAppSelector((state) => state.status.timerStatus);
  const statusBarColor = useMemo(() => {
    if (timerStatus === 'inactive') return background;
    else if (timerStatus === 'work') return error;
    return warning;
  }, [timerStatus, background, error, warning]);

  const fbUser = useAuth();
  // const isAuthenticated = !!user?.uid;
  const user = useAppSelector((state) => state.user) ?? fbUser;
  // const user = fb.auth.currentUser;

  const routeNameRef = useRef<string | undefined>();

  const isAuthenticated = !!user;

  const [loaded, setLoaded] = useState(isAuthenticated);

  useTimeout(() => setLoaded(true), 1000);

  console.debug('isAuthenticated', isAuthenticated);

  return (
    <PaperProvider theme={Theme}>
      <StatusBar animated backgroundColor={statusBarColor} />
      {!loaded ? (
        <Spinner size={'lg'} />
      ) : (
        <NavigationContainer
          theme={NavigationTheme}
          ref={navigationRef}
          onReady={() => {
            routeNameRef.current = navigationRef.getCurrentRoute()?.name;
          }}
          onStateChange={async () => {
            const previousRouteName = routeNameRef.current;
            const currentRouteName = navigationRef.getCurrentRoute()?.name;

            if (previousRouteName !== currentRouteName) {
              // The line below uses the expo-firebase-analytics tracker
              // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
              // Change this line to use another Mobile analytics SDK
              fb.analytics.logEvent('ChangeScreen');
            }

            // Save the current route name for later comparison
            routeNameRef.current = currentRouteName;
          }}
        >
          {!isAuthenticated ? <AuthStack /> : <AuthenticatedUserStack />}
        </NavigationContainer>
      )}
    </PaperProvider>
  );
};

export default RootNavigator;
