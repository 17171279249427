import AppHeader from '@/components/AppHeader';
import InfoContainer from '@/components/InfoContainer';
import ScreenContainer from '@/components/ScreenContainer';
import layout from '@/constants/layout';
import { syncedStoriesSelectors } from '@/Store/firestore/storiesSlice';
import { useAppSelector } from '@/Store/store';
import { DiaryScreenProps } from '@/types/navigation.type';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Box, Column, Divider, FlatList, Icon, Row, Text } from 'native-base';
import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Appbar, FAB } from 'react-native-paper';
import { SafeAreaView } from 'react-native-safe-area-context';
import StoryCard from './components/StoryCard';

export default function DiaryScreen(props: DiaryScreenProps) {
  const stories = useAppSelector(syncedStoriesSelectors.selectAll);

  return (
    <>
      <ScreenContainer noScroll>
        <AppHeader title="Diary">
          <Appbar.Action icon="magnify" onPress={console.log} />
        </AppHeader>

        <FlatList
          data={stories}
          ListEmptyComponent={() => <InfoContainer title="No stories" />}
          renderItem={({ item: story }) => {
            return (
              <Row
                key={story.id}
                alignItems="center"
                justifyContent="center"
                flex="1"
              >
                <Column
                  justifyContent={'flex-start'}
                  alignItems="center"
                  height="100%"
                  ml="2"
                >
                  <Divider
                    orientation="vertical"
                    width={1}
                    position="absolute"
                    borderRadius="3xl"
                  />
                  <Box borderRadius={4} alignItems="center">
                    <Icon
                      as={MaterialCommunityIcons}
                      name="book"
                      color="white"
                      bg="primary.400"
                      borderRadius="4"
                    />
                    {/* <Text></Text */}
                    <Text>
                      {/* {new Date(story.updateTime).toLocaleTimeString()} */}
                    </Text>
                  </Box>
                  {/* <Divider orientation="vertical" position="absolute" /> */}
                </Column>
                <TouchableOpacity
                  style={{ flex: 1 }}
                  onPress={() =>
                    props.navigation.navigate('Story', { id: story.id })
                  }
                >
                  <StoryCard story={story} />
                </TouchableOpacity>
              </Row>
            );
          }}
        />
      </ScreenContainer>

      <FAB
        style={l.fab}
        icon="plus"
        onPress={() => props.navigation.navigate('NewStory')}
        visible={true}
      />
    </>
  );
}

const l = StyleSheet.create({
  fab: {
    position: 'absolute',
    margin: 30,
    right: 0,
    bottom: 0,
  },
});
