import React, { useMemo, useState } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Box, Button, Circle, ScrollView, Text, Toast } from 'native-base';
import { State, useHookstate } from '@hookstate/core';
import { useNavigation } from '@react-navigation/native';
import { cloneDeep, unzip } from 'lodash';

import AppHeader from '@/components/AppHeader';
import { COLORS } from '@/constants/colors';
import { WEEKDAYS } from '@/constants/time';
import { useAppDispatch, useAppSelector } from '@/Store/store';
import { updateTimeblocks, useSelectTimeblocks } from '@/Store/Timeblocks';
import { ITimeblock } from '@/types/timeblock.type';
import { ITimespace } from '@/types/timespace.type';
import { getObjectFromHookstate, leadingZero } from '@/utils';
import Timeblock from './components/Timeblock';
import { timespacesSelectors } from '@/Store/firestore/timespacesSlice';
import ScreenContainer from '@/components/ScreenContainer';

export default function TimeblocksScreen() {
  const dispatch = useAppDispatch();
  const _timeblocks = useSelectTimeblocks();
  const navigation = useNavigation();
  const timespaces = useAppSelector(timespacesSelectors.selectAll);
  // const timespacesMap = useAppSelector(timespacesSelectors.selectEntities);
  const mainTimespace = useAppSelector(timespacesSelectors.selectMainTimespace);

  // If null then it is main timespace;
  const [currentTimespace, setCurrentTimespace] = useState<null | string>(null);

  const hours = useHookstate(
    useMemo(() => {
      // Make a deep clone to avoid getting mutation errors from Redux
      // Transpose matrix to get array of hours that contains array with weekdays
      // https://stackoverflow.com/a/46805290
      return unzip(cloneDeep(_timeblocks));
    }, [_timeblocks]),
  );

  const onTimeblockPress = (timeblock: State<ITimeblock>) => {
    if (timeblock.timespaces.length >= 4)
      return Toast.show({
        title: 'You can not set more than 4 timespaces for one timeblock',
      });

    console.debug('onPressTimeblock', timeblock);

    if (currentTimespace === null) {
      timeblock.mainTimespace.set((state) => !state);
      return;
    }

    const ts = timeblock.timespaces.get();
    if (ts.includes(currentTimespace))
      timeblock.timespaces.set(ts.filter((t) => t !== currentTimespace));
    else timeblock.timespaces.merge([currentTimespace]);
  };

  const onHourPress = (timeblocks: State<ITimeblock[]>) => {
    timeblocks.batch((batch) => {
      batch.forEach((t) => {
        onTimeblockPress(t);
      });
    });
  };

  const onSave = () => {
    dispatch(updateTimeblocks(unzip(getObjectFromHookstate(hours))));
    navigation.goBack();
  };

  return (
    <ScreenContainer noScroll>
      <Box style={layout.container}>
        <Box style={{ flex: 1 }}>
          <Box style={{ flexDirection: 'row' }}>
            <Box style={layout.hourBlock} />
            {WEEKDAYS.map((day) => (
              <Box
                key={day}
                style={[
                  layout.block,
                  { borderBottomWidth: 0, marginBottom: 20 },
                ]}
              >
                <Text>{day.slice(0, 3)}</Text>
              </Box>
            ))}
          </Box>
          <ScrollView>
            {hours.map((hourRow, index) => {
              return (
                <Box key={index} flexDirection="row">
                  <TouchableOpacity
                    style={layout.hourBlock}
                    onPress={() => onHourPress(hourRow)}
                  >
                    <Text>{leadingZero(hourRow[0].start.get()) + ':00'}</Text>
                  </TouchableOpacity>
                  {hourRow.map((timeblockState) => (
                    <Timeblock
                      key={timeblockState.day.get()}
                      timeblockState={timeblockState}
                      onPress={onTimeblockPress}
                    />
                  ))}
                </Box>
              );
            })}
          </ScrollView>
        </Box>
      </Box>
      <Box>
        <ScrollView horizontal h="10">
          <TouchableOpacity onPress={() => setCurrentTimespace(null)}>
            <Box bg={COLORS[mainTimespace.color]}>
              <Text>Main timespace</Text>
            </Box>
          </TouchableOpacity>
          {timespaces.map((ts) => {
            return (
              <TouchableOpacity
                key={ts.id}
                onPress={() => setCurrentTimespace(ts.id)}
              >
                <Circle bg={COLORS[ts.color]}>{ts.name}</Circle>
              </TouchableOpacity>
            );
          })}
        </ScrollView>
      </Box>
      <Box>
        <Button onPress={onSave}>Save</Button>
      </Box>
    </ScreenContainer>
  );
}

const layout = StyleSheet.create({
  container: {
    flex: 1,
  },
  block: {
    flex: 1,
    height: 50,
  },
  borderBlock: {
    borderRightWidth: 1,
    borderTopWidth: 1,
  },
  hourBlock: {
    flex: 1,
    borderRightWidth: 1,
    paddingRight: 20,
  },
});
