import defaultTheme from '@/Theme/base';
import React from 'react';
import { StyleSheet } from 'react-native';
import { Appbar } from 'react-native-paper';

type AppHeaderProps = {
  title: string;
  children?: React.ReactNode;
};

export default function AppHeader(props: AppHeaderProps) {
  return (
    <Appbar.Header
      dark
      style={{ backgroundColor: defaultTheme.colors.neutral.active }}
      statusBarHeight={0}
    >
      <Appbar.Content titleStyle={layout.titleStyle} title={props.title} />
      {props.children}
    </Appbar.Header>
  );
}

const layout = StyleSheet.create({
  titleStyle: {
    fontSize: 36,
    textAlign: 'left',
  },
});
