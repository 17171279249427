import React from 'react';
import { Button, Dialog, Paragraph, Portal, Surface } from 'react-native-paper';

type props = {
  visible: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DeleteDialog = ({ visible, onClose, onConfirm }: props) => (
  <Dialog visible={visible} onDismiss={onClose}>
    <Dialog.Title>Delete?</Dialog.Title>
    <Dialog.Content>
      <Paragraph>Are you sure you want to delete this task?</Paragraph>
    </Dialog.Content>
    <Dialog.Actions>
      <Button onPress={onClose}>Cancel</Button>
      <Button onPress={onConfirm}>Delete</Button>
    </Dialog.Actions>
  </Dialog>
);

// const style = StyleSheet.create({
//   dialog: {
//     // elevation: 100,
//   },
// })

export default DeleteDialog;
