import { UserData } from '@/types/firebase';
import fb from '@/utils/firebase';
import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';

export const signOut = createAsyncThunk('user/logout', () => fb.auth.signOut());

const userSlice = createSlice({
  name: 'user',
  initialState: (fb.auth.currentUser?.toJSON() as UserData) ?? null,
  reducers: {
    setUser: (state, action: PayloadAction<UserData | null>) => action.payload,
  },
  extraReducers: (builder) => {
    builder.addCase(signOut.fulfilled, () => null);
  },
});

export const { setUser } = userSlice.actions;

export default userSlice;
