import { Draft } from 'immer';
import { partial } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useImmer } from 'use-immer';

export default function useImmerState<S extends Record<keyof S, S[keyof S]>>(
  objectState: S,
) {
  const [state, setState] = useImmer(objectState as S);
  const [stateKeys] = useState(() => Object.keys(objectState));

  const updateFunc = useCallback(
    <K extends keyof Draft<S>>(prop: K, value: Draft<S>[K]): void => {
      setState((draft) => {
        draft[prop] = value;
      });
    },
    [setState],
  );

  const propSetters = useMemo(() => {
    return Object.fromEntries(
      stateKeys.map((k) => [k, partial(updateFunc, k as keyof Draft<S>)]),
    ) as Record<keyof S, (value: Draft<S>[keyof Draft<S>]) => void>;
  }, [stateKeys, updateFunc]);

  return [state, setState, propSetters, updateFunc] as [
    S,
    typeof setState,
    typeof propSetters,
    typeof updateFunc,
  ];
}
