import { navigate, navigationRef } from '@/Navigators/navigation';
import { isWeb } from '@/utils/env';
import { noop } from 'lodash';
import useHotkey from '../useHotkey';

function useHotkeysSubscription() {
  useHotkey('1', () => {
    console.debug('Pressed 1');
    navigate('Tasks');
  });
  useHotkey('2', () => navigate('Timer'));
  useHotkey('3', () => navigate('Diary'));
  useHotkey('4', () => navigate('Calendar'));
  useHotkey('Enter', () => navigate('CreateTask'));
}

export default isWeb
  ? useHotkeysSubscription
  : (noop as typeof useHotkeysSubscription);
