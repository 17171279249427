export const COLORS = {
  black: '#000000',
  navy: '#000080',
  darkBlue: '#00008B',
  mediumBlue: '#0000CD',
  blue: '#0000FF',
  darkGreen: '#006400',
  green: '#008000',
  teal: '#008080',
  darkCyan: '#008B8B',
  deepSkyBlue: '#00BFFF',
  darkTurquoise: '#00CED1',
  mediumSpringGreen: '#00FA9A',
  lime: '#00FF00',
  springGreen: '#00FF7F',
  cyan: '#00FFFF',
  midnightBlue: '#191970',
  dodgerBlue: '#1E90FF',
  lightSeaGreen: '#20B2AA',
  forestGreen: '#228B22',
  seaGreen: '#2E8B57',
  darkSlateGray: '#2F4F4F',
  limeGreen: '#32CD32',
  mediumSeaGreen: '#3CB371',
  turquoise: '#40E0D0',
  royalBlue: '#4169E1',
  steelBlue: '#4682B4',
  darkSlateBlue: '#483D8B',
  mediumTurquoise: '#48D1CC',
  indigo: '#4B0082',
  darkOliveGreen: '#556B2F',
  cadetBlue: '#5F9EA0',
  cornflowerBlue: '#6495ED',
  rebeccaPurple: '#663399',
  mediumAquaMarine: '#66CDAA',
  dimGray: '#696969',
  slateBlue: '#6A5ACD',
  oliveDrab: '#6B8E23',
  slateGray: '#708090',
  lightSlateGray: '#778899',
  mediumSlateBlue: '#7B68EE',
  lawnGreen: '#7CFC00',
  aquamarine: '#7FFFD4',
  maroon: '#800000',
  purple: '#800080',
  olive: '#808000',
  gray: '#808080',
  skyBlue: '#87CEEB',
  lightSkyBlue: '#87CEFA',
  blueViolet: '#8A2BE2',
  darkRed: '#8B0000',
  darkMagenta: '#8B008B',
  saddleBrown: '#8B4513',
  darkSeaGreen: '#8FBC8F',
  lightGreen: '#90EE90',
  mediumPurple: '#9370DB',
  darkViolet: '#9400D3',
  paleGreen: '#98FB98',
  darkOrchid: '#9932CC',
  yellowGreen: '#9ACD32',
  sienna: '#A0522D',
  brown: '#A52A2A',
  darkGray: '#A9A9A9',
  lightBlue: '#ADD8E6',
  greenYellow: '#ADFF2F',
  paleTurquoise: '#AFEEEE',
  lightSteelBlue: '#B0C4DE',
  powderBlue: '#B0E0E6',
  fireBrick: '#B22222',
  darkGoldenRod: '#B8860B',
  mediumOrchid: '#BA55D3',
  rosyBrown: '#BC8F8F',
  darkKhaki: '#BDB76B',
  silver: '#C0C0C0',
  mediumVioletRed: '#C71585',
  indianRed: '#CD5C5C',
  peru: '#CD853F',
  chocolate: '#D2691E',
  tan: '#D2B48C',
  lightGray: '#D3D3D3',
  thistle: '#D8BFD8',
  orchid: '#DA70D6',
  goldenRod: '#DAA520',
  paleVioletRed: '#DB7093',
  crimson: '#DC143C',
  gainsboro: '#DCDCDC',
  plum: '#DDA0DD',
  burlyWood: '#DEB887',
  lightCyan: '#E0FFFF',
  lavender: '#E6E6FA',
  darkSalmon: '#E9967A',
  violet: '#EE82EE',
  paleGoldenRod: '#EEE8AA',
  lightCoral: '#F08080',
  khaki: '#F0E68C',
  aliceBlue: '#F0F8FF',
  honeyDew: '#F0FFF0',
  azure: '#F0FFFF',
  sandyBrown: '#F4A460',
  wheat: '#F5DEB3',
  beige: '#F5F5DC',
  whiteSmoke: '#F5F5F5',
  mintCream: '#F5FFFA',
  ghostWhite: '#F8F8FF',
  salmon: '#FA8072',
  antiqueWhite: '#FAEBD7',
  linen: '#FAF0E6',
  lightGoldenRodYellow: '#FAFAD2',
  oldLace: '#FDF5E6',
  red: '#FF0000',
  fuchsia: '#FF00FF',
  deepPink: '#FF1493',
  orangeRed: '#FF4500',
  tomato: '#FF6347',
  hotPink: '#FF69B4',
  coral: '#FF7F50',
  darkOrange: '#FF8C00',
  lightSalmon: '#FFA07A',
  orange: '#FFA500',
  lightPink: '#FFB6C1',
  pink: '#FFC0CB',
  gold: '#FFD700',
  peachPuff: '#FFDAB9',
  navajoWhite: '#FFDEAD',
  moccasin: '#FFE4B5',
  bisque: '#FFE4C4',
  mistyRose: '#FFE4E1',
  blanchedAlmond: '#FFEBCD',
  papayaWhip: '#FFEFD5',
  lavenderBlush: '#FFF0F5',
  seaShell: '#FFF5EE',
  cornsilk: '#FFF8DC',
  lemonChiffon: '#FFFACD',
  floralWhite: '#FFFAF0',
  snow: '#FFFAFA',
  yellow: '#FFFF00',
  lightYellow: '#FFFFE0',
  ivory: '#FFFFF0',
  white: '#FFFFFF',
} as const;

export type NamedColor = keyof typeof COLORS;

export const COLOR_NAMES = Object.keys(COLORS) as Array<NamedColor>;
export const COLOR_VALUES = Object.values(COLORS) as Array<
  typeof COLORS[NamedColor]
>;

export const POPULAR_COLORS = {
  blue: '#0000FF',
  red: '#FF0000',
  green: '#008000',
  purple: '#800080',
  orange: '#FFA500',
  yellow: '#FFFF00',
  gold: '#FFD700',
  silver: '#C0C0C0',
  pink: '#FFC0CB',
  turquoise: '#40E0D0',
  skyBlue: '#87CEEB',
  cyan: '#00FFFF',
  limeGreen: '#32CD32',
  darkBlue: '#00008B',
  gray: '#808080',
  aquamarine: '#7FFFD4',
  violet: '#EE82EE',
  teal: '#008080',
  brown: '#A52A2A',
  lightBlue: '#ADD8E6',
  lavender: '#E6E6FA',
} as const;

export const POPULAR_COLOR_NAMES = Object.keys(POPULAR_COLORS) as Array<
  keyof typeof POPULAR_COLORS
>;
export const POPULAR_COLOR_VALUES = Object.values(POPULAR_COLORS) as Array<
  typeof POPULAR_COLORS[keyof typeof POPULAR_COLORS]
>;

const tintColorLight = '#2f95dc';
const tintColorDark = '#fff';

export default {
  light: {
    text: '#000',
    background: '#fff',
    tint: tintColorLight,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: '#fff',
    background: '#000',
    tint: tintColorDark,
    tabIconDefault: '#ccc',
    tabIconSelected: tintColorDark,
  },
};
