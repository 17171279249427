import AppIcon from '@/components/icons/AppIcon';
import ScreenContainer from '@/components/ScreenContainer';
import { COLORS } from '@/constants/colors';
import { timespacesSelectors } from '@/Store/firestore/timespacesSlice';
import { useAppSelector } from '@/Store/store';
import { AllTimespacesScreenProps } from '@/types/navigation.type';
import { ITimespace } from '@/types/timespace.type';
import { Box, Button, IconButton, Text } from 'native-base';
import React, { useLayoutEffect } from 'react';
import { TouchableOpacity } from 'react-native';

const ProjectItem = ({
  timespace,
  onPress,
}: {
  timespace: ITimespace;
  onPress: () => void;
}) => {
  // const taskProjects = useTasksById(project.tasks);

  // const max = taskProjects.length || 1;
  // const completeness =
  //   taskProjects.filter((i) => i?.status === 'completed').length / max;

  return (
    <Box
      p="1"
      m="1"
      borderLeftColor={COLORS[timespace.color]}
      borderLeftWidth={1}
    >
      <TouchableOpacity onPress={onPress}>
        <Text fontSize="lg">{timespace.name}</Text>
        {/* <Text fontSize={'sm'}>{timespace.description}</Text> */}
        {/* <Progress value={completeness * 100} /> */}
        {/* {taskProjects.map((t) => {
          return <Text key={t?.id}>{t?.title}</Text>;
        })} */}
      </TouchableOpacity>
    </Box>
  );
};

// function TimespaceModal(props: { isOpen: boolean; onClose: () => void }) {
//   return (
//     <RNModal visible={props.isOpen} onRequestClose={props.onClose}>
//       {/* <SafeAreaView style={layout.flex}> */}
//       <IconButton icon={<ArrowBackIcon />} onPress={props.onClose} />
//       <CreateTimespaceScreen />
//       {/* </SafeAreaView> */}
//     </RNModal>
//   );
// }

export default function AllTimespacesScreen({
  navigation,
}: AllTimespacesScreenProps) {
  const timespaces = useAppSelector(timespacesSelectors.selectAll);
  const mainTimespace = useAppSelector(timespacesSelectors.selectMainTimespace);

  const onProjectPress = (id: string) => {
    navigation.navigate('Timespace', { id });
  };

  useLayoutEffect(() => {
    if (!mainTimespace) return;
    navigation.setOptions({
      headerRight: () => (
        <IconButton
          icon={<AppIcon name="plus" />}
          onPress={() => navigation.navigate('CreateTimespace')}
        />
      ),
    });
  }, [navigation, mainTimespace]);

  // const { isOpen, onClose, onToggle } = useDisclose();

  // <SafeAreaView>
  return (
    <ScreenContainer>
      <Button
        onPress={() => {
          navigation.navigate('MainTimespace');
          // navigation.goBack();
          // dispatch(removeTimespace(mainTimespace.id));
        }}
        variant={'ghost'}
      >
        Main timespace
      </Button>
      {timespaces.map((timespace) => (
        <ProjectItem
          key={timespace.id}
          timespace={timespace}
          onPress={() => onProjectPress(timespace.id)}
        />
      ))}
    </ScreenContainer>
  );
}
