import { useSubscribeToYoutrackTasks } from '@/hooks/subscriptions/useSubscribeToYoutrackTasks';
import prioritizeList, { prioritizeListDebounce } from '@/priority';
import tasksSlice, { syncedTasksSelectors } from '@/Store/firestore/tasksSlice';
import { dispatch, useAppSelector } from '@/Store/store';
import type { ITask, PrioritizedTask as PTask } from '@/types/task.type';
import fb from '@/utils/firebase';
import { without } from 'lodash';
import { useEffect, useState } from 'react';

const { removeMany, setMany } = tasksSlice.actions;

export default function useSubscribeToTasks(): PTask[] {
  const [tasks, setTasks] = useState<PTask[] | null>([]);

  const ownerId = fb.auth.currentUser?.uid;
  useEffect(() => {
    if (!ownerId) {
      fb.auth.signOut();
      return;
    }

    const tasksSubscriber = fb.store
      .collection('tasks')
      .where('ownerId', '==', ownerId)
      .onSnapshot((snapshot) => {
        const newTasks: ITask[] = [];
        snapshot?.forEach((doc) => {
          newTasks.push({
            ...(doc.data() as ITask),
            id: doc.id,
          });
        });

        const added: ITask[] = [];
        const modified: ITask[] = [];
        const removed: ITask['id'][] = [];

        snapshot.docChanges().forEach((change) => {
          const data = change.doc.data() as ITask;
          if (change.type === 'added') {
            added.push(data as ITask);
          }
          if (change.type === 'modified') {
            modified.push(data as ITask);
          }
          if (change.type === 'removed') {
            removed.push(data.id);
          }
        });

        if (added.length > 0) dispatch(setMany(prioritizeList(added)));
        if (modified.length > 0) dispatch(setMany(prioritizeList(modified)));
        if (removed.length > 0) dispatch(removeMany(removed));

        const prioritizedTasks = prioritizeListDebounce(newTasks) ?? [];
        setTasks(prioritizedTasks);
      });

    return () => tasksSubscriber();
  }, [ownerId]);

  return tasks ?? [];
}

/** This hook must be used only in one component */
export const useCombinedTasksSubscription = (): void => {
  const storedTasks = useAppSelector(syncedTasksSelectors.selectIds);
  const tasks = useSubscribeToTasks();
  const youTrackTasks = useSubscribeToYoutrackTasks();

  // Remove obsolete data
  useEffect(() => {
    const receivedDataLength = tasks.length + youTrackTasks.length;
    if (storedTasks.length !== 0 && receivedDataLength !== storedTasks.length) {
      const receivedTasks = [...tasks, ...youTrackTasks].map((t) => t.id);
      const diff = without(receivedTasks, ...storedTasks);
      if (diff.length > 0) {
        console.warn('Found obsolete data', diff);
        dispatch(tasksSlice.actions.removeMany(diff));
      }
    }
  }, [storedTasks, tasks, youTrackTasks]);
};
