import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as resources from './locales';
import './dates';

i18n.use(initReactI18next).init({
  compatibilityJSON: 'v3',
  resources: {
    ...Object.fromEntries(
      Object.entries(resources).map(([key, value]) => [
        key,
        {
          translation: value,
        },
      ]),
    ),
  },
  lng: 'en',
});

export { default } from 'i18next';
