import React, { useMemo, useState } from 'react';
import { projectsSelectors } from '@/Store/firestore/projectsSlice';
import { useAppSelector } from '@/Store/store';
import { ProjectScreenProps } from '@/types/navigation.type';
import { Box, IconButton, Row, Text, VStack } from 'native-base';
import { useTasksById } from '@/hooks/useTask';
import TaskListItem from '../Tasks/components/TaskListItem';
import useHeaderIcon from '@/hooks/useHeaderIcon';
import AppIcon from '@/components/icons/AppIcon';
import ScreenContainer from '@/components/ScreenContainer';
import ProjectEditor from './components/ProjectEditor';
import { syncedTasksSelectors } from '@/Store/firestore/tasksSlice';

export default function ProjectScreen({
  navigation,
  route,
}: ProjectScreenProps) {
  const { id } = route.params;

  const project = useAppSelector((state) =>
    projectsSelectors.selectById(state, id),
  );
  // const tasks = useTasksById(project?.tasks);

  // const tasks = useAppSelector(syncedTasksSelectors.selectEntities);

  const [isEditing, setIsEditing] = useState(false);

  useHeaderIcon(
    navigation,
    useMemo(() => {
      return (
        <Row>
          <IconButton
            onPress={() => setIsEditing((s) => !s)}
            icon={<AppIcon name={isEditing ? 'content-save' : 'pencil'} />}
          />
        </Row>
      );
    }, [isEditing]),
  );

  if (!project) return <Text>Something went wrong</Text>;

  if (isEditing)
    return (
      <ScreenContainer>
        <ProjectEditor project={project} />
      </ScreenContainer>
    );

  return (
    <ScreenContainer>
      <VStack space="4">
        <Text>{project?.name}</Text>
        <Text>{project?.description}</Text>
        {project.tasks.map((t) => {
          return t ? <TaskListItem id={t} /> : null;
        })}
      </VStack>
    </ScreenContainer>
  );
}
