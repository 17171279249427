import youtrackApi from '@/services/youtrack/youtrackApi';
import { AsyncThunkConfig } from '@/types/types';
import { update } from '@/utils';
import fb from '@/utils/firebase';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { chunk, throttle } from 'lodash';
import { Toast } from 'native-base';
import type {
  ITask,
  PrioritizedTask as PTask,
  Subtask,
} from '../../types/task.type';
import { RootState } from '../store';
import { createTask } from '../Tasks/createTask';

export const getDoc = (id?: string) => fb.store.collection('tasks').doc(id);

const tasksAdapter = createEntityAdapter<PTask>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (task) => task.id,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => b.priority - a.priority, // XXX: Need to make check that there will always be entities with priority field;
});

export const addTask = createAsyncThunk<Promise<any>, Omit<ITask, 'id'>>(
  'syncedTasks/addTask',
  async (task) => {
    const d = fb.store.collection('tasks').doc();
    return await d.set({ ...task, id: d.id });
  },
);

export const removeTask = createAsyncThunk<Promise<any>, string>(
  'syncedTasks/removeTask',
  async (id) => {
    return await fb.store.collection('tasks').doc(id).delete();
  },
);

export const modifyTask = createAsyncThunk<Promise<any>, ITask>(
  'syncedTasks/modifyTask',
  async (task) => {
    if (task.origin !== 'tastik') return;
    console.debug('modifyTask', task);
    return await fb.store.collection('tasks').doc(task.id).update(update(task));
  },
);

export const changeSubtasks = createAsyncThunk<
  Promise<any>,
  { id: string; subtasks: Subtask[] },
  AsyncThunkConfig
>('syncedTasks/toggleSubtask', async ({ id, subtasks }) => {
  return await fb.store
    .collection('tasks')
    .doc(id)
    .update({ subtasks, updateTime: Date.now() } as ITask);
});

export const changeTaskStatus = createAsyncThunk<
  Promise<any> | void,
  { id: string; status: ITask['status'] },
  AsyncThunkConfig
>('syncedTasks/changeTaskStatus', ({ id, status }, thunkAPI) => {
  const task = thunkAPI.getState().syncedTasks.entities[id];
  if (!task || task.origin !== 'tastik') return; // TODO:
  thunkAPI.dispatch(tasksSlice.actions.updateOne({ id, changes: { status } }));
  return fb.store
    .collection('tasks')
    .doc(id)
    .update({ status, updateTime: Date.now() } as ITask);
});

// Throttle to avoid spam and double invocation from timer (if any) TODO:
export const addTime = createAsyncThunk<
  Promise<any>,
  { id: string; minutes: number; comment?: string },
  AsyncThunkConfig
>('syncedTasks/addTime', ({ id, minutes, comment }, thunkAPI) => {
  // console.debug('minutes', minutes);
  const task = thunkAPI.getState().syncedTasks.entities[id];
  if (!task) return Promise.reject('No task');
  if (minutes < 1) return Promise.resolve();
  if (minutes < 5) {
    if (!Toast.isActive)
      Toast.show({ title: 'Cannot track less than 5 minutes' });
    return Promise.resolve();
  }
  if (task.origin === 'youtrack') {
    // https://redux-toolkit.js.org/rtk-query/usage/usage-without-react-hooks
    return thunkAPI.dispatch(
      youtrackApi.endpoints.postWorkItem.initiate({
        id,
        muteUpdateNotifications: false,
        issueWorkItem: {
          duration: { minutes },
          date: Date.now(),
          text: comment ?? 'Logged by Tastik',
          usesMarkdown: false,
        },
      }),
    );
  }

  return fb.store
    .collection('tasks')
    .doc(id)
    .update({
      elapsedTime: task.elapsedTime + minutes,
      updateTime: Date.now(),
    } as Partial<ITask>);
});

export const fixTasks = createAsyncThunk<void, void, AsyncThunkConfig>(
  'syncedTasks/fixTasks',
  async (_) => {
    // TODO: Make batch;
    const tasksCollection = await fb.store.collection('tasks').get();
    Promise.all(
      chunk(tasksCollection.docs, 250).map(async (tasks) => {
        return await Promise.all(
          tasks.map(
            async (taskDoc) =>
              await fb.store
                .collection('tasks')
                .doc(taskDoc.id)
                .update(
                  createTask({
                    ...taskDoc.data,
                    timespaces: [],
                    mainTimespace: true,
                  }) as Partial<ITask>,
                ),
          ),
        );
      }),
    );
  },
);

const tasksSlice = createSlice({
  name: 'syncedTasks',
  initialState: tasksAdapter.getInitialState(),
  reducers: {
    setTasks: tasksAdapter.setAll,
    updateOne: tasksAdapter.updateOne,
    addMany: tasksAdapter.addMany,
    setMany: tasksAdapter.setMany,
    updateMany: tasksAdapter.updateMany,
    removeMany: tasksAdapter.removeMany,
    bookAdded: tasksAdapter.addOne,
    tasksReceived(state, action) {
      // Or, call them as "mutating" helpers in a case reducer
      tasksAdapter.setAll(state, action.payload.books);
    },
  },
});

// Can create a set of memoized selectors based on the location of this entity state
export const syncedTasksSelectors = tasksAdapter.getSelectors<RootState>(
  (state) => state.syncedTasks,
);

// And then use the selectors to retrieve values
// const selectSyncedTasks = tasksSelectors.selectAll(store.getState())

export default tasksSlice;
