import { Collections } from '@/types/entity.type';
import Constants from 'expo-constants';
// import type firebaseTypes from 'firebase/compat/app';
import webFirebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import { isDevMode, usingFirebaseSimulator } from '../env';
import {
  auth,
  database as _database,
  firestore,
  init,
  signInWithGoogle,
  analytics,
} from './firebase';

const firebaseConfig = Constants.manifest?.web?.config?.firebase;
if (!firebaseConfig) throw new Error('Missing firebase config');
export const firebaseApp = webFirebase.initializeApp(firebaseConfig);

const database =
  isDevMode && usingFirebaseSimulator ? webFirebase.database() : _database;

export const getDoc = (collection: Collections, id?: string) =>
  firestore.collection(collection).doc(id);

export const getCollection = (collection: Collections) =>
  firestore.collection(collection);

export const getUser = () => auth.currentUser;

export const signUpWithPassword = async (email: string, password: string) => {
  auth
    .createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // ..
    });
};

export const signInWithPassword = async (email: string, password: string) => {
  auth
    .signInWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in
      const user = userCredential.user;
      // ...
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
    });
};

export const getAppDataDoc = () => {
  const userId = getUser()?.uid;
  // if (!userId) return console.error('No user to get app data');
  return getCollection('appData').doc(userId);
};

export const getAppStatusRef = () => {
  const ownerId = getUser()?.uid;
  return database.ref(`appStatus/${ownerId}`);
};

const fb = {
  store: firestore,
  auth,
  // There is an issue with native database emulator function. So we load web version of this
  // https://github.com/invertase/react-native-firebase/issues/5650
  database,
  analytics,
  getDoc,
  getCollection,
  getAppDataDoc,
  getUser,
  signInWithGoogle,
  signInWithPassword,
  signUpWithPassword,
  init,
};

export default fb;
