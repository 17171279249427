import * as React from 'react';
import { ToggleButton } from 'react-native-paper';

const ImportanceToggles = ({ value, onToggle }) => {
  return (
    <ToggleButton.Row
      onValueChange={(newValue) =>
        onToggle(newValue ? Number(newValue) : value)
      }
      value={String(value)}
      style={{ width: '100%' }}
    >
      <ToggleButton style={{ flexGrow: 1 }} icon="chevron-down" value={'1'} />
      <ToggleButton style={{ flexGrow: 1 }} icon="minus" value={'2'} />
      <ToggleButton style={{ flexGrow: 1 }} icon="chevron-up" value={'3'} />
    </ToggleButton.Row>
  );
};

export default ImportanceToggles;
