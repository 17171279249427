import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Flex, Row, ScrollView, Spinner } from 'native-base';
import * as CalendarAPI from 'expo-calendar';
import { SafeAreaView } from 'react-native-safe-area-context';
import { useAppSelector } from '@/Store/store';
import { shallowEqual, useDispatch } from 'react-redux';
import { calendarsSelectors, setAll } from '@/Store/firestore/calendarsSlice';
import { keyBy, pick, uniqBy } from 'lodash';

import type { ICalendar } from '@/types/types';
import { SelectCalendarsScreenProps } from '@/types/navigation.type';
import ScreenContainer from '@/components/ScreenContainer';

type Props = SelectCalendarsScreenProps & {
  calendars: ICalendar[];
  isVisible: boolean;
  onClose: (calendars: string[]) => void;
};

export default function SelectCalendarsScreen({ navigation }: Props) {
  // const [service, setService] = useState('');
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const savedLocalCalendars = useAppSelector<ICalendar[]>(
    calendarsSelectors.selectAll,
    shallowEqual,
  );
  const [selected, setSelected] = useState<string[]>(() =>
    savedLocalCalendars.map((cal) => cal.id),
  );

  const [allCalendars, setAllCalendars] =
    useState<ICalendar[]>(savedLocalCalendars);

  console.debug('all calendars', allCalendars);

  useEffect(() => {
    (async () => {
      const { status } = await CalendarAPI.requestCalendarPermissionsAsync();
      if (status === 'granted') {
        const cals = await CalendarAPI.getCalendarsAsync(
          CalendarAPI.EntityTypes.EVENT,
        );
        setAllCalendars(uniqBy([...savedLocalCalendars, ...cals], 'id'));
      }
      setIsLoading(false);
    })();
  }, [savedLocalCalendars]);

  const saveCalendarsSettings = () => {
    const normalizedCals = keyBy(allCalendars, 'id');
    const newCals = Object.values(pick(normalizedCals, selected));
    for (const x of newCals) {
      x.active = true;
    }
    dispatch(setAll(newCals));
    navigation.goBack();
  };

  return (
    <ScreenContainer noScroll>
      <Flex flex="1" pb="1" px="4">
        <ScrollView contentContainerStyle={{ paddingBottom: 20 }}>
          <Checkbox.Group
            onChange={setSelected}
            value={selected}
            accessibilityLabel="choose numbers"
          >
            {allCalendars.map((c) => (
              <Checkbox
                style={{
                  backgroundColor: c.color,
                  borderColor: c.color,
                  borderWidth: c.isPrimary ? 2 : 1,
                }}
                key={c.id}
                value={c.id}
              >
                {c.title}
              </Checkbox>
            ))}
          </Checkbox.Group>
          {isLoading && <Spinner />}
        </ScrollView>
      </Flex>
      {/* <Row>
        <Button>Connect google calendar</Button>
      </Row> */}
      <Row>
        <Button flex={1} onPress={saveCalendarsSettings}>
          Save
        </Button>
      </Row>
    </ScreenContainer>
  );
}

const flex = {
  flex: 1,
};
