import { useAppDispatch } from '@/Store/store';
import userSlice from '@/Store/User';
import { User, UserData } from '@/types/firebase';
import fb from '@/utils/firebase';
import { useCallback, useEffect, useState } from 'react';

export default function useAuth() {
  const dispatch = useAppDispatch();
  const [user, setUser] = useState<User | null>(fb.auth.currentUser);

  // Handle user state changes
  const onAuthStateChanged = useCallback(
    (authUser: User) => {
      // console.debug('User state', authUser);
      dispatch(
        userSlice.actions.setUser(
          authUser === null ? null : (authUser.toJSON() as UserData),
        ),
      );
      console.debug('authUser', authUser);
      if (authUser) {
        // authUser.uid;
        fb.store
          .collection('users')
          .doc(authUser.uid)
          .set(authUser.toJSON(), { merge: true });
      }
      setUser(authUser);
    },
    [dispatch],
  );

  useEffect(() => {
    const subscriber = fb.auth.onAuthStateChanged(
      onAuthStateChanged,
      (error) => {
        if (error) {
          console.error(error, 'Performing signout');
          fb.auth.signOut();
        }
      },
    );
    return () => subscriber(); // unsubscribe on unmount
  }, [onAuthStateChanged]);

  return user;
}
