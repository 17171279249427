import React, { useState } from 'react';

import TaskItem from './TaskItem';

import { TaskContainerProps } from '@/types/navigation.type';
import { SafeAreaView } from 'react-native-safe-area-context';
import useHeaderIcon from '@/hooks/useHeaderIcon';
import { IconButton, Row } from 'native-base';
import AppIcon from '@/components/icons/AppIcon';
import { Appbar } from 'react-native-paper';
import DeleteDialog from '@/components/DeleteDialog';
import { dispatch, useAppSelector } from '@/Store/store';
import {
  modifyTask,
  removeTask,
  syncedTasksSelectors,
} from '@/Store/firestore/tasksSlice';
import TaskInput from './TaskInput';
import { TaskCallback } from '@/types/task.type';
import { isEqual } from 'lodash';
import ScreenContainer from '@/components/ScreenContainer';

export default function TaskScreen({ route, navigation }: TaskContainerProps) {
  const id = route.params.id;
  const onClose = navigation.canGoBack()
    ? navigation.goBack
    : () => navigation.navigate('Home');

  const task = useAppSelector((store) =>
    syncedTasksSelectors.selectById(store, id),
  );
  const isEditable = !task?.origin || task?.origin === 'tastik';

  const [isEditing, setIsEditing] = useState(false);
  const [deleteVisible, setDeleteVisible] = useState(false);

  const showDialog = () => setDeleteVisible(true);
  const hideDialog = () => setDeleteVisible(false);
  const selfDelete = () => {
    if (!id) return;
    dispatch(removeTask(id));
    hideDialog();
    onClose();
  };

  useHeaderIcon(
    navigation,
    isEditable ? (
      <>
        <Row>
          {isEditing && (
            <Appbar.Action color="white" icon="delete" onPress={showDialog} />
          )}
          {!isEditing ? (
            <Appbar.Action
              icon="pencil"
              color="white"
              onPress={() => setIsEditing(true)}
            />
          ) : (
            <Appbar.Action
              color="white"
              icon="content-save"
              onPress={() => setIsEditing(false)}
            />
          )}
        </Row>
      </>
    ) : null,
  );

  const onSubmitChanges: TaskCallback = (task_) => {
    if (task_ && !isEqual(task_, task)) {
      dispatch(modifyTask(task_));
    }
    setIsEditing(false);
  };

  return (
    <>
      {isEditable && isEditing ? (
        <TaskInput task={task} onSubmit={onSubmitChanges} />
      ) : (
        <ScreenContainer>
          <TaskItem id={id} onClose={onClose} />
        </ScreenContainer>
      )}
      <DeleteDialog
        visible={deleteVisible}
        onClose={hideDialog}
        onConfirm={() => selfDelete()}
      />
    </>
  );
}
