import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import SignInScreen from '@/Containers/SignIn/SignIn';
import SignUpScreen from '@/Containers/SignUp';
import type { AuthStackParamList } from '@/types/navigation.type';
import InfoScreen from '@/Containers/InfoScreen';

const Stack = createStackNavigator<AuthStackParamList>();

export default function AuthStack() {
  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Group>
        <Stack.Screen name="SignIn" component={SignInScreen} />
        <Stack.Screen
          name="SignUp"
          component={SignUpScreen}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name="Info"
          component={InfoScreen}
          options={{ headerShown: false }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}
