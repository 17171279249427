// import { LinearGradient } from 'expo-linear-gradient'
import type { Theme } from 'native-base';

const background = '#0F1828';

export const design = {
  transparent: 'rgba(0,0,0,0)',
  success: '#2CC069',
  warning: '#FDCF41',
  error: '#E94242',
  primary: '#1a47ff',
  accent: '#002DE3',
  background: '#0F1828',
  surface: '#aaa',
  onSurface: '#111',
  text: '#F7F7FC',
  disabled: 'steelblue',
  placeholder: 'grey',
  backdrop: 'black',
  safe: '#7BCBCF',
  body: '#1B2B48',
};

const defaultTheme = {
  // dependencies: {
  //   'linear-gradient': LinearGradient,
  // },
  config: {
    useSystemColorMode: false,
    initialColorMode: 'dark',
  },
  colors: {
    design,
    primary: {
      50: '#e1ebff',
      100: '#D2D5F9', // BG - use for background
      200: '#879FFF', // Light - variant
      300: '#4c70ff',
      400: design.primary, // Dark Mode - use for Dark Mode
      500: design.accent, // Default - use for button
      600: '#0023b4',
      700: '#001A83', // Dark - use for on pressed
      800: '#000e51',
      900: '#000521',
    },
    neutral: {
      active: design.background,
      dark: '#152033',
      body: design.body,
      weak: '#A4A4A4',
      disabled: '#ADB5BD',
      line: '#EDEDED',
      secondary: design.text,
    },
    background,
    danger: { 500: design.error },
    warning: { 500: design.warning },
    success: { 500: design.success },
    safe: { 500: design.safe }, // Only one shadow
  },

  /** Typography */
  fontConfig: {
    Mulish: {
      400: {
        normal: 'Mulish_400Regular',
      },
      500: {
        normal: 'Mulish_400Regular',
      },
    },
    Comfortaa: {
      400: {
        normal: 'Comfortaa_400Regular',
      },
      500: {
        normal: 'Comfortaa_500Medium',
      },
    },
  },
  fonts: {
    // heading: 'Comfortaa',
    // body: 'Comfortaa',
    // mono: 'Comfortaa',
  },

  /* Components
.................................*/
  components: {
    Text: {
      baseStyle: (props) => {
        // console.warn(JSON.stringify(props, undefined, 2));
        return {
          fontFamily: 'body',
          fontWeight: 400,
          fontSize: 'md',
          color: 'neutral.secondary',
        };
      },
    },

    Input: {
      baseStyle: {
        selectionColor: 'primary.600',
      },
      defaultProps: {
        _focus: {
          borderColor: 'neutral.secondary',
        },
      },
    },
  },
} as const;

// TODO: Add gradients
// #D2D5F9 - #2C37E1
// #EC9EFF - #5F2EEA

export const linearGradient = {
  colors: ['primary.200', 'primary.800'],
  start: [0, 0],
  end: [1, 0],
};

// type MainColors = keyof typeof theme.colors;

// const DesignColorsMapLight = {
//   primary: theme.colors.primary[500],
//   'primary-dark': theme.colors.primary[700],
// };

// const DesignColorsMapDark = {
//   ...DesignColorsMapLight,
//   primary: theme.colors.primary,
// };

// const getColor = <T extends MainColors>(
//   color: T,
// ): typeof theme.colors[T][500] => {
//   return theme.colors[color][500];
// };

// const c = <T extends MainColors>(color: T): typeof theme.colors[T] => {
//   return theme.colors[color];
// };

// c('primary').

export default defaultTheme;
