// import InfoContainer from '@/components/InfoContainer';
// import React from 'react';

// export default function () {
//   return (
//     <InfoContainer
//       title="Calendar is not yet available in web version of Tastik."
//       message="Please switch to ios or android app to use task scheduling."
//     />
//   );
// }

// TODO: https://github.com/wmcmahan/react-native-calendar-events#ios-specific-instructions
// TODO: https://github.com/vonovak/react-native-add-calendar-event
import usePrioritizedTasks from '@/hooks/usePrioritizedTasks';
import prioritizeList from '@/priority';
import AppVibration from '@/services/AppVibration';
import { calendarsSelectors } from '@/Store/firestore/calendarsSlice';
import { dispatch, useAppSelector } from '@/Store/store';
import { useSelectTimeblocks } from '@/Store/Timeblocks';
import { CalendarScreenProps } from '@/types/navigation.type';
import { PrioritizedTask } from '@/types/task.type';
import { useFocusEffect } from '@react-navigation/native';
// import { scheduleTimeblocksFromEvents } from 'config/notifications';
import dayjs from 'dayjs';
import * as CalendarAPI from 'expo-calendar';
import produce from 'immer';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { FontAwesome5 } from '@expo/vector-icons';
import { Calendar, MIN_HEIGHT } from 'react-native-big-calendar';

import { intersection, isEqual, keyBy, uniqueId } from 'lodash';
import {
  Button,
  Center,
  IconButton,
  Row,
  Skeleton,
  Spinner,
  Text,
  useToken,
} from 'native-base';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import WeekView from 'react-native-week-view';
import { shallowEqual } from 'react-redux';
import CalendarSkeleton from './components/CalendarSkeleton';
import { SafeAreaView } from 'react-native-safe-area-context';
import layout from '@/constants/layout';
import { timespacesSelectors } from '@/Store/firestore/timespacesSlice';
import { fixTasks } from '@/Store/firestore/tasksSlice';

export interface IEvent extends Partial<CalendarAPI.Event> {
  id: string;
  title: string;
  description: string;
  startDate: Date;
  endDate: Date;
  start: Date;
  end: Date;
  task?: PrioritizedTask;
  color?: string;
  // ... more properties if needed,
}

const eventToCal = (event: CalendarAPI.Event, color = 'grey'): IEvent => {
  const { startDate, endDate, notes, title } = event;
  const start = new Date(startDate);
  const end = new Date(endDate);
  const description = `${title}\n${notes}`;
  const res = {
    ...event,
    start,
    end,
    startDate: start,
    endDate: end,
    title: title ?? description,
    description,
    color,
  };
  return res;
};

const DAY = 24 * 3600 * 1000;
const MONTH = 30 * 24 * 3600 * 1000;

const TIMEBLOCKS_LIMIT = 24 * 7;

const timeOffset = (time: number) => {
  const date = new Date(Date.now() + time);
  return date;
};

const settings = {
  pomoWorkMinutes: 45,
};

export default function CalendarScreen({ navigation }: CalendarScreenProps) {
  const calendarRef = useRef<typeof WeekView>();
  const [isLoading, setIsLoading] = useState(true);
  // const [selectedCalendars, setSelectedCalendars] = useState<string[]>([]);
  const calendars = useAppSelector(calendarsSelectors.selectAll, shallowEqual);
  const [calendarEvents, setCalendarEvents] = useState<IEvent[]>([]);
  const [events, setEvents] = useState<IEvent[]>([]);
  const { prioritizedTasks } = usePrioritizedTasks();
  const timespacesMap = useAppSelector(timespacesSelectors.selectEntities);
  const mainTimespace = useAppSelector(timespacesSelectors.selectMainTimespace);

  // Unmount calendar on unfocus to avoid expensive rerenders
  const [isFocused, setIsFocused] = useState<boolean>(navigation.isFocused);
  useFocusEffect(
    useCallback(() => {
      setIsFocused(true);
      return () => setIsFocused(false);
    }, []),
  );

  const eventColor = useToken('colors', 'primary.300');

  const timeblocks = useSelectTimeblocks();

  const weekday = useMemo(() => dayjs().isoWeekday() - 1, []);

  const weekBlocks = useMemo(
    () => [...timeblocks.slice(weekday), ...timeblocks.slice(0, weekday)],
    [timeblocks, weekday],
  );

  const selectTimeblocks = () => {
    navigation.navigate('TimeblockSelector');
  };

  // const fetchEvents = useCallback(() => {
  //   if (calendars.length === 0) return;
  //   const normalizedCalendars = keyBy(calendars, 'id');

  //   CalendarAPI.getEventsAsync(
  //     Object.keys(normalizedCalendars),
  //     timeOffset(DAY * -3),
  //     timeOffset(DAY * 3),
  //   )
  //     .then((evs) => {
  //       const newEvs = evs.map((e) =>
  //         eventToCal(e, normalizedCalendars[e.calendarId].color),
  //       );
  //       setCalendarEvents((currentEvs) => {
  //         if (!isEqual(currentEvs, newEvs)) {
  //           return newEvs;
  //         }
  //         return currentEvs;
  //       });
  //     })
  //     .catch((error) => {
  //       console.log('error:', error);
  //       return error;
  //     });
  // }, [calendars]);

  const fillTimeblocks = useCallback(() => {
    let tasks = prioritizedTasks;
    if (tasks.length === 0) return;
    // Start timeblocks day from current day
    const now = dayjs();
    const taskEvents: IEvent[] = [];
    let iterations = 0;
    const maxTimeblocks = weekBlocks.length * 24;

    const setAll = () => {
      // scheduleTimeblocksFromEvents(taskEvents);
      setEvents(taskEvents);
      setIsLoading(false);
    };

    for (const [index, days] of weekBlocks.entries())
      for (const tb of days) {
        setTimeout(() => {
          iterations += 1;

          if (!tb.mainTimespace && tb.timespaces.length === 0) {
            if (iterations >= maxTimeblocks) setAll();
            return;
          }
          const startTime = dayjs()
            .isoWeekday(now.isoWeekday() + index)
            .hour(tb.start)
            .startOf('hour');
          // If current timeblock is earlier than now then return
          if (startTime.isSame(now, 'day') && startTime.hour() < now.hour())
            return;
          const endTime = dayjs()
            .isoWeekday(now.isoWeekday() + index)
            .hour(tb.end)
            .startOf('hour');
          const elapsed = settings.pomoWorkMinutes;
          // const chosenTask
          let i = 0;
          const mainTimespaceTaskIndex = 0;
          const maxMatches = 0;
          // const maxIndex = 0;
          let matches: string[] = [];
          for (; i < tasks.length; i++) {
            const t = tasks[i];
            matches = intersection(t.timespaces, tb.timespaces);
            if (matches.length > maxMatches) break;
            if (t.mainTimespace && tb.mainTimespace) break;
          }
          if (i >= tasks.length - 1) return;

          const first = { ...tasks[i] };
          tasks = prioritizeList(
            produce(tasks, (draft) => {
              // console.log(draft[0].elapsedTime, elapsed);
              draft[i].elapsedTime += elapsed;
            }),
            startTime.valueOf(),
            undefined,
            true,
          );
          const startDate = startTime.toDate();
          const endDate = endTime.toDate();
          // const id = first.id + '' + tb.day * 100 + tb.start;
          const id = `${tb.day}_${tb.start}-${tb.end}`;
          const event: IEvent = {
            id,
            task: first,
            title: first.title,
            description: first.title,
            startDate,
            endDate,
            start: startDate,
            end: endDate,
            color: timespacesMap[matches[0]]?.color ?? eventColor,
          };
          taskEvents.push(event);
          if (iterations >= maxTimeblocks) setAll();
        });
      }

    // fetchEvents()
  }, [prioritizedTasks, weekBlocks, timespacesMap, eventColor]);

  useEffect(() => {
    fillTimeblocks();
    // fetchEvents();
  }, [fillTimeblocks]);

  const evs = useMemo(() => {
    console.debug('generate events');
    return [...events, ...calendarEvents];
  }, [calendarEvents, events]);

  console.debug('rerender calends');

  return (
    <>
      <SafeAreaView style={l.container}>
        <View style={layout.flex}>
          <Row justifyContent={'flex-end'}>
            <IconButton
              onPress={selectTimeblocks}
              icon={
                <MaterialCommunityIcons
                  name="timetable"
                  size={24}
                  color="white"
                />
              }
            />
            <IconButton
              icon={
                <FontAwesome5 name="calendar-plus" size={24} color="white" />
              }
              onPress={() => navigation.navigate('SelectCalendars')}
            />
            <IconButton
              // variant={'ghost'}
              icon={
                <MaterialCommunityIcons
                  name="calendar-today"
                  size={24}
                  color="white"
                />
              }
              // name="user"
              onPress={() => calendarRef.current?.goToDate(new Date())}
            />
          </Row>
          <View style={l.bottomBar} />
          {isFocused ? (
            <Calendar
              // isRefreshing={isLoading}
              // RefreshComponent={Spinner}
              // ref={calendarRef}
              events={evs}
              height={MIN_HEIGHT}
              locale="en"
              weekStartsOn={1}
              // selectedDate={new Date()}
              // numberOfDays={3}
              // formatDateHeader={'ddd, D'}
              // showNowLine
              // hoursInDisplay={10}
              // startHour={new Date().getHours()}
              // hourTextStyle={{ color: 'white' }}
              // headerTextStyle={{ color: 'white' }}
              // EventComponent={({
              //   event,
              //   position,
              // }: {
              //   event: IEvent;
              //   position: { height: number; width: number };
              // }) => (
              //   <TouchableOpacity
              //     delayLongPress={300}
              //     onPress={() => {
              //       // console.debug(event.task?.title, event.task);
              //     }}
              //     onLongPress={() => {
              //       AppVibration.touch();
              //       event.task?.id &&
              //         navigation.navigate('Task', {
              //           id: event.task.id,
              //         });
              //     }}
              //   >
              //     <Center
              //       position="relative"
              //       h={position.height - 2}
              //       // style={{ backgroundColor: event.color, bord }}
              //       // w={position.width }
              //       bg={event.color}
              //       // borderRadius={0}
              //       // borderTopLeftRadius={20}
              //       borderTopRightRadius="20"
              //       borderBottomLeftRadius="20"
              //       // bottom="1"
              //       left="1"
              //       mt={0.5}
              //     >
              //       <Text fontSize="sm" p="1">
              //         {event.description}
              //         <Text>{event.task?.priority}</Text>
              //       </Text>
              //     </Center>
              //   </TouchableOpacity>
              // )}
              // prependMostRecent
            />
          ) : (
            // <CalendarSkeleton />
            <Spinner />
          )}
          {/* {Platform.OS === 'web' && (
            <Calendar
              mode="3days"
              events={events}
              height={300}
              // renderHeader={() => <View />}
            />
          )} */}
        </View>
        {/* <View style={layout.bottomBar}>
        </View> */}
      </SafeAreaView>
    </>
  );
}

const l = StyleSheet.create({
  container: {
    flex: 1,
  },
  bottomBar: {
    backgroundColor: 'aliceblue',
    left: 0,
    right: 0,
    bottom: 0,
  },
});
