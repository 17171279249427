import { IProject } from '@/types/project.type';
import { update } from '@/utils';
import { getDoc } from '@/utils/firebase';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { Required } from 'utility-types';
import { RootState } from '../store';

export type NewProject = Required<Partial<IProject>, 'name'>;

export const createProject = (props: Partial<IProject>): IProject => {
  const id = getDoc('projects', props?.id).id;
  return update({
    id,
    name: props.name ?? id,
    color: 'blue',
    description: '',
    tasks: [],
    // stages: [],
    ...props,
  });
};

const projectsAdapter = createEntityAdapter<IProject>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (project) => project.id,
  // Keep the "all IDs" array sorted based on book titles
  // sortComparer: (a, b) => b.priority - a.priority,
});

const projectsSlice = createSlice({
  name: 'projects',
  initialState: projectsAdapter.getInitialState(),
  reducers: {
    resetProjects: () => projectsAdapter.getInitialState(),
    setAllProjects: projectsAdapter.setAll,
    addProject: projectsAdapter.addOne,
    removeProject: projectsAdapter.removeOne,
    upsertProject: projectsAdapter.upsertOne,
  },
});

// Can create a set of memoized selectors based on the location of this entity state
export const projectsSelectors = projectsAdapter.getSelectors<RootState>(
  (state) => state.projects,
);

// And then use the selectors to retrieve values
// const selectSyncedTasks = tasksSelectors.selectAll(store.getState())

export default projectsSlice;
