import AppIcon from '@/components/icons/AppIcon';
import TransparentIcon from '@/components/icons/TransparentIcon';
import layout from '@/constants/layout';
import { devOnly } from '@/utils/env';
import { Center, IconButton, Row, Text } from 'native-base';
import React, { useCallback, useMemo, useState } from 'react';
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native';
import { toDayStart, ModeDisplayMap } from './DatesPicker';
import dayjs from 'dayjs';

// e.g in your index.js
import {
  en,
  // nl,
  // de,
  // pl,
  // pt,
  // enGB,
  registerTranslation,
  DatePickerModal,
  TimePickerModal,
} from 'react-native-paper-dates';
registerTranslation('en', en);
// registerTranslation('nl', nl)
// registerTranslation('pl', pl)
// registerTranslation('pt', pt)
// registerTranslation('de', de)
// registerTranslation('en-GB', enGB);

export const DateRow = ({
  date,
  onChange,
  style,
}: {
  date: number | null;
  onChange: (timestamp: number | null) => void;
  style?: StyleProp<ViewStyle>;
}) => {
  const [mode, setMode] = useState<'close' | 'date' | 'time'>('close');

  // const startDay = useMemo(
  //   () => (toDayStart(date),
  //   [date],
  // );
  const dayStart = useMemo(() => toDayStart(date), [date]);
  const isDayStart = date === dayStart;

  const onChangeDate = useCallback(
    (params?: { date?: Date; hours?: number; minutes?: number }) => {
      if (!params) return;

      let time = params.date;
      if (params.minutes && params.hours) {
        time = dayjs().hour(params.hours).minute(params.minutes).toDate();
        // dayjs()
      }
      if (!time) return;
      setMode('close');
      const value = new Date(time).valueOf();
      onChange(mode === 'date' ? toDayStart(value) : value);
    },
    [mode, onChange],
  );

  const onCancel = () => {
    setMode('close');
  };

  const onRemovePress = () => {
    if (!date) return;
    console.debug(date);
    onChange(isDayStart ? null : dayStart);
    setMode('close');
  };

  return (
    <>
      <Row
        alignItems="center"
        borderColor="primary.300"
        space={2}
        style={style}
        minH="10"
        paddingX={4}
      >
        <Row flex="1" justifyContent="space-around">
          <TouchableOpacity style={layout.flex} onPress={() => setMode('date')}>
            {date ? (
              <Text>{new Date(date).toDateString()}</Text>
            ) : (
              <Text color="muted.500">Date</Text>
            )}
          </TouchableOpacity>

          <TouchableOpacity style={layout.flex} onPress={() => setMode('time')}>
            <Center>
              {date && !isDayStart ? (
                <Text>{new Date(date).toLocaleTimeString()}</Text>
              ) : (
                <Text color="muted.500">Time</Text>
              )}
            </Center>
          </TouchableOpacity>
        </Row>

        <IconButton
          variant="ghost"
          isDisabled={!date}
          disabled={!date}
          size="sm"
          onPress={onRemovePress}
          icon={date ? <AppIcon name="close" /> : <TransparentIcon />}
        />
      </Row>

      <DatePickerModal
        date={new Date(date ?? Date.now())}
        visible={mode === 'date'}
        // visible
        // mode={mode === 'close' ? 'date' : mode}
        locale="en"
        mode="single"
        // onConfirm={}
        onConfirm={onChangeDate}
        onDismiss={onCancel}
        // is24Hour
        // display={ModeDisplayMap[mode]}
      />
      <TimePickerModal
        // date={new Date(date ?? Date.now())}
        hours={new Date(date ?? Date.now()).getHours()}
        minutes={new Date(date ?? Date.now()).getMinutes()}
        visible={mode === 'time'}
        // visible
        // mode={mode === 'close' ? 'date' : mode}
        locale="en"
        // mode="single"
        // onConfirm={}
        onConfirm={onChangeDate}
        onDismiss={onCancel}
      />
      {/* <DateTimePickerModal
        date={new Date(date ?? Date.now())}
        isVisible={mode !== 'close'}
        mode={mode === 'close' ? 'date' : mode}
        locale={devOnly('en_GB')}
        onConfirm={onChangeDate}
        onCancel={onCancel}
        is24Hour
        display={ModeDisplayMap[mode]}
      /> */}
    </>
  );
};
