import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import TimeblocksScreen from '@/Containers/Calendar/TimeblocksScreen';
import TaskScreen from '@/Containers/Tasks/TaskScreen';
import SideNav from './SideNav';
import { useCombinedTasksSubscription } from '@/hooks/firestore/useTasksSubscription';
import { AuthenticatedUserStackParamList } from '@/types/navigation.type';
import NewStoryScreen from '@/Containers/Diary/NewStoryScreen';
import useStories from '@/hooks/firestore/useStories';
import StoryScreen from '@/Containers/Diary/StoryScreen';
import SelectCalendarsScreen from '@/Containers/SelectCalendarsScreen';
import AllProjectsScreen from '@/Containers/projects/AllProjectsScreen';
import ProjectScreen from '@/Containers/projects/ProjectScreen';
import AllTimespaces from '@/Containers/timespaces/AllTimespacesScreen';
import TimespaceScreen from '@/Containers/timespaces/TimespaceScreen';
import CreateProjectScreen from '@/Containers/projects/CreateProjectScreen';
import CreateTimespaceScreen from '@/Containers/timespaces/CreateTimespaceScreen';
import useTimespaceSubscription from '@/hooks/firestore/useTimespaceSubscription';
import MainTimespaceScreen from '@/Containers/timespaces/MainTimespaceScreen';
import CreateTaskScreen from '@/Containers/Tasks/CreateTaskScreen';
import useAppDataSubscription from '@/hooks/firestore/useAppDataSubscription';
import { useGroupedTasksSubscription } from '@/hooks/usePrioritizedTasks';
import InfoScreen from '@/Containers/InfoScreen';
import useHotkeysSubscription from '@/hooks/subscriptions/useHotkeysSubscription';

const Stack = createStackNavigator<AuthenticatedUserStackParamList>();

export default function AuthenticatedUserStack() {
  // These subscription hooks must be used only once.
  useStories();
  useTimespaceSubscription();
  useAppDataSubscription();
  useCombinedTasksSubscription();
  useGroupedTasksSubscription();
  useHotkeysSubscription();

  return (
    <Stack.Navigator
      screenOptions={{ headerShown: true, headerBackTitle: 'Back' }}
    >
      <Stack.Screen
        name="Home"
        component={SideNav}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="Task"
        component={TaskScreen}
        options={{ animationEnabled: true, headerTitle: '' }}
      />
      <Stack.Screen name="TimeblockSelector" component={TimeblocksScreen} />
      <Stack.Screen name="NewStory" component={NewStoryScreen} />
      <Stack.Screen name="Story" component={StoryScreen} />
      <Stack.Screen
        name="SelectCalendars"
        component={SelectCalendarsScreen}
        options={{ headerShown: true }}
      />
      <Stack.Screen name="AllProjects" component={AllProjectsScreen} />
      <Stack.Screen name="Project" component={ProjectScreen} />
      <Stack.Screen name="MainTimespace" component={MainTimespaceScreen} />

      <Stack.Screen name="AllTimespaces" component={AllTimespaces} />
      <Stack.Screen name="Timespace" component={TimespaceScreen} />
      <Stack.Group
        screenOptions={{
          animationEnabled: true,
          presentation: 'modal',
        }}
      >
        <Stack.Screen
          name="CreateTimespace"
          component={CreateTimespaceScreen}
        />
        <Stack.Screen name="CreateProject" component={CreateProjectScreen} />
        <Stack.Screen name="CreateTask" component={CreateTaskScreen} />
        <Stack.Screen
          name="Info"
          component={InfoScreen}
          options={{ headerShown: false }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
}
