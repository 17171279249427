import { dispatch, useAppSelector } from '@/Store/store';
import {
  removeStory,
  syncedStoriesSelectors,
} from '@/Store/firestore/storiesSlice';
import { StoryScreenProps } from '@/types/navigation.type';
import { Box, Button, Checkbox, Divider, Row, Text } from 'native-base';
import React, { useLayoutEffect } from 'react';
import ScreenContainer from '@/components/ScreenContainer';
import { Alert } from 'react-native';

export default function StoryScreen({ navigation, route }: StoryScreenProps) {
  const story = useAppSelector((store) =>
    syncedStoriesSelectors.selectById(store, route.params.id),
  );

  useLayoutEffect(() => {
    if (!story) return;
    navigation.setOptions({
      headerRight: () => (
        <Button
          onPress={() => {
            Alert.alert(
              'Delete',
              'Are you sure you want to delete this story?',
              [
                {
                  text: 'Delete',
                  style: 'destructive',
                  onPress: () => {
                    navigation.goBack();
                    dispatch(removeStory(story?.id));
                  },
                },
                { text: 'Cancel', style: 'cancel' },
              ],
              { cancelable: true },
            );
          }}
        >
          Delete
        </Button>
      ),
    });
  }, [navigation, story]);

  if (!story) return <Text>Something went wrong</Text>;
  return (
    <ScreenContainer>
      <Box flex="1" padding="2">
        <Text fontSize="xl">{story.title}</Text>
        <Text fontSize="xs">{new Date(story.updateTime).toLocaleString()}</Text>
        <Divider my="4" />
        <Text>{story.content}</Text>
        {story.tasks.map((t) => (
          <Box key={t.id}>
            <Row px="4" alignItems="center">
              <Checkbox
                isChecked
                isDisabled
                value={t.title}
                accessibilityLabel="Completed task checkbox"
              />
              <Text px="1">{t.title}</Text>
            </Row>
          </Box>
        ))}
        <Text mt="4">Tags</Text>
        <Divider />
        <Row flexWrap="wrap" space="2">
          {story.tags.map((s) => (
            <React.Fragment key={s}>
              <Text>#{s}</Text>
            </React.Fragment>
          ))}
        </Row>
      </Box>
    </ScreenContainer>
  );
}
