import { BlankSpacer } from '@/components';
import ErrorView from '@/components/ErrorView';
import AppIcon from '@/components/icons/AppIcon';
import SubtasksList from '@/Containers/Tasks/components/SubtasksList';
import { useAppTheme } from '@/Context/AppThemeContext';
import useColor from '@/hooks/useColor';
import { useTimespacesById } from '@/hooks/useTask';
import { getPriority } from '@/priority';
import {
  changeTaskStatus,
  addTime,
  modifyTask,
  syncedTasksSelectors,
} from '@/Store/firestore/tasksSlice';
import { useAppDispatch, useAppSelector } from '@/Store/store';
import { toggleSubtask } from '@/Store/Tasks';
import { ITask, TaskCallback } from '@/types/task.type';
import { getDurationTime, isActive, isDelayed } from '@/utils';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Center,
  Divider,
  Icon,
  IconButton,
  Input,
  Row,
  Text,
} from 'native-base';
import React, { useCallback, useState } from 'react';
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInputFocusEventData,
  TextInputSubmitEditingEventData,
} from 'react-native';
// import { ScrollView } from 'react-native-gesture-handler';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { string } from 'yup';
import TaskActionPanel from './components/TaskActionPanel';
import { getCheckboxIconName } from './components/TaskListItem';
import TaskInput from './TaskInput';

type Props = {
  id: ITask['id'];
  onClose: TaskCallback;
  onSave?: TaskCallback;
  // onChange: TaskUpdateFunc
};

const importanceNames = ['low', 'normal', 'high'];
const importanceIcons = ['chevron-down', 'minus', 'chevron-up'];

const IconText = ({
  icon = MaterialCommunityIcons,
  name,
  children,
}: {
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon?: Function;
  name: string;
  children: string;
}) => (
  <Center flexDirection="row">
    <Icon as={icon} name={name} mr="2" />
    <Text>{children}</Text>
  </Center>
);

const validateElapsedChange = (value: string): number | string => {
  if (value === '') return value;
  if (value === '-') return value;
  const num = Number(value);
  if (Number.isNaN(num)) return 0;
  return num;
};

const onElapsedChange = (
  e:
    | NativeSyntheticEvent<TextInputFocusEventData>
    | NativeSyntheticEvent<TextInputSubmitEditingEventData>,
): number | string => validateElapsedChange(e.nativeEvent.text);

export default function TaskItem({ id, onClose, onSave }: Props) {
  const { Layout, Colors } = useAppTheme();
  const dispatch = useAppDispatch();
  // const [changes, setChanges] = useImmer(task)
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  // const task = useSelectTask(id); // TODO Check for stability
  const task = useAppSelector((store) =>
    syncedTasksSelectors.selectById(store, id),
  );

  const [elapsedChange, setElapsedChange] = useState<number | string>('');

  const timespaces = useTimespacesById(task?.timespaces);
  // const mainTimespace = useAppSelector(timespacesSelectors.selectMainTimespace);

  const headerColor = useColor('primary.500', 'background');

  const onCheckboxPress = useCallback(
    (task: ITask) => {
      if (!task) return;
      if (isDelayed(task)) return;
      if (task.origin === 'youtrack') return; // TODO: Check
      const status: ITask['status'] = isActive(task) ? 'completed' : 'active';
      // setIsHiding(true);
      setLoading(true);
      dispatch(changeTaskStatus({ id: task.id, status })).finally(() =>
        setLoading(false),
      );
    },
    [dispatch],
  );

  // const { clock } = getDurationTime(task.duration);

  // const saveChanges = () => {
  //   console.log('Save changes launched');
  //   // onSave(task);
  //   onSubmitChanges(task);
  // };

  const onSubmitChanges: TaskCallback = (task_) => {
    if (task_) {
      dispatch(modifyTask(task_));
    }
    setIsEditing(false);
  };

  // const onSubtaskToggle = (index: number) => {
  //   dispatch(toggleSubtask({ id: task.id, index }));
  // };

  // useEffect(() => {
  //   // dispatch(modifyTask())
  //   return () => saveChanges();
  // }, []);
  if (!task) return <ErrorView message="No task with such Id" />;
  return (
    // <View style={layout.flex}>
    <>
      <KeyboardAwareScrollView>
        <Box marginX="4">
          {isEditing ? (
            <TaskInput task={task} onSubmit={onSubmitChanges} />
          ) : (
            <>
              <Row alignItems="center">
                {/* <AppIcon
                  name={getCheckboxIconName(task)}
                  onPress={() => onCheckboxPress(task)}
                /> */}
                <AppIcon
                  // paddingRight={4}
                  // as={MaterialCommunityIcons}
                  // borderWidth={1}
                  // fontWeight="light"
                  name={loading ? 'loading' : getCheckboxIconName(task)}
                  onPress={() => onCheckboxPress(task)}
                />
                <Text
                  px="3"
                  flex="1"
                  fontWeight={500}
                  fontSize="xl"
                  py="4"
                  selectable
                >
                  {task.title}
                </Text>
              </Row>

              <Box>
                {!!task.notes && (
                  <Text shadow={'1'} fontSize="lg" selectable>
                    {task.notes}
                  </Text>
                )}
                <Box h="4 " />
                <Row justifyContent="space-between" paddingY="2">
                  <IconText name={importanceIcons[task.importance - 1]}>
                    Importance
                  </IconText>
                  <Text>{importanceNames[task.importance - 1]}</Text>
                </Row>

                <Row justifyContent="space-between" paddingY="2">
                  <IconText name="timelapse">Time spent:</IconText>
                  <Text>
                    {Math.floor(task.elapsedTime)}m out of {task.duration}m
                  </Text>
                </Row>

                <Divider />
                <Row justifyContent="space-between" paddingY="2">
                  <IconText
                    name={task.dueDate ? 'ray-start-arrow' : 'ray-start'}
                  >
                    Start from:
                  </IconText>
                  <Text>
                    {typeof task.startDate === 'number'
                      ? new Date(task.startDate).toDateString()
                      : null}
                  </Text>
                </Row>
                {task.dueDate === null ? null : (
                  <Row justifyContent="space-between" paddingY="2">
                    <IconText name={'ray-end'}>Due date:</IconText>
                    <Text
                      color={
                        task.dueDate < Date.now() ? 'danger.500' : undefined
                      }
                    >
                      {new Date(task.dueDate).toDateString()}
                    </Text>
                  </Row>
                )}
                <Divider />

                {task.subtasks.length > 0 && (
                  <SubtasksList
                    taskId={task.id}
                    subtasks={task.subtasks}
                    // onRemove={() => console.warn('onRemove')}
                    // onToggle={onSubtaskToggle}
                  />
                )}

                <Row justifyContent="space-between" paddingY="2">
                  <IconText name="priority-high">Priority</IconText>
                  <Text>{getPriority(task)}</Text>
                </Row>
                {task.mainTimespace && <Text>Uses main timespace</Text>}
                <Box>
                  {timespaces.map((ts) => {
                    return ts ? (
                      <Row key={ts.id}>
                        <Text>{ts.name}</Text>
                      </Row>
                    ) : null;
                  })}
                </Box>
                <TaskActionPanel task={task} />

                <Box justifyContent={'center'}>
                  <Text>Add time</Text>
                  <Row>
                    <Row flex="1">
                      <IconButton
                        // isDisabled={+elapsedChange < 1}
                        alignItems="center"
                        flex="1"
                        icon={<AppIcon name="minus" />}
                        onPress={() =>
                          setElapsedChange((s) => (s <= 0 ? 0 : +s - 1))
                        }
                      />
                      <Input
                        flex="2"
                        alignSelf={'center'}
                        value={elapsedChange.toString()}
                        onChangeText={(text) =>
                          setElapsedChange(validateElapsedChange(text))
                        }
                        keyboardType="number-pad"
                        onBlur={(e) => setElapsedChange(onElapsedChange(e))}
                        onSubmitEditing={(e) =>
                          setElapsedChange(onElapsedChange(e))
                        }
                        // blurOnSubmit
                        // onChangeText={setElapsedChange}
                      />
                      <IconButton
                        alignItems="center"
                        flex="1"
                        icon={<AppIcon name="plus" />}
                        onPress={() => setElapsedChange((s) => +s + 1)}
                      />
                      <Box flex="1">
                        {!!elapsedChange && elapsedChange !== task.elapsedTime && (
                          <Button
                            onPress={() => {
                              let change = +elapsedChange;
                              if (task.elapsedTime + change < 0) {
                                change = -task.elapsedTime;
                              }
                              return dispatch(
                                addTime({
                                  id: task.id,
                                  minutes: change,
                                }),
                              );
                            }}
                            colorScheme="info"
                          >
                            Save
                          </Button>
                        )}
                      </Box>
                    </Row>
                  </Row>
                  {task.origin === 'youtrack' && (
                    <Row justifyContent="space-between" paddingY="2">
                      {/* <IconText name={importanceIcons[task.importance - 1]}> */}
                      <Text textDecorationLine="underline">
                        Task from {task.origin}
                      </Text>
                      {/* </IconText> */}
                    </Row>
                  )}
                  <Text>Last update: {dayjs(task.updateTime).fromNow()}</Text>
                </Box>
                <BlankSpacer height={20} />
              </Box>
            </>
          )}
        </Box>
      </KeyboardAwareScrollView>
      {/* <Row
        position="absolute"
        bottom="0"
        left="0"
        right="0"
        borderWidth="1"
        borderTopColor="primary.100"
      >
        <Button variant={'ghost'}>Activate task</Button>
      </Row> */}
    </>
  );
}

const layout = StyleSheet.create({
  flex: {
    flex: 1,
  },
  taskSectionContainer: {
    // margin: 10,
  },
  closeButtonContainer: {
    padding: 8,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
  },
  closeButtonText: {},
  taskTitle: { fontSize: 18 },
  bottomBar: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: 50,
  },
});
