import AppIcon from '@/components/icons/AppIcon';
import ScreenContainer from '@/components/ScreenContainer';
import { COLORS, POPULAR_COLOR_NAMES } from '@/constants/colors';
import useHeaderIcon from '@/hooks/useHeaderIcon';
import { useTasksById } from '@/hooks/useTask';
import { projectsSelectors } from '@/Store/firestore/projectsSlice';
import { useAppSelector } from '@/Store/store';
import { AllProjectScreenProps } from '@/types/navigation.type';
import { IProject } from '@/types/project.type';
import { Box, Flex, IconButton, Progress, Row, Text } from 'native-base';
import React from 'react';
import { Dimensions, TouchableOpacity } from 'react-native';

export const COLOR_WIDTH = Math.floor(
  Dimensions.get('screen').width / POPULAR_COLOR_NAMES.length,
);

export default function AllProjectsScreen({
  navigation,
}: AllProjectScreenProps) {
  const projects = useAppSelector(projectsSelectors.selectAll);

  const onProjectPress = (id: string) => {
    navigation.navigate('Project', { id });
  };

  useHeaderIcon(
    navigation,
    <IconButton
      onPress={() => navigation.navigate('CreateProject')}
      icon={<AppIcon name="plus" />}
    />,
  );

  // const { isOpen, onClose, onToggle } = useDisclose();

  return (
    <Flex>
      <Row />
      <ScreenContainer>
        {projects.map((project) => (
          <ProjectItem
            key={project.id}
            project={project}
            onPress={() => onProjectPress(project.id)}
          />
        ))}
      </ScreenContainer>
    </Flex>
  );
}

const ProjectItem = ({
  project,
  onPress,
}: {
  project: IProject;
  onPress: () => void;
}) => {
  const taskProjects = useTasksById(project.tasks);

  const max = taskProjects.length || 1;
  const completeness =
    taskProjects.filter((i) => i?.status === 'completed').length / max;

  return (
    <Box
      p="1"
      m="1"
      borderLeftColor={COLORS[project.color]}
      borderLeftWidth={1}
    >
      <TouchableOpacity onPress={onPress}>
        <Text fontSize="lg">{project.name}</Text>
        <Text fontSize={'sm'}>{project.description}</Text>
        <Progress value={completeness * 100} />
        {/* {taskProjects.map((t) => {
          return <Text key={t?.id}>{t?.title}</Text>;
        })} */}
      </TouchableOpacity>
    </Box>
  );
};
