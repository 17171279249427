import { useEffect, useState } from 'react';
import { useGetMyIssuesQuery } from '../../services/youtrack/youtrackApi';
import { dispatch, useAppSelector } from '@/Store/store';
import { createTask } from '@/Store/Tasks/createTask';
import { ITask, PrioritizedTask } from '@/types/task.type';
import prioritizeList from '@/priority';
import tasksSlice from '@/Store/firestore/tasksSlice';

export const useSubscribeToYoutrackTasks = (): PrioritizedTask[] => {
  const youTrack = useAppSelector((state) => state.integrations.youTrack);
  const isValid = youTrack.token && youTrack.serviceUrl;
  const [youTrackTasks, setYouTrackTasks] = useState<PrioritizedTask[]>([]);

  const {
    dueDate: dueDateAlias,
    duration: durationAlias,
    elapsedTime: elapsedTimeAlias,
  } = youTrack.customFields;

  const { data: youtrackIssues } = useGetMyIssuesQuery(undefined, {
    skip: !isValid,
    pollingInterval: 1000 * 60 * 2,
  });

  useEffect(() => {
    if (!youtrackIssues) return;

    const tasks: ITask[] = [];

    youtrackIssues.forEach((issue) => {
      let dueDate: number | null | undefined;
      let duration: number | undefined;
      let elapsedTime: number | undefined;
      for (const field of issue.customFields ?? []) {
        if (dueDate && duration && elapsedTime) break;

        const name = field.projectCustomField?.field?.name;
        if (typeof field.value === 'number') {
          if (name === dueDateAlias) dueDate = field.value;
        } else {
          if (name === durationAlias) duration = field.value?.minutes;
          if (name === elapsedTimeAlias) elapsedTime = field.value?.minutes;
        }
      }
      if (!dueDate || typeof dueDate !== 'number') dueDate = null;
      if (!duration || typeof duration !== 'number') duration = 0;
      if (!elapsedTime || typeof elapsedTime !== 'number') elapsedTime = 0;
      tasks.push(
        createTask({
          id: issue.id,
          origin: 'youtrack',
          timespaces: youTrack.timespace ? [youTrack.timespace] : [],
          mainTimespace: youTrack.timespace ? false : true,
          dueDate: dueDate,
          duration: duration,
          elapsedTime: elapsedTime,
          title: issue.summary,
          status: issue.resolved ? 'completed' : 'active',
          startDate: issue.created,
        }),
      );
    });

    const prioritizedTasks = prioritizeList(tasks);
    dispatch(tasksSlice.actions.setMany(prioritizedTasks));
    setYouTrackTasks(prioritizedTasks);
  }, [
    youtrackIssues,
    dueDateAlias,
    durationAlias,
    elapsedTimeAlias,
    youTrack.timespace,
  ]);

  return youTrackTasks;
};
