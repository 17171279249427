/**
 * This file contains the application's variables.
 *
 * Define color, sizes, etc. here instead of duplicating them throughout the components.
 * That allows to change them more easily later on.
 */

import {
  ThemeColors,
  ThemeFontSize,
  ThemeMetricsSizes,
  ThemeNavigationColors,
} from '@/Theme/theme.type';
import defaultTheme from './base';

/**
 * Colors
 */
export const Colors: ThemeColors & Partial<ThemeNavigationColors> = {
  // Example colors:
  transparent: 'rgba(0,0,0,0)',
  // inputBackground: '#FFFFFF',
  // white: '#ffffff',
  // secondary: '#3d5aff',
  // border: 'rgb(199, 199, 204)',
  success: defaultTheme.colors.success[500],
  warning: defaultTheme.colors.warning[500],
  error: defaultTheme.colors.danger[500],

  /* RNPaper colors */
  primary: defaultTheme.colors.primary[400],
  accent: defaultTheme.colors.primary[500],
  // accent: '#FFF2F9',
  background: defaultTheme.colors.neutral.active,
  /** @color background color for elements containing content, such as cards */
  surface: defaultTheme.colors.neutral.active,
  // onSurface: '#111',
  // text: '#212529',
  text: '#fff',
  disabled: 'steelblue',
  placeholder: 'grey',

  /** @color color for backdrops of various components such as modals. */
  // backdrop: 'black',
  // notification: 'red',
};

export const NavigationColors: Partial<ThemeNavigationColors> = {
  // primary: rgb(0, 122, 255),
  primary: Colors.primary,
  background: Colors.background,
  // card: 'rgb(255, 255, 255)',
  card: Colors.background,
  // text: 'rgb(28, 28, 30)',
  text: Colors.text,
  border: Colors.notification,
  notification: Colors.notification,
};

/**
 * FontSize
 */
export const FontSize: ThemeFontSize = {
  small: 16,
  regular: 20,
  large: 40,
};

/**
 * Metrics Sizes
 */
const tiny = 5; // 10
const small = tiny * 2; // 10
const regular = tiny * 3; // 15
const large = regular * 2; // 30
export const MetricsSizes: ThemeMetricsSizes = {
  tiny,
  small,
  regular,
  large,
};
