import { getEnv, isDevMode } from '@/utils/env';
import fb from '@/utils/firebase';
import { FirebaseFirestoreTypes } from '@react-native-firebase/firestore';
import * as Device from 'expo-device';
import { Platform } from 'react-native';

// set the host and the port property to connect to the emulator
// set these before any read/write operations occur to ensure it doesn't affect your Cloud Firestore data!

// const HOST = Constants.manifest?.extra?.HOST;
const HOST = getEnv('HOST') ?? '127.0.0.1';
const host = Device.isDevice && Platform.OS !== 'web' ? HOST : '127.0.0.1';

const env = {
  host,
  firestorePort: Number(getEnv('FIREBASE_FIRESTORE_PORT') || 8090),
  authPort: Number(getEnv('FIREBASE_AUTH_PORT') || 9099),
  databasePort: Number(getEnv('FIREBASE_DATABASE_PORT') || 9000),
};

console.debug('env', env);

if (getEnv('FIREBASE_SIMULATOR') === 'true') {
  try {
    fb.store.useEmulator(env.host, env.firestorePort);
    fb.database.useEmulator(env.host, env.databasePort);
    // @ts-expect-error
    fb.auth.useEmulator(`http://${env.host}:${env.authPort}`, {
      disableWarnings: isDevMode,
    });
    fb.store.settings({
      host: `${env.host}:${env.firestorePort}`,
      ssl: false,
      ...Platform.select({
        native: {
          persistence: false,
        } as FirebaseFirestoreTypes.Settings,
      }),
    });
    console.log('Using emulators on host', env.host);
  } catch (error) {
    if (error instanceof Error)
      console.error(
        'Error occurred during initializing firebase with emulators on',
        error.message,
      );
  }
}

fb.init();
