import { useAppSelector } from '@/Store/store';
import { syncedTasksSelectors } from '@/Store/firestore/tasksSlice';
import { shallowEqual } from 'react-redux';
import { timespacesSelectors } from '@/Store/firestore/timespacesSlice';

export default function useTask(id: string) {
  return (
    useAppSelector((state) => syncedTasksSelectors.selectById(state, id)) ??
    null
  );
}

export function useTasks() {
  return useAppSelector(
    (state) => syncedTasksSelectors.selectAll(state),
    shallowEqual,
  );
}

export function useTasksById(ids?: string[]) {
  return useAppSelector(
    (state) =>
      !ids ? [] : ids.map((tId) => syncedTasksSelectors.selectById(state, tId)),
    shallowEqual,
  );
}

export function useTimespacesById(ids?: string[]) {
  return useAppSelector((state) =>
    !ids ? [] : ids.map((tsId) => timespacesSelectors.selectById(state, tsId)),
  );
}
