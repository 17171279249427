import storiesSlice from '@/Store/firestore/storiesSlice';
import { useAppDispatch, useAppSelector } from '@/Store/store';
import { IStory } from '@/types/story.type';
import fb from '@/utils/firebase';
import { useEffect } from 'react';

export default function useStories() {
  const dispatch = useAppDispatch();
  const ownerId = useAppSelector((store) => store.user?.uid);
  useEffect(() => {
    if (!ownerId) {
      dispatch(storiesSlice.actions.setStories([]));
      return;
    }

    const storiesSubscriber = fb.store
      .collection('stories')
      .where('ownerId', '==', ownerId)
      .onSnapshot((snapshot) => {
        const stories: IStory[] = [];
        snapshot.forEach((doc) => {
          stories.push({
            ...(doc.data() as IStory),
            id: doc.id,
          });
        });
        dispatch(storiesSlice.actions.setStories(stories));
      });

    return () => storiesSubscriber();
  }, [dispatch, ownerId]);
}
