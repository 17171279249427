import { TimerStatus } from '@/Context/AppStatusContext';
import { ITask } from '@/types/task.type';
import { AsyncThunkConfig } from '@/types/types';
import { getAppStatusRef } from '@/utils/firebase';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import produce from 'immer';
import { dispatch } from '../store';

export const setCurrentTaskThunk = createAsyncThunk<
  Promise<any>,
  ITask | null,
  AsyncThunkConfig
>('status/setCurrentTask', (task, thunkApi) => {
  const state: AppStatus = {
    ...thunkApi.getState().status,
    currentTask: task,
  };
  console.debug('state', state);
  return getAppStatusRef().set(state);
});

export const setTimerStatusThunk = createAsyncThunk<
  Promise<any>,
  TimerStatus,
  AsyncThunkConfig
>('status/setTimerStatus', (timerStatus, thunkApi) => {
  const value = produce(thunkApi.getState().status, (draft) => {
    // const startDate =
    //   timerStatus === 'work' ? Date.now() : draft.timerStartDate;
    const startDate = Date.now();
    const endDate = dayjs(startDate)
      .add(timerStatus === 'work' ? draft.workTime : draft.restTime, 'minute')
      .valueOf();
    draft.timerStatus = timerStatus;
    draft.timerStartDate = startDate;
    draft.timerEndDate = endDate;
  });
  console.debug('value', value);
  return getAppStatusRef().set(value);
});

export type AppStatus = {
  workTime: number;
  restTime: number;
  currentTask: ITask | null;
  timerStatus: TimerStatus;
  timerStartDate: number | null;
  timerEndDate: number | null;
};

const initialState: AppStatus = {
  workTime: 50,
  restTime: 10,
  currentTask: null,
  timerStatus: 'inactive',
  timerStartDate: null,
  timerEndDate: null,
};

const statusSlice = createSlice({
  name: 'status',
  initialState: initialState,
  reducers: {
    setStatusState: (state, action: PayloadAction<AppStatus>) => {
      if (!action.payload) return;
      return action.payload;
    },
    mergeStatusState: (state, action: PayloadAction<AppStatus>) => {
      return { ...state, ...action.payload };
    },
  },
});

export default statusSlice;

export const setCurrentTask = (task: ITask | null) =>
  dispatch(setCurrentTaskThunk(task));
export const setTimerStatus = (status: TimerStatus) =>
  dispatch(setTimerStatusThunk(status));
