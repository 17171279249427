import React from 'react';
import { Text, Center } from 'native-base';

type ErrorViewProps = {
  message: string;
};

export default function ErrorView({ message }: ErrorViewProps) {
  return (
    <Center flex="1">
      <Text>Something went wrong</Text>
      <Text>{message}</Text>
    </Center>
  );
}
