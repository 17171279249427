import Constants from 'expo-constants';
import * as Device from 'expo-device';
import { Platform } from 'react-native';

export const getEnv = (
  name:
    | 'HOST'
    | 'NODE_ENV'
    | 'CHANNEL'
    | 'DEV_YOUTRACK_TOKEN'
    | 'FIREBASE_SIMULATOR'
    | 'FIREBASE_DATABASE_PORT'
    | 'FIREBASE_AUTH_PORT'
    | 'FIREBASE_FIRESTORE_PORT',
): string | undefined => Constants.manifest?.extra?.[name];

export const isDevMode =
  __DEV__ &&
  getEnv('CHANNEL') !== 'production' &&
  getEnv('NODE_ENV') === 'development';

export const isWeb = Device.isDevice && Platform.OS === 'web';
export const isEmulator = !Device.isDevice;
export const isExpoGo = Constants.appOwnership === 'expo';

const platformOnly =
  (platform: typeof Platform.OS) =>
  <T>(prop: T): T | undefined =>
    Platform.OS === platform ? prop : undefined;

export const androidOnly = platformOnly('android');
export const iosOnly = platformOnly('ios');
export const webOnly = platformOnly('web');

export const devOnly = <T>(prop: T): T | undefined =>
  isDevMode ? prop : undefined;

export const usingFirebaseSimulator = getEnv('FIREBASE_SIMULATOR') === 'true';
