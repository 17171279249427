import { ITask, PrioritizedTask, Subtask, TaskDate } from '@/types/task.type';
import { Downgraded, State } from '@hookstate/core';
import { cloneDeep } from 'lodash';
import { createTask } from './Store/Tasks/createTask';
import { BaseEntity } from './types/entity.type';
import { Optional } from 'utility-types';

import * as Sentry from 'sentry-expo';
import { isWeb } from './utils/env';

export const isActive = (item: ITask | Subtask) => {
  return item.status === 'active';
};

export function isNumber(taskDate: TaskDate): taskDate is number {
  return typeof taskDate === 'number';
}

export const HOUR = 60;
export const isObjective = (task: ITask) => task.duration >= HOUR;
export const isLongTerm = (duration: ITask['duration']) => duration > HOUR;
export const isDelayed = (task: ITask, now = Date.now()) => {
  return (
    !task.startDate || now < task.startDate || Array.isArray(task.startDate)
  );
};

export const getDurationMinutes = (duration: number) => duration % 60;
export const getDurationHours = (duration: number) => Math.floor(duration / 60);

export const leadingZero = (val: number | string) => {
  if (+val < 10) {
    return `0${val}`;
  }
  return `${val}`;
};

export const getDurationTime = (duration: number) => {
  const minutes = getDurationMinutes(duration);
  const hours = getDurationHours(duration);
  const clock = `${leadingZero(hours)}:${leadingZero(minutes)}`;
  return { minutes, hours, clock };
};

export const createEmptyTask = () =>
  createTask({ title: '', startDate: Date.now() });
// TODO remove duplicate function from tasks/index

// export const animateNext = () => {
//   LayoutAnimation.configureNext(
//     LayoutAnimation.Presets.linear,
//     () => {},
//     (...args) => {
//       console.error('Animation failed');
//       console.log(args);
//     },
//   );
// };

export function detectTaskType(task: PrioritizedTask) {
  const type = task.duration < 1000 * 60 * 60 * 2 ? 'todo' : 'objective';
  let status: ITask['status'] | 'delayed' = task.status;

  if (typeof task.startDate !== 'number' || task.startDate > Date.now())
    status = 'delayed';

  return `${status}-${type}`;
}

/**
 * Extract nested data from Hookstate
 * It is a workaround to fix bug with extracting nested data from hookstate
 * @see https://github.com/avkonst/hookstate/issues/136
 * @param  {State<any>} state
 */
export const getObjectFromHookstate = <S>(state: State<S>): S =>
  cloneDeep(state.attach(Downgraded).get());

type OptionalEntity = Optional<BaseEntity, 'creationTime' | 'updateTime'>;

/**
 * Update entity time
 */
export const update = <T extends OptionalEntity>(entity: T): T & BaseEntity => {
  const updated = { ...entity };
  const now = Date.now();
  if (!updated.creationTime) updated.creationTime = now;
  updated.updateTime = now;
  return updated as T & BaseEntity;
};
export const u = update;

export { noop } from 'lodash';

// export const captureError = (error: Error) => {
//   const instance = isWeb ? Sentry.Browser : Sentry.Native;
//   instance.captureException(new Error('Error', {}));
// };
