/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { IconProps } from 'react-native-vector-icons/Icon';

function TransparentIcon({
  size = 24,
  ...props
}: {
  size?: number;
  color?: string;
} & Omit<IconProps, 'name' | 'color'>): JSX.Element {
  return (
    <MaterialCommunityIcons size={size} color={'transparent'} {...props} />
  );
}

export default TransparentIcon;
