import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import toObject from 'dayjs/plugin/toObject';
import relativeTime from 'dayjs/plugin/relativeTime';
import objectSupport from 'dayjs/plugin/objectSupport';
import minMax from 'dayjs/plugin/minMax';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import localeData from 'dayjs/plugin/localeData';
import isYesterday from 'dayjs/plugin/isYesterday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isToday from 'dayjs/plugin/isToday';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import calendar from 'dayjs/plugin/calendar';
import updateLocale from 'dayjs/plugin/updateLocale';
import isoWeek from 'dayjs/plugin/isoWeek';

import 'dayjs/locale/ru';
dayjs.extend(weekday);
dayjs.extend(toObject);
dayjs.extend(relativeTime);
dayjs.extend(objectSupport);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);
dayjs.extend(localeData);
dayjs.extend(localeData);
dayjs.extend(isYesterday);
dayjs.extend(isTomorrow);
dayjs.extend(isToday);
dayjs.extend(isBetween);
dayjs.extend(duration);
dayjs.extend(calendar);
dayjs.extend(updateLocale);
dayjs.extend(isoWeek);
