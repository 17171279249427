/**
 * Used to navigating without the navigation prop
 * @see https://reactnavigation.org/docs/navigating-without-navigation-prop/
 *
 * You can add other navigation functions that you need and export them
 */
import * as React from 'react';
import {
  CommonActions,
  createNavigationContainerRef,
  NavigationContainerRef,
} from '@react-navigation/native';
import { AuthenticatedUserStackParamList } from '@/types/navigation.type';

export const navigationRef = createNavigationContainerRef();

// export const navigationRef = React.createRef<NavigationContainerRef>();

type Routes = keyof AuthenticatedUserStackParamList;

export function navigate(name: Routes, params?: any) {
  navigationRef.current?.navigate(name, params);
}

export function navigateAndReset(routes = [], index = 0) {
  navigationRef.current?.dispatch(
    CommonActions.reset({
      index,
      routes,
    }),
  );
}

export function navigateAndSimpleReset(name: string, index = 0) {
  navigationRef.current?.dispatch(
    CommonActions.reset({
      index,
      routes: [{ name }],
    }),
  );
}

// Get the current screen from the navigation state
export function getActiveRouteName(navigationState) {
  if (!navigationState) return null;
  const route = navigationState.routes[navigationState.index];
  // Parse the nested navigators
  if (route.routes) return getActiveRouteName(route);
  return route.routeName;
}
