import { SelectColorModal } from '@/components/ColorsModal';
import ScreenContainer from '@/components/ScreenContainer';
import { NamedColor } from '@/constants/colors';
import timespacesSlice, {
  timespacesSelectors,
} from '@/Store/firestore/timespacesSlice';
import { dispatch, useAppSelector } from '@/Store/store';
import { MainTimespaceScreenProps } from '@/types/navigation.type';
import { Box, Button, useDisclose } from 'native-base';
import React, { useState } from 'react';

export default function MainTimespaceScreen({
  navigation,
}: MainTimespaceScreenProps) {
  const mainTimespace = useAppSelector(timespacesSelectors.selectMainTimespace);

  const [color, setColor] = useState<NamedColor>(mainTimespace.color);

  // useLayoutEffect(() => {
  //   if (!mainTimespace) return;
  //   if (mainTimespace.id === 'default') return;
  //   navigation.setOptions({
  //     headerRight: () => (
  //       <Button
  //         onPress={() => {
  //           navigation.goBack();
  //           dispatch(removeTimespace(mainTimespace.id));
  //         }}
  //       >
  //         Delete
  //       </Button>
  //     ),
  //   });
  // }, [navigation, mainTimespace]);

  const save = () => {
    dispatch(timespacesSlice.actions.setMainTimespace({ color }));
    navigation.goBack();
  };

  const { isOpen, onClose: onColorPickerClose, onToggle } = useDisclose();

  // if (!mainTimespace) return <InfoContainer title="No such timespace" />;
  return (
    <ScreenContainer>
      <Box flex="1" backgroundColor="background">
        <Box flex={1}>
          <Button onPress={onToggle}>{color}</Button>
          <SelectColorModal
            isOpen={isOpen}
            onClose={onColorPickerClose}
            onSelect={setColor}
          />
          {/* <Text mt="2">Importance</Text> */}
          {/* <ImportanceToggles
          value={timespace.importance.get()}
          onToggle={timespace.importance.set}
        /> */}
        </Box>
        <Button isDisabled={false} onPress={save}>
          Save
        </Button>
      </Box>
    </ScreenContainer>
  );

  // if (timespace.id === 'default') return null;

  // return <TimespaceEditor timespace={mainTimespace} />;

  // return <Box />;
}
