import React from 'react';
import { Alert } from 'react-native';
import { AlertDialog, Button, Center } from 'native-base';

import fb from '@/utils/firebase';
import { dispatch, logoutAction } from '@/Store/store';

export const logout = () => {
  return fb.auth
    .signOut()
    .then(() => dispatch(logoutAction()))
    .catch(console.error);
};

const onSignoutPress = () => {
  Alert.alert(
    'Logout',
    'Are you sure you want to logout?',
    [{ text: 'Cancel' }, { text: 'Confirm', onPress: logout }],
    { cancelable: true },
  );
};

const LogoutButton = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  const onClose = () => setIsOpen(false);

  const cancelRef = React.useRef(null);

  return (
    <Center>
      <Button
        shadow={2}
        colorScheme="danger"
        onPress={() => setIsOpen(!isOpen)}
      >
        Logout
      </Button>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Logout</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure you want to logout? Your data may be lost.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                variant="unstyled"
                colorScheme="coolGray"
                onPress={onClose}
                ref={cancelRef}
              >
                Cancel
              </Button>
              <Button colorScheme="danger" onPress={logout}>
                Logout
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </Center>
  );
};

export default LogoutButton;
