import 'expo-dev-client';
import 'react-native-gesture-handler';

import './config/sentry';
import './config/firebase';
import './config/LogBox';
import './config/dates';
import './config/googleSignin';
import './config/notifications';
import './config/debugging';
import './config/DevSettings';

import '@/Store/store'; // Make sure it is initialized early
import * as serviceWorkerRegistration from './src/serviceWorkerRegistration';

import { registerRootComponent } from 'expo';

import { App } from './src/App';

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);

serviceWorkerRegistration.register();

// export { App as default } from './src/App';
