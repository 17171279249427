import React from 'react'
import { StyleSheet } from 'react-native'
import { Input } from 'react-native-elements'
import Icon from 'react-native-vector-icons/FontAwesome'

const BasicInput = ({
  iconName = 'user',
  iconSize = 24,
  iconColor = '#CCCBCA',
  ...rest
}) => (
  <Input
    {...rest}
    leftIcon={<Icon name={iconName} size={iconSize} color={iconColor} />}
    inputContainerStyle={style.InputContainerStyle}
    leftIconContainerStyle={style.LeftIconContainerStyle}
    errorStyle={style.ErrorStyle}
  />
)

const style = StyleSheet.create({
  InputContainerStyle: {
    borderWidth: 1,
    borderRadius: 15,
    borderColor: 'blue',
  },
  LeftIconContainerStyle: {
    marginLeft: 12,
  },
  ErrorStyle: {
    marginLeft: 15,
    marginBottom: 20,
  },
})

export default BasicInput
