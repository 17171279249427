import React from 'react';
import { Entypo, MaterialCommunityIcons } from '@expo/vector-icons';
import { Animated, StyleSheet, View } from 'react-native';
import { Icon } from 'native-base';

const Icons = {
  play: MaterialCommunityIcons,
  pause: MaterialCommunityIcons,
  ['clock-outline']: MaterialCommunityIcons,
  plus: MaterialCommunityIcons,
  minus: MaterialCommunityIcons,
  close: MaterialCommunityIcons,
  backburger: MaterialCommunityIcons,
  ['dots-vertical']: MaterialCommunityIcons,
  ['menu-open']: MaterialCommunityIcons,
  ['menu-left']: MaterialCommunityIcons,
  ['menu-left-outline']: MaterialCommunityIcons,
  ['chevron-left']: MaterialCommunityIcons,
  ['pan-left']: MaterialCommunityIcons,
  ['checkbox-blank-circle-outline']: MaterialCommunityIcons,
  ['checkbox-blank-outline']: MaterialCommunityIcons,
  ['checkbox-blank-off-outline']: MaterialCommunityIcons,
  ['checkbox-marked-circle-outline']: MaterialCommunityIcons,
  ['checkbox-marked-outline']: MaterialCommunityIcons,
  ['circle-off-outline']: MaterialCommunityIcons,
  loading: MaterialCommunityIcons,
  pencil: MaterialCommunityIcons,
  ['content-save']: MaterialCommunityIcons,
  ['text-search']: MaterialCommunityIcons,
  // ['clock-plus-outline']: MaterialCommunityIcons,
  forward: Entypo,
};

type AvailableIcons = keyof typeof Icons;
type NBIconProps = React.ComponentPropsWithoutRef<typeof Icon>;

const animationScale = 1;

function AppIcon({
  name = 'play',
  size = 6,
  color = 'white',
  ...props
}: {
  name: AvailableIcons;
  size?: number;
  color?: string;
} & NBIconProps) {
  const [currentIcon, setCurrentIcon] = React.useState<AvailableIcons>(
    () => name,
  );
  const [previousIcon, setPreviousIcon] = React.useState<AvailableIcons | null>(
    null,
  );
  const { current: fade } = React.useRef<Animated.Value>(new Animated.Value(1));

  const containerSize = size * 4;

  const scale = animationScale;

  if (currentIcon !== name) {
    setPreviousIcon(() => currentIcon);
    setCurrentIcon(() => name);
  }

  React.useEffect(() => {
    if (previousIcon && previousIcon !== currentIcon) {
      fade.setValue(1);

      Animated.timing(fade, {
        duration: scale * 200,
        toValue: 0,
        useNativeDriver: true,
      }).start();
    }
  }, [currentIcon, previousIcon, fade, scale]);

  const opacityPrev = fade;
  const opacityNext = previousIcon
    ? fade.interpolate({
        inputRange: [0, 1],
        outputRange: [1, 0],
      })
    : 1;

  const rotatePrev = fade.interpolate({
    inputRange: [0, 1],
    outputRange: ['-90deg', '0deg'],
  });

  const rotateNext = previousIcon
    ? fade.interpolate({
        inputRange: [0, 1],
        outputRange: ['0deg', '-180deg'],
      })
    : '0deg';

  const IconSource = Icons[name] ?? MaterialCommunityIcons;

  return (
    <View
      style={[
        styles.content,
        {
          height: containerSize,
          width: containerSize,
        },
      ]}
    >
      {previousIcon ? (
        <Animated.View
          style={[
            styles.icon,
            {
              opacity: opacityPrev,
              transform: [{ rotate: rotatePrev }],
            },
          ]}
        >
          {/* <IconSource name={previousIcon} {...props} /> */}
          <Icon
            name={previousIcon}
            size={size}
            color={color}
            as={IconSource}
            {...props}
          />
        </Animated.View>
      ) : null}
      <Animated.View
        style={[
          styles.icon,
          {
            opacity: opacityNext,
            transform: [{ rotate: rotateNext }],
          },
        ]}
      >
        <Icon
          name={currentIcon}
          size={size}
          color={color}
          as={IconSource}
          {...props}
        />
      </Animated.View>
    </View>
  );
}

export default AppIcon;

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
});
