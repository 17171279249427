import layout from '@/constants/layout';
import React from 'react';
import {
  SafeAreaView,
  ScrollView,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

type Props = React.PropsWithChildren<
  React.ComponentPropsWithoutRef<typeof ScrollView>
> & {
  noScroll?: boolean;
  style?: StyleProp<ViewStyle>;
};

export default function ScreenContainer({
  children,
  noScroll = false,
  style,
}: Props) {
  if (noScroll)
    return (
      <SafeAreaView style={layout.flex}>
        <View style={[styles.container, style]}>{children}</View>
      </SafeAreaView>
    );

  return (
    <SafeAreaView style={layout.flex}>
      <KeyboardAwareScrollView
        style={styles.shrink}
        contentContainerStyle={styles.grow}
      >
        <View style={[styles.container, style]}>{children}</View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: layout.container.paddingHorizontal,
  },
  shrink: {
    flexShrink: 1,
  },
  grow: {
    flexGrow: 1,
  },
});
