import { initialTasks } from './initial';
import { useAppSelector } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ITask, TaskId, TaskList } from '@/types/task.type';
import { RootState, useAppDispatch } from '@/Store/store';

const initialState: TaskList = [];

export const slice = createSlice({
  name: 'tasks',
  initialState: initialState as TaskList,
  reducers: {
    addTask: (state, action: PayloadAction<ITask>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      console.log(action);
      state.push(action.payload);
    },
    removeTask: (state, action: PayloadAction<TaskId>) => {
      return state.filter((task) => task.id !== action.payload);
    },
    modifyTask: (
      state,
      action: PayloadAction<{ id: TaskId; fields: Partial<ITask> }>,
    ) => {
      return state.map((task) => {
        if (task.id === action.payload.id) {
          return { ...task, ...action.payload.fields };
        }
        return task;
      });
    },
    changeStatus: (
      state,
      action: PayloadAction<{ status: ITask['status']; task: ITask }>, // TODO Change to ID
    ) => {
      // console.debug('completeTask', action);
      state.forEach((task) => {
        if (task.id === action.payload.task.id) {
          task.status = action.payload.status;
        }
      });
    },
    removeAllTasks: () => {
      return [];
    },
    incrementTaskTime: (
      state,
      action: PayloadAction<{ id: TaskId; time: number }>,
    ) => {
      return state.map((task) => {
        if (task.id === action.payload.id) {
          return {
            ...task,
            elapsedTime: task.elapsedTime + action.payload.time,
          };
        }
        return task;
      });
    },

    toggleSubtask: (
      state,
      action: PayloadAction<{ id: TaskId; index: number }>,
    ) => {
      state.forEach((task) => {
        if (task.id === action.payload.id) {
          const subtask = task.subtasks[action.payload.index];
          subtask.status = subtask.status === 'active' ? 'completed' : 'active';
        }
      });
    },
  },
});

export const {
  addTask,
  changeStatus,
  removeTask,
  removeAllTasks,
  incrementTaskTime,
  modifyTask,
  toggleSubtask,
} = slice.actions;

/* The function below is called a thunk and allows us to perform async logic. It
can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
will call the thunk with the `dispatch` function as the first argument. Async
code can then be executed and other actions can be dispatched */
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementTime(amount))
//   }, 1000)
//}

/* The function below is called a selector and allows us to select a value from
the state. Selectors can also be defined inline where they're used instead of
in the slice file. For example: `useSelector((state) => state.counter.value)` */

export const selectTasks = (state: RootState) => state.tasks;
export const selectTask = (id: TaskId) => (state: RootState) =>
  state.tasks.find((task) => task.id === id);

export const useSelectTasks = () => {
  return useAppSelector(selectTasks);
};

export const useSelectTask = (id?: TaskId) => {
  return useAppSelector((state: RootState) => {
    if (!id) return;
    return state.tasks.find((task) => task.id === id);
  });
};

export default slice.reducer;
