import React from 'react';
import { Center, Heading, Text } from 'native-base';

type Props = {
  title: string;
  message?: string;
  image?: React.ReactNode;
};

export default function InfoContainer({ title, message, image }: Props) {
  return (
    <Center flex={1}>
      <Heading>{title}</Heading>
      <Text>{message}</Text>
      {image}
    </Center>
  );
}
