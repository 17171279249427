export const HOURS_IN_DAY = 24;

export const DAY_IN_MS = 1000 * 60 * 60 * 24;

export const WEEKDAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];
