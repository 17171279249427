import React from 'react';
import { Platform } from 'react-native';
import {
  BottomTabNavigationOptions,
  createBottomTabNavigator,
} from '@react-navigation/bottom-tabs';
import Ionicons from 'react-native-vector-icons/Ionicons';
import Tasks from '@/Containers/Tasks/TasksTab';
import Timer from '@/Containers/Timer/TimerTab';
import DiaryScreen from '@/Containers/Diary/DiaryTab';
import CalendarScreen from '@/Containers/Calendar/CalendarTab';

import { enableScreens } from 'react-native-screens';
import CustomTabBar from './components/CustomTabBar';
import CleanTabBar from './components/CleanTabBar';
import BottomTabBar from './components/NativeTabs/views/BottomTabBar';
import { TabNavigatorParamList } from '@/types/navigation.type';

enableScreens(true);

const Tab = createBottomTabNavigator<TabNavigatorParamList>();

const RouteIconMap = {
  Tasks: 'ios-list',
  Timer: 'ios-timer',
  Calendar: 'calendar',
  Goals: 'start',
  Diary: 'book',
  Settings: 'cogs',
} as const;

const tabNavigationOptions = ({ route }: any) =>
  ({
    tabBarHideOnKeyboard: Platform.OS === 'ios' ? false : true,
    headerShown: false,
    safeAreaInsets: { top: 0 },
    gestureEnabled: false,
    tabBarIcon: ({ color, size }) => {
      const iconName = RouteIconMap[route.name as keyof typeof RouteIconMap];
      // You can return any component that you like here!
      return <Ionicons name={iconName} size={size} color={color} />;
    },
  } as BottomTabNavigationOptions);

// @refresh reset
const TabNavigator = () => {
  return (
    <Tab.Navigator
      key={'TabNavigator'}
      backBehavior="none"
      screenOptions={tabNavigationOptions}
      // tabBar={(props) => <CustomTabBar {...props} />}
      // tabBar={(props) => <CleanTabBar {...props} />}
      tabBar={(props) => <BottomTabBar {...props} />}
    >
      <Tab.Screen name="Tasks" component={Tasks} />
      <Tab.Screen name="Timer" component={Timer} />
      {/* <Tab.Screen name="Calendar" component={Calendar} /> */}
      {/* <Tab.Screen name="Goals" component={Goals} /> */}
      <Tab.Screen name="Diary" component={DiaryScreen} />
      <Tab.Screen name="Calendar" component={CalendarScreen} />
    </Tab.Navigator>
  );
};

export default TabNavigator;
