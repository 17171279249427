import { Dimensions, ViewStyle } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

const layout = {
  flex: {
    flex: 1,
  },
  shrink: {
    flexShrink: 1,
  },
  grow: {
    flexGrow: 1,
  },
  container: {
    paddingHorizontal: 8,
  },
  window: {
    width,
    height,
  },
  isSmallDevice: width < 375,

  row: {
    flexDirection: 'row',
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center',
  },
} as const;

export default layout;
