import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { removeTimespace } from './timespacesSlice';

// const calendarsAdapter = createEntityAdapter<ICalendar>();

export type IntegrationsState = {
  youTrack: {
    token?: string;
    serviceUrl?: string;
    /** Match YouTrack custom fields with Tastik task properties */
    customFields: {
      assignee?: string;
      dueDate?: string;
      duration?: string;
      elapsedTime?: string;
    };
    timespace?: string;
    lastUpdate?: null;
  };
  notion: {
    token?: string;
    timespace?: string;
  };
};

const initialState: IntegrationsState = {
  youTrack: {
    customFields: {},
    lastUpdate: null,
  },
  notion: {},
};

const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    setYouTrackToken: (state, action: PayloadAction<string | undefined>) => {
      state.youTrack.token = action.payload;
    },
    setYouTrackUrl: (state, action: PayloadAction<string | undefined>) => {
      state.youTrack.serviceUrl = action.payload;
    },
    removeYouTrackIntegration: (state) => {
      state.youTrack = initialState.youTrack;
    },
    setCustomFields: (
      state,
      action: PayloadAction<IntegrationsState['youTrack']['customFields']>,
    ) => {
      if (!state.youTrack.customFields) state.youTrack.customFields = {};
      Object.assign(state.youTrack.customFields, action.payload);
    },
    setYouTrackTimespace: (state, action: PayloadAction<string>) => {
      state.youTrack.timespace = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(removeTimespace.fulfilled, (state, action) => {
      const timespaceId = action.meta.arg;
      Object.keys(state).forEach((integration) => {
        const ts = state[integration as keyof typeof state].timespace;
        if (ts === timespaceId)
          state[integration as keyof typeof state].timespace = undefined;
      });
    });
  },
});

// export const calendarsSelectors = calendarsAdapter.getSelectors<RootState>(
//   (state) => state.calendars,
// );

// export const calendarsSelectors = calendarsAdapter.getSelectors();
export const {
  setYouTrackToken,
  setYouTrackUrl,
  removeYouTrackIntegration,
  setCustomFields,
} = integrationsSlice.actions;
export default integrationsSlice;

// dispatch(removeYouTrackIntegration());
