import AsyncStorage from '@react-native-async-storage/async-storage';
import LocalStorage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import {
  persistReducer,
  persistStore,
  purgeStoredState,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { configureStore, createAction } from '@reduxjs/toolkit';
import FlipperMiddleware from 'redux-flipper';

// import startup from './Startup';
import userSlice from './User';
// import theme from './Theme';
import tasks from './Tasks';
import stories from './Stories';
import syncedStories from './firestore/storiesSlice';
import settingsSlice from './firestore/settingsSlice';
import calendarsSlice from './firestore/calendarsSlice';
import projectsSlice from './firestore/projectsSlice';
import timespacesSlice from './firestore/timespacesSlice';

import timeblocks from './Timeblocks';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import syncedTasks from './firestore/tasksSlice';
import integrationsSlice from './firestore/integrationsSlice';
import youtrackApi from '@/services/youtrack/youtrackApi';
import { rtkQueryErrorLogger } from './middlewares/errorHandlerMiddleware';
import { isWeb } from '@/utils/env';
import statusSlice from './firestore/statusSlice';

// const youTrackReducerPath = youTrackApi.reducerPath;
// youTrackApi.reducer;

export const logoutAction = createAction('logout');

const reducers = combineReducers({
  [youtrackApi.reducerPath]: youtrackApi.reducer,
  user: userSlice.reducer,
  [statusSlice.name]: statusSlice.reducer,
  settings: settingsSlice.reducer,
  calendars: calendarsSlice.reducer,
  projects: projectsSlice.reducer,
  timespaces: timespacesSlice.reducer,
  // theme,
  tasks,
  stories,
  syncedStories: syncedStories.reducer,
  timeblocks,
  syncedTasks: syncedTasks.reducer,
  integrations: integrationsSlice.reducer,
  // ['hello-wrold']: 'hi',
  // youTrack: youTrackApi;
  // [youTrackReducerPath]
  // [youTrackApi.red]
  // [youTrackApi.reducerPath]: youTrackApi.reducer,
  // [youTrackApi]
  // calendars
});

const persistConfig = {
  key: 'root',
  storage: isWeb ? LocalStorage : AsyncStorage,
  whitelist: [
    'theme',
    'stories',
    'calendars',
    'projects',
    'integrations',
    // 'timespaces',
  ],
  // blacklist: [youTrackApi.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const rootReducer: typeof persistedReducer = (state, action) => {
  if (action.type === logoutAction.type) {
    console.log('Performing logout');
    purgeStoredState(persistConfig);
    return persistedReducer(undefined, action);
  }
  return persistedReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const middlewares = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      // TODO: Decide whether we need these checks as they cause too much slowdown in dev mode
      immutableCheck: false,
      // serializableCheck: false,
    })
      .prepend(rtkQueryErrorLogger)
      .concat(youtrackApi.middleware)
      .concat(FlipperMiddleware());

    // Add RTK Query api middlewares;
    // middlewares.concat(youTrackApi.middleware);

    // if (__DEV__ && !process.env.JEST_WORKER_ID) {
    //   middlewares.push(FlipperMiddleware());
    //   console.debug('Add flipper middleware');
    // }

    return middlewares;
  },
});

const persistor = persistStore(store);

export { store, persistor };

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const dispatch = store.dispatch;
