import {
  COLORS,
  COLOR_NAMES,
  POPULAR_COLOR_NAMES,
  NamedColor,
} from '@/constants/colors';
import { Box, Button, Modal, ScrollView } from 'native-base';
import React, { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { COLOR_WIDTH } from '../Containers/projects/AllProjectsScreen';

const PopularColors = React.memo(
  ({ setColor }: { setColor: (color: NamedColor) => void }) => {
    return (
      <>
        {POPULAR_COLOR_NAMES.map((i) => (
          <TouchableOpacity key={i} onPress={() => setColor(i)}>
            <Box
              h={3 * COLOR_WIDTH}
              w={3 * COLOR_WIDTH}
              // borderRadius="0"
              background={COLORS[i]}
            />
          </TouchableOpacity>
        ))}
      </>
    );
  },
);
const AllColors = React.memo(
  ({ setColor }: { setColor: (color: NamedColor) => void }) => {
    return (
      <>
        {COLOR_NAMES.map((i) => (
          <TouchableOpacity key={i} onPress={() => setColor(i)}>
            <Box h="30px" w="30px" background={COLORS[i]} />
          </TouchableOpacity>
        ))}
      </>
    );
  },
);
type ColorModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (color: NamedColor) => void;
};
export const SelectColorModal = ({
  isOpen,
  onClose,
  onSelect,
}: ColorModalProps) => {
  const [color, setColor] = useState<NamedColor>('blue');

  const onSave = () => {
    onSelect(color);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content maxHeight={'4/5'}>
        <Modal.CloseButton />
        <Modal.Header>{color}</Modal.Header>
        <Modal.Body>
          <Box flexDirection="row" flexWrap="wrap">
            <PopularColors setColor={setColor} />
          </Box>
          <ScrollView
            showsHorizontalScrollIndicator
            persistentScrollbar
            horizontal
            py="4"
          >
            <AllColors setColor={setColor} />
          </ScrollView>
        </Modal.Body>

        <Modal.Footer>
          <Button.Group space={2}>
            <Button variant="ghost" colorScheme="blueGray" onPress={onClose}>
              Cancel
            </Button>
            <Button onPress={onSave}>Save</Button>
          </Button.Group>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
