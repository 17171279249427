import { SelectColorModal } from '@/components/ColorsModal';
import ImportanceToggles from '@/components/ImportanceToggles';
import timespacesSlice, {
  createTimespace,
  upsertTimespace,
} from '@/Store/firestore/timespacesSlice';
import { dispatch } from '@/Store/store';
import { ITimespace } from '@/types/timespace.type';
import { getObjectFromHookstate } from '@/utils';
import { useHookstate } from '@hookstate/core';
import { Box, Button, Input, Text, useDisclose } from 'native-base';
import React from 'react';

const defaultOnSave = (data: ITimespace) => {
  return dispatch(upsertTimespace(createTimespace(data)));
};

export default function TimespaceEditor({
  timespace: oldTimespace,
  onSave = defaultOnSave,
}: {
  timespace?: ITimespace;
  onSave?: (data: ITimespace) => void;
}) {
  // const oldTimespace = route.params?.timespace;
  // const dispatch = useAppDispatch();
  const timespace = useHookstate<Partial<ITimespace>>(
    () => oldTimespace ?? (createTimespace({ name: '' }) as ITimespace),
  );

  const { isOpen, onClose: onColorPickerClose, onToggle } = useDisclose();

  const isValid = !!timespace.name.get();

  const save = () => {
    const ts = getObjectFromHookstate(timespace) as ITimespace;
    if (!ts.name) return;
    onSave(createTimespace(ts));
    timespace.set(createTimespace({ name: '' }));
    onColorPickerClose();
  };

  return (
    <Box flex="1" backgroundColor="background">
      <Box flex={1}>
        <Input
          placeholder="Name"
          value={timespace.name.get()}
          onChangeText={timespace.name.set}
        />
        <Button onPress={onToggle}>{timespace.color.get()}</Button>
        <SelectColorModal
          isOpen={isOpen}
          onClose={onColorPickerClose}
          onSelect={(color) => timespace.color.set(color)}
        />
        <Text mt="2">Importance</Text>
        <ImportanceToggles
          value={timespace.importance.get()}
          onToggle={timespace.importance.set}
        />
      </Box>
      <Button isDisabled={!isValid} onPress={save}>
        Save
      </Button>
    </Box>
  );
}
