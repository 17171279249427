import InfoContainer from '@/components/InfoContainer';
import React from 'react';

type Props = {
  title: string;
  message?: string;
  image?: React.ReactNode;
};

export default function InfoScreen({ navigation, route }) {
  const { title, message, image } = route.params as Props;
  return <InfoContainer title={title} message={message} image={image} />;
}
