import { useAppDispatch } from '@/Store/store';
import { removeStory } from '@/Store/Stories';
import { IStory } from '@/types/story.type';
import {
  Box,
  Checkbox,
  Button,
  Divider,
  Pressable,
  Row,
  Text,
  VStack,
} from 'native-base';
import * as React from 'react';

import { shallowEqual } from 'react-redux';

type StoryComponentProps = {
  story: IStory;
};

const StoryCard = ({ story }: StoryComponentProps) => {
  const dispatch = useAppDispatch();
  return (
    <Box borderRadius="md" margin="2">
      <VStack space="4">
        <Box flexDirection={'row-reverse'}>
          <Text fontSize={12} pr="2">
            {new Date(story.updateTime).toLocaleString()}
          </Text>
        </Box>

        <Box px="4" pt="0">
          <Text>{story.title}</Text>
        </Box>

        {!!story.content && (
          <Box px="4">
            <Text fontSize="sm">{story.content}</Text>
          </Box>
        )}

        <Box px="4" flexDirection="row" flexWrap="wrap">
          {story.tasks.map((task) => (
            <Row key={task.id} alignItems="center">
              <Checkbox
                isChecked
                isDisabled
                value={task.title}
                accessibilityLabel={task.title}
              />
              <Text fontSize="sm" px="1">
                {task.title}
              </Text>
            </Row>
          ))}
        </Box>

        <Row flexWrap="wrap" px="4">
          {story.tags.map((tag) => (
            <Text fontSize="sm" key={tag}>
              #{tag}
            </Text>
          ))}
        </Row>
      </VStack>
    </Box>
  );
};

export default StoryCard;
