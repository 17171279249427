import { HOURS_IN_DAY } from '@/constants/time';
import statusSlice, { AppStatus } from '@/Store/firestore/statusSlice';
import { dispatch } from '@/Store/store';
import { timeblocksSlice } from '@/Store/Timeblocks';
import { IAppData } from '@/types/appData';
import { ITimeblock } from '@/types/timeblock.type';
import fb, { getCollection } from '@/utils/firebase';
import { chunk } from 'lodash';
import { useEffect } from 'react';

export default function useAppDataSubscription(): void {
  const ownerId = fb.getUser()?.uid;

  useEffect(() => {
    if (!ownerId) {
      fb.auth.signOut();
      return;
    }

    const appDataSubscriber = getCollection('appData')
      .doc(ownerId)
      .onSnapshot((doc) => {
        if (!doc.exists) return;
        const appData = doc.data() as IAppData;
        const { settings } = appData;
        const timeblocks: ITimeblock[][] = chunk(
          appData.timeblocks,
          HOURS_IN_DAY,
        );
        dispatch(timeblocksSlice.actions.setAllTimeblocks(timeblocks));
      });

    return () => appDataSubscriber();
  }, [ownerId]);

  useEffect(() => {
    const onValueChange = fb.database
      .ref(`/appStatus/${ownerId}`)
      .on('value', (snapshot) => {
        const value = snapshot.val() as AppStatus;
        if (!value) return;
        dispatch(statusSlice.actions.setStatusState(value));
      });

    // Stop listening for updates when no longer required
    return () =>
      fb.database.ref(`/appStatus/${ownerId}`).off('value', onValueChange);
  }, [ownerId]);
}
