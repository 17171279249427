import React from 'react';

import TaskItem from './TaskItem';

import {
  CreateTaskScreenProps,
  TaskContainerProps,
} from '@/types/navigation.type';
import { SafeAreaView } from 'react-native-safe-area-context';
import TaskInput from './TaskInput';
import { TaskCallback } from '@/types/task.type';
import { addTask, modifyTask } from '@/Store/firestore/tasksSlice';
import { dispatch } from '@/Store/store';

// type TaskContainerProps = {
//   navigation: RouteProp<RootStackParamList, 'Task'>
//   route: StackNavigationProp<RootStackParamList, 'Task'>
// }

export default function CreateTaskScreen({
  route,
  navigation,
}: CreateTaskScreenProps) {
  // const id = route.params.id;
  const onClose = navigation.canGoBack()
    ? navigation.goBack
    : () => navigation.navigate('Home');

  const onSubmitChanges: TaskCallback = (task_) => {
    if (task_) {
      dispatch(addTask(task_));
      // dispatch(modifyTask(task_));
    }
  };

  return <TaskInput onSubmit={onSubmitChanges} />;
}
