import { ITask } from '@/types/task.type';
import { IMainTimespace, ITimespace } from '@/types/timespace.type';
import { AsyncThunkConfig, ThunkVoidReturn } from '@/types/types';
import { update } from '@/utils';
import fb, { getDoc } from '@/utils/firebase';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import produce from 'immer';
import { isFalsy, Required } from 'utility-types';
import { AppDispatch, RootState } from '../store';
import { setAllTimeblocks } from '../Timeblocks';

export type NewTimespace = Partial<ITimespace>;

export const createTimespace = (props?: NewTimespace): ITimespace => {
  const id = getDoc('timespaces', props?.id).id;
  const ownerId = fb.getUser()?.uid;
  if (!ownerId) fb.auth.signOut();
  return update({
    ownerId: ownerId ?? '',
    id,
    name: props?.name ?? id,
    color: 'blue',
    importance: 1,
    ...props,
  });
};

export const upsertTimespace = createAsyncThunk<
  void,
  Partial<ITimespace>,
  AsyncThunkConfig
>('timespaces/upsertOne', async (timespace) => {
  await fb
    .getCollection('timespaces')
    .doc(timespace.id)
    .set(timespace, { merge: true });
});

export const uploadTimespaces = createAsyncThunk<
  void,
  undefined,
  AsyncThunkConfig
>('timespaces/uploadLocal', async (_, thunkApi) => {
  const store = thunkApi.getState();
  const ownerId = fb.getUser()?.uid;
  const timespaces = Object.values(store.timespaces.entities).filter(
    Boolean,
  ) as ITimespace[];
  const res = await Promise.all(
    timespaces.map((t) =>
      getDoc('timespaces', t.id).set({ ...t, ownerId: ownerId ?? '' }),
    ),
  );
  console.warn(res);
});

export const removeTimespace = createAsyncThunk<void, string, AsyncThunkConfig>(
  'timespaces/remove',
  (id: string, thunkApi) => {
    const state = thunkApi.getState();
    return Promise.all(
      state.tasks.map((t) => {
        if (t.timespaces.includes(id))
          return fb.getDoc('tasks', t.id).update({
            timespaces: t.timespaces.filter((ts) => ts !== id),
          });
        return Promise.resolve();
      }),
    )
      .then(() => console.warn('Deleted timespace', id))
      .then(() => {
        const newTimeblocks = produce(state.timeblocks, (draft) => {
          draft.forEach((days) =>
            days.forEach((hour) => hour.timespaces.filter((ts) => ts === id)),
          );
        });
        thunkApi.dispatch(setAllTimeblocks(newTimeblocks));
      })
      .then(() => getDoc('timespaces', id).delete())
      .catch(console.error);
  },
);

const timespacesAdapter = createEntityAdapter<ITimespace>({
  // Assume IDs are stored in a field other than `book.id`
  // selectId: (task) => task.id,
  // Keep the "all IDs" array sorted based on book titles
  // sortComparer: (a, b) => b.priority - a.priority,
});

const initialState = timespacesAdapter.getInitialState({
  main: {
    // name: 'main',
    color: 'blue',
  } as IMainTimespace,
});

const timespacesSlice = createSlice({
  name: 'timespaces',
  initialState,
  reducers: {
    reset: () => initialState,
    setMainTimespace: (state, action: PayloadAction<IMainTimespace>) => {
      state.main = action.payload;
    },
    setAllTimespaces: timespacesAdapter.setAll,
    // addTimespace: timespacesAdapter.addOne,
    // removeTimespace: (state, action: PayloadAction<string>) =>
    // Do not delete default timespace;

    // upsertTimespace: timespacesAdapter.upsertOne,
  },
  // extraReducers: (builder) => {
  //   builder.addCase(removeTimespace.fulfilled);
  // },
});

// Can create a set of memoized selectors based on the location of this entity state
export const timespacesSelectors = {
  ...timespacesAdapter.getSelectors<RootState>((state) => state.timespaces),
  selectMainTimespace: (state: RootState) => state.timespaces.main,
};

// And then use the selectors to retrieve values
// const selectSyncedTasks = tasksSelectors.selectAll(store.getState())

export default timespacesSlice;
