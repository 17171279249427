/* eslint-disable no-alert */
import * as Notifications from 'expo-notifications';
import Constants from 'expo-constants';
import { Platform } from 'react-native';
import { IEvent } from '@/Containers/Calendar/CalendarTab';
import dayjs from 'dayjs';

// https://docs.expo.dev/versions/latest/sdk/notifications/#datetriggerinput
// https://docs.expo.dev/versions/latest/sdk/notifications/#notificationcontentinput

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: true,
    shouldSetBadge: true,
  }),
});

async function schedulePushNotification() {
  await Notifications.scheduleNotificationAsync({
    content: {
      title: "You've got mail! 📬",
      body: 'Here is the notification body',
      data: { data: 'goes here' },
    },
    trigger: { seconds: 2 },
  });
}

export async function scheduleTimeblockNotification(event: IEvent) {
  // if (isWeb) return console.warn('Notifications are not available in web');
  if (!event.task) return;
  // console.debug(
  //   'Notification',
  //   event.task.title,
  //   event.startDate.toLocaleString(),
  // );

  const startDate = dayjs(event.startDate).subtract(15, 'minute').valueOf();

  Promise.all([
    startDate < Date.now()
      ? Promise.resolve()
      : await Notifications.scheduleNotificationAsync({
          content: {
            title: event.task.title,
            body: 'Prepare for task',
            priority: Notifications.AndroidNotificationPriority.DEFAULT,
          },
          trigger: {
            date: dayjs(event.startDate).subtract(15, 'minute').valueOf(),
          },
        }),
    await Notifications.scheduleNotificationAsync({
      content: {
        title: event.task.title,
        body: 'Time to work!',
        priority: Notifications.AndroidNotificationPriority.MAX,
        vibrate: [0, 250, 250, 250],
      },
      trigger: {
        date: event.startDate,
      },
    }),
  ]);
}

export async function scheduleTimeblocksFromEvents(events: IEvent[]) {
  Notifications.cancelAllScheduledNotificationsAsync();
  return Promise.all(
    events.map((event) => scheduleTimeblockNotification(event)),
  );
}

async function registerForPushNotificationsAsync() {
  let token;
  if (Constants.isDevice) {
    const { status: existingStatus } =
      await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      alert('Failed to get push token for push notification!');
      return;
    }
    token = (await Notifications.getExpoPushTokenAsync()).data;
    console.log(token);
  } else {
    alert('Must use physical device for Push Notifications');
  }

  if (Platform.OS === 'android') {
    Notifications.setNotificationChannelAsync('default', {
      name: 'default',
      importance: Notifications.AndroidImportance.MAX,
      vibrationPattern: [0, 250, 250, 250],
      lightColor: '#FF231F7C',
    });
  }

  return token;
}
