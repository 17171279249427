import { BlankSpacer } from '@/components';
import ImportanceToggles from '@/components/ImportanceToggles';
import ScreenContainer from '@/components/ScreenContainer';
import SubtasksEditor from '@/Containers/Tasks/components/SubtasksEditor';
import { navigate } from '@/Navigators/navigation';
import { timespacesSelectors } from '@/Store/firestore/timespacesSlice';
import { useAppSelector } from '@/Store/store';
import { createTask } from '@/Store/Tasks/createTask';
import { AuthenticatedUserStackParamList } from '@/types/navigation.type';
import {
  EmptyTask,
  ITask,
  Subtask,
  TaskCallback,
  TaskUpdateFunc,
} from '@/types/task.type';
import { NavigationProp, useNavigation } from '@react-navigation/native';
import produce from 'immer';
import {
  AddIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Input,
  Row,
  Switch,
  Text,
  theme,
} from 'native-base';
import React, { useRef, useState } from 'react';
import { StyleSheet, TextInput, View } from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Appbar, Divider } from 'react-native-paper';
import { useImmer } from 'use-immer';
// import getStyle from '_styles'
import DatesPicker from './components/DatesPicker';

type props = {
  task?: ITask;
  onSubmit: TaskCallback;
  onClose?: TaskCallback;
};

// const emptyTask = createTask({ title: '' });

export default function TaskInput({ task, onSubmit, onClose }: props) {
  const [newTask, setNewTask] = useImmer(
    () => task ?? createTask({ title: '' }),
  );
  // const [isObjective, setIsObjective] = useState(false)
  const [subtaskTitle, setSubtaskTitle] = useState('');
  const timespaces = useAppSelector(timespacesSelectors.selectAll);
  // const navigation =
  //   useNavigation<NavigationProp<AuthenticatedUserStackParamList>>();

  const [selectedTimespaces, setSelectedTimespaces] = useState<string[]>(
    newTask.timespaces,
  );

  const updateTask: TaskUpdateFunc = (prop, value) => {
    setNewTask((draft) => {
      draft[prop] = value;
    });
  };
  const setSubtasks = (subtasks: Subtask[]) => {
    updateTask('subtasks', subtasks);
  };

  const titleInputEl = useRef<TextInput>(null);
  const subtaskInputEl = useRef<TextInput>(null);

  const handleSubmit = () => {
    if (!newTask.title) {
      // TODO toast!
      return;
    }
    onSubmit({ ...newTask, timespaces: selectedTimespaces });
    clearState();
  };

  const clearState = () => {
    setNewTask(createTask({ title: '', startDate: Date.now() }));
    // setIsObjective(false)
    setSubtaskTitle('');
    setSelectedTimespaces([]);
  };

  const completeSubtask = (index: number) => {
    updateTask(
      'subtasks',
      produce(newTask.subtasks, (draft) => {
        const status = draft[index].status;
        draft[index].status = status === 'active' ? 'completed' : 'active';
      }),
    );
  };

  const removeSubtask = (index: number) =>
    updateTask(
      'subtasks',
      newTask.subtasks.filter((item, i) => i !== index),
    );

  const focusInput = () => {
    if (titleInputEl.current) {
      titleInputEl.current.focus();
    }
  };

  const addSubtask = () => {
    if (!subtaskTitle) {
      return;
    }
    updateTask('subtasks', [
      ...newTask.subtasks,
      {
        title: subtaskTitle,
        status: 'active',
        creationTime: Date.now(),
      } as Subtask,
    ]);
    setSubtaskTitle('');
  };

  const onToggleSwitch = () => {
    // setIsObjective(!isObjective)
  };

  const onImportanceToggle = (value: ITask['importance']) =>
    updateTask('importance', value);

  const isValid =
    !!newTask.title &&
    (selectedTimespaces.length >= 1 || newTask.mainTimespace);

  return (
    <>
      <ScreenContainer>
        <Flex flex="1" m="2">
          <View>
            <Input
              isRequired
              fontSize={'xl'}
              clearTextOnFocus={false}
              variant={'unstyled'}
              placeholder="What to do?"
              autoFocus
              ref={titleInputEl}
              returnKeyType="done"
              onSubmitEditing={handleSubmit}
              multiline
              blurOnSubmit
              onChangeText={(value) => updateTask('title', value)}
              value={newTask.title}
              marginBottom="4"
            />
            <Input
              fontSize={'md'}
              value={newTask.notes}
              onChangeText={(val) => updateTask('notes', val)}
              placeholder="Notes"
              multiline
              selectionColor="primary.600"
              variant="unstyled"
            />
            <SubtasksEditor
              subtasks={newTask.subtasks}
              setSubtasks={setSubtasks}
            />
            <Input
              value={subtaskTitle}
              placeholder="Add subtask"
              fontSize="md"
              ref={subtaskInputEl}
              returnKeyType="next"
              onSubmitEditing={() => {
                if (subtaskInputEl.current) {
                  subtaskInputEl.current.focus();
                  addSubtask();
                }
              }}
              onChangeText={(text) => setSubtaskTitle(text)}
              variant="unstyled"
              blurOnSubmit={false}
            />
            <View style={s.importanceContainer}>
              <ImportanceToggles
                onToggle={onImportanceToggle}
                value={newTask.importance}
              />
            </View>
            <BlankSpacer height={20} />
            <Divider />
            <DatesPicker {...newTask} onChange={updateTask} />
            <View style={s.objectiveContainer} />
            <Box>
              <Row>
                <Text>Select timespaces</Text>
                <IconButton
                  icon={<AddIcon />}
                  size="2"
                  onPress={() => navigate('CreateTimespace')}
                />
              </Row>
              <Row marginTop="6">
                <Text>Main timespace</Text>
                <Switch
                  size="sm"
                  value={newTask.mainTimespace}
                  onToggle={() =>
                    updateTask('mainTimespace', !newTask.mainTimespace)
                  }
                />
              </Row>
              <Box>
                <Checkbox.Group
                  onChange={setSelectedTimespaces}
                  value={selectedTimespaces}
                  accessibilityLabel="choose timespaces"
                >
                  {timespaces.map((ts) => (
                    <Checkbox key={ts.id} value={ts.id}>
                      {ts.name}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Box>
            </Box>
          </View>
          <BlankSpacer height={50} />
        </Flex>
      </ScreenContainer>

      <Button variant={'solid'} onPress={handleSubmit} isDisabled={!isValid}>
        <Text _dark={{ color: 'neutral.secondary' }}>Save</Text>
      </Button>
    </>
  );
}

const s = StyleSheet.create({
  container: { flexDirection: 'column' },
  titleInput: { margin: 10, fontSize: 16 },

  notesInput: {
    margin: 10,
  },
  subtasksTouchable: {},
  subtasksInput: {
    margin: 10,
    borderWidth: 0,
  },
  objectiveSection: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  objectiveContainer: {
    margin: 10,
  },
  importanceContainer: {
    alignSelf: 'center',
  },
});
