import timespacesSlice from '@/Store/firestore/timespacesSlice';
import { useAppDispatch } from '@/Store/store';
import type { ITimespace } from '@/types/timespace.type';
import fb, { getCollection } from '@/utils/firebase';
import { useEffect } from 'react';

export default function useTimespaceSubscription(): void {
  const dispatch = useAppDispatch();
  const ownerId = fb.getUser()?.uid;

  useEffect(() => {
    if (!ownerId) {
      fb.auth.signOut();
      return;
    }

    const timespacesSubscriber = getCollection('timespaces')
      .where('ownerId', '==', ownerId)
      .onSnapshot((snapshot) => {
        const timespaces: ITimespace[] = [];
        snapshot?.forEach((doc) => {
          timespaces.push({
            ...(doc.data() as ITimespace),
            id: doc.id,
          });
        });
        dispatch(timespacesSlice.actions.setAllTimespaces(timespaces));
      });

    return () => timespacesSubscriber();
  }, [dispatch, ownerId]);
}
