import { isDevMode } from '@/utils/env';
import * as Sentry from 'sentry-expo';
import { CaptureConsole } from '@sentry/integrations';

Sentry.init({
  dsn: 'https://c6110178e466448ba0d201c32c4e2868@o1261580.ingest.sentry.io/6439471',
  enableInExpoDevelopment: true,
  debug: isDevMode, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
  tracesSampleRate: 1,
  integrations: [
    new CaptureConsole({
      levels: ['error'],
    }),
  ],
});

// // Access any @sentry/react-native exports via:
// Sentry.Native.*

// // Access any @sentry/browser exports via:
// Sentry.Browser.*
