import { isDevMode, isWeb } from '@/utils/env';
import { noop } from 'lodash';
import { connectToDevTools } from 'react-devtools-core';

// Connect devtools
if (__DEV__ && !isWeb) {
  connectToDevTools({
    host: 'localhost',
    port: 8097,
  });
}

if (!isDevMode) {
  console.debug = noop;
  console.log = noop;
}
