import AppHeader from '@/components/AppHeader';
import AppIcon from '@/components/icons/AppIcon';
import ScreenContainer from '@/components/ScreenContainer';
import TaskListItem from '@/Containers/Tasks/components/TaskListItem';
import usePrioritizedTasks, {
  useTasksGroups,
} from '@/hooks/usePrioritizedTasks';
import { useAppSelector } from '@/Store/store';
import { TasksScreenProps } from '@/types/navigation.type';
import { MaterialIcons, Octicons } from '@expo/vector-icons';
import { useBackHandler } from '@react-native-community/hooks';
import { Box, Heading, IconButton, Input, Row, Switch } from 'native-base';
import React, { useCallback, useMemo, useState } from 'react';
import {
  Alert,
  BackHandler,
  SectionList,
  StyleSheet,
  View,
} from 'react-native';
// import TaskList from './TaskList';
import { FAB } from 'react-native-paper';

const TasksTab = ({ navigation }: TasksScreenProps) => {
  const { completedTasks, delayedTasks, activeTasks, todayTasks } =
    useTasksGroups();

  const selectedTaskId = useAppSelector(
    (state) => state.status.currentTask?.id,
  );

  // TODO: Make header

  // console.debug('Tasklist render');
  const [showToday, setShowToday] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [searchText, setSearchText] = useState('');
  const toggleSearch = () => {
    if (!showSearch) return setShowSearch(true);
    setSearchText('');
    setShowSearch(false);
  };

  const renderItem = useCallback(
    ({ item: task }) => (
      <TaskListItem id={task.id} inWork={task.id === selectedTaskId} />
    ),
    [selectedTaskId],
  );

  const data = useMemo(
    () =>
      showToday
        ? [
            {
              title: 'Tasks',
              data: !searchText
                ? todayTasks
                : todayTasks.filter((t) => t.title.includes(searchText)),
            },
          ]
        : [
            {
              title: 'Tasks',
              data: !searchText
                ? activeTasks
                : activeTasks.filter((t) => t.title.includes(searchText)),
            },
            // {
            //   title: 'Todos',
            //   data: todos,
            // },
            // {
            //   title: 'Objectives',
            //   data: objectives,
            // },
            {
              title: 'Delayed',
              data: !searchText
                ? delayedTasks
                : delayedTasks.filter((t) => t.title.includes(searchText)),
            },
            {
              title: 'Completed',
              data: !searchText
                ? completedTasks
                : completedTasks.filter((t) => t.title.includes(searchText)),
            },
          ],
    [
      activeTasks,
      completedTasks,
      delayedTasks,
      searchText,
      showToday,
      todayTasks,
    ],
  );

  /* Close modal instead of exiting app when user uses back swipe gesture  */
  useBackHandler(() => {
    if (navigation.canGoBack()) {
      navigation.goBack();
      return true;
    }
    if (showSearch) {
      toggleSearch();
      return true;
    }

    Alert.alert('Hold on!', 'Are you sure you want to exit?', [
      {
        text: 'Cancel',
        onPress: () => null,
        style: 'cancel',
      },
      { text: 'YES', onPress: () => BackHandler.exitApp() },
    ]);
    return true;
    // let the default thing happen
    // return false
  });

  return (
    <>
      <ScreenContainer noScroll>
        <View style={layout.container}>
          <AppHeader title="Tasks">
            <Row>
              <Switch
                value={showToday}
                onToggle={() => setShowToday((val) => !val)}
              />
              <IconButton
                onPress={() => navigation.navigate('AllProjects')}
                icon={<Octicons name="project" size={24} color="white" />}
              />
              <IconButton
                icon={
                  <MaterialIcons
                    name="workspaces-filled"
                    size={24}
                    color="white"
                  />
                }
                onPress={() => navigation.navigate('AllTimespaces')}
              />
              <IconButton
                icon={<AppIcon name="text-search" />}
                onPress={toggleSearch}
                isPressed={showSearch}
              />
            </Row>
          </AppHeader>

          {showSearch && (
            <Input mx="1" my="2" autoFocus onChangeText={setSearchText} />
          )}

          <SectionList
            stickySectionHeadersEnabled={false}
            contentContainerStyle={{ paddingHorizontal: 6 }}
            sections={data}
            keyExtractor={(item) => item.id}
            renderItem={renderItem}
            ListEmptyComponent={() => (
              <Box>
                <Heading>It is all clear!</Heading>
              </Box>
            )}
            renderSectionHeader={({ section: { title, data: sectionData } }) =>
              sectionData.length === 0 ? null : (
                <Heading fontSize="xl" pt="4" px="4">
                  {title}
                </Heading>
              )
            }
          />
        </View>
      </ScreenContainer>

      <FAB
        style={layout.fab}
        icon="plus"
        onPress={() => navigation.navigate('CreateTask')}
        visible={true}
      />
      {/* <NavPad /> */}
    </>
  );
};

const layout = StyleSheet.create({
  container: {
    flex: 1,
    // paddingHorizontal: 8,
  },
  fab: {
    position: 'absolute',
    margin: 30,
    right: 0,
    bottom: 0,
  },
  headerTitle: { fontSize: 36, fontWeight: 'bold' },
  header: {},
});

export default TasksTab;
