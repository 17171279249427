import InfoContainer from '@/components/InfoContainer';
import ScreenContainer from '@/components/ScreenContainer';
import timespacesSlice, {
  removeTimespace,
  timespacesSelectors,
} from '@/Store/firestore/timespacesSlice';
import { useAppDispatch, useAppSelector } from '@/Store/store';
import { TimespaceScreenProps } from '@/types/navigation.type';
import { Button } from 'native-base';
import React, { useLayoutEffect } from 'react';
import TimespaceEditor from './components/TimespaceEditor';

export default function TimespaceScreen({
  navigation,
  route,
}: TimespaceScreenProps) {
  const timespace = useAppSelector((state) =>
    timespacesSelectors.selectById(state, route.params.id),
  );
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (!timespace) return;
    navigation.setOptions({
      headerRight: () => (
        <Button
          onPress={() => {
            navigation.goBack();
            dispatch(removeTimespace(timespace.id));
          }}
        >
          Delete
        </Button>
      ),
    });
  }, [dispatch, navigation, timespace]);

  if (!timespace) return <InfoContainer title="No such timespace" />;
  // if (timespace.id === 'default') return null;

  return (
    <ScreenContainer>
      <TimespaceEditor timespace={timespace} />
    </ScreenContainer>
  );

  // return <Box />;
}
