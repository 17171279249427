import ScreenContainer from '@/components/ScreenContainer';
import { COLORS } from '@/constants/colors';
import useOpenTask from '@/hooks/useOpenTask';
import youtrackApi, {
  useGetCustomFieldsQuery,
  useGetMeQuery,
  useGetMyIssuesQuery,
} from '@/services/youtrack/youtrackApi';
import integrationsSlice, {
  IntegrationsState,
  removeYouTrackIntegration,
  setCustomFields,
  setYouTrackToken,
  setYouTrackUrl,
} from '@/Store/firestore/integrationsSlice';
import { timespacesSelectors } from '@/Store/firestore/timespacesSlice';
import { dispatch, useAppSelector } from '@/Store/store';
import { getEnv, isDevMode } from '@/utils/env';
import {
  Box,
  Button,
  CheckIcon,
  Input,
  Link,
  Pressable,
  Row,
  Select,
  Spinner,
  Text,
  VStack,
} from 'native-base';
import React, { useMemo, useState } from 'react';
import { Alert } from 'react-native';
import { string } from 'yup';

type SelectorMap = Record<
  keyof Required<Required<IntegrationsState['youTrack']>['customFields']>,
  {
    label: string;
    get: string;
    set: React.Dispatch<React.SetStateAction<string>>;
  }
>;

const urlValidation = string().url();

export default function YouTrackIntegrationScreen({
  navigation,
  route,
}): JSX.Element {
  const youTrack = useAppSelector((state) => state.integrations.youTrack);
  const isValid = youTrack.token && youTrack.serviceUrl;
  const openTask = useOpenTask();
  const timespaces = useAppSelector(timespacesSelectors.selectAll);

  console.debug('youTrack', youTrack);

  // useHeaderIcon();

  const [token, setToken] = useState<string>(
    isDevMode ? getEnv('DEV_YOUTRACK_TOKEN') ?? '' : '',
  );
  const [serviceUrl, setServiceUrl] = useState<string>(
    youTrack.serviceUrl ?? '',
  );

  const [dueDateField, setDueDateField] = useState(
    youTrack.customFields?.dueDate,
  );
  const [durationField, setDurationField] = useState(
    youTrack.customFields?.duration,
  );
  const [elapsedTimeField, setElapsedTimeField] = useState(
    youTrack.customFields?.elapsedTime,
  );

  const [selectedTimespace, setSelectedTimespace] = useState<
    string | undefined
  >(youTrack.timespace);

  const getMeQuery = useGetMeQuery(undefined, {
    skip: !isValid,
  });

  const { data: issuesData, isLoading: issuesLoading } = useGetMyIssuesQuery(
    undefined,
    {
      skip: !isValid,
    },
  );

  // console.debug('issues', issuesData);

  const customFieldsQuery = useGetCustomFieldsQuery(undefined, {
    skip: !isValid,
  });

  const isSameFields =
    youTrack.customFields.dueDate === dueDateField &&
    youTrack.customFields.duration === durationField &&
    youTrack.customFields.elapsedTime === elapsedTimeField;

  const selectors = useMemo(
    () =>
      Object.entries({
        dueDate: {
          label: 'Due date',
          get: dueDateField,
          set: setDueDateField,
        },
        duration: {
          label: 'Duration',
          get: durationField,
          set: setDurationField,
        },
        elapsedTime: {
          label: 'Elapsed time',
          get: elapsedTimeField,
          set: setElapsedTimeField,
        },
      } as Partial<SelectorMap>),
    [dueDateField, durationField, elapsedTimeField],
  );

  // console.debug('adminRequest', data, error);
  // console.debug('issuesRequest', issuesData, issuesError);
  // console.debug('customFields', customFieldsQuery.data);

  // console.debug('youTrack', youTrack);

  if (isValid)
    return (
      <ScreenContainer>
        {(getMeQuery.isLoading || issuesLoading) && <Spinner />}
        <VStack space="1">
          <Text>Id: {getMeQuery.data?.id}</Text>
          <Text>Name: {getMeQuery.data?.name}</Text>
          {/* <Text>Email: {getMeQuery.data?.email}</Text> */}
          <Text>Login: {getMeQuery.data?.login}</Text>
        </VStack>

        <VStack space="2" my="4">
          <Box>
            <VStack space="1">
              {selectors.map(([key, { label, get, set }]) => (
                <Box key={key}>
                  <Text>{label}</Text>
                  <Select
                    selectedValue={get}
                    accessibilityLabel={label}
                    placeholder={label}
                    mt={1}
                    onValueChange={set}
                    _selectedItem={{
                      endIcon: <CheckIcon size="5" />,
                    }}
                  >
                    {customFieldsQuery.data?.map((f) => {
                      if (!f.id || !f.name) return;
                      return (
                        <Select.Item
                          key={`${key}-${f.id}`}
                          label={f.name}
                          value={f.name}
                        />
                      );
                    })}
                  </Select>
                </Box>
              ))}
            </VStack>

            <Button
              mt="1"
              isDisabled={
                !dueDateField ||
                !durationField ||
                !elapsedTimeField ||
                isSameFields
              }
              onPress={() =>
                dispatch(
                  setCustomFields({
                    dueDate: dueDateField,
                    duration: durationField,
                    elapsedTime: elapsedTimeField,
                  }),
                )
              }
            >
              Set fields
            </Button>
          </Box>

          <Box>
            <Select
              selectedValue={selectedTimespace}
              accessibilityLabel={'Select timespace'}
              placeholder={'Select timespace'}
              mt={1}
              onValueChange={setSelectedTimespace}
              _selectedItem={{
                endIcon: <CheckIcon size="5" />,
              }}
            >
              {timespaces.map((ts) => {
                if (!ts.id || !ts.name) return;
                return (
                  <Select.Item
                    borderLeftWidth={1}
                    borderLeftColor={COLORS[ts.color]}
                    key={`${ts.id}`}
                    label={ts.name}
                    value={ts.id}
                  />
                );
              })}
            </Select>
            <Button
              mt="1"
              isDisabled={
                !selectedTimespace || selectedTimespace === youTrack.timespace
              }
              onPress={() => {
                if (!selectedTimespace) return;
                dispatch(
                  integrationsSlice.actions.setYouTrackTimespace(
                    selectedTimespace,
                  ),
                );
              }}
            >
              Set timespace
            </Button>
          </Box>
        </VStack>

        <Button
          colorScheme="danger"
          onPress={() =>
            Alert.alert(
              'Reset token',
              'Are you sure you want to reset your token?',
              [
                {
                  text: 'Reset',
                  onPress: () => {
                    dispatch(removeYouTrackIntegration());
                    dispatch(youtrackApi.util.resetApiState());
                  },
                },
                {
                  text: 'Cancel',
                },
              ],
            )
          }
        >
          Reset token
        </Button>
        <Text mt="2">Received tasks:</Text>
        <VStack space="2">
          {issuesData?.map((issue) => (
            <Pressable key={issue.id} onPress={() => openTask(issue.id!)}>
              <Row space="3">
                <Text>{issue.id}</Text>
                <Text>{issue.project?.name}</Text>
                <Text>{issue.summary}</Text>
              </Row>
            </Pressable>
          ))}
        </VStack>
      </ScreenContainer>
    );

  // If not configured
  return (
    <ScreenContainer>
      <VStack space="4">
        <Text>Enter your YouTrack token</Text>
        <Link href="https://www.jetbrains.com/help/youtrack/standalone/Manage-Permanent-Token.html#obtain-permanent-token">
          Where to get token?
        </Link>
        <Row space="2">
          <Input
            flex="1"
            placeholder="Token"
            value={token}
            onChangeText={setToken}
            autoCapitalize={'none'}
          />
          <Button
            isDisabled={!token || token.length < 5 || !token.startsWith('perm')}
            onPress={() => dispatch(setYouTrackToken(token))}
          >
            Save
          </Button>
        </Row>
        <Row space="2">
          <Input
            flex="1"
            placeholder="Service url"
            value={serviceUrl}
            onChangeText={setServiceUrl}
            autoCapitalize={'none'}
            autoCorrect={false}
            autoCompleteType="off"
            keyboardType="url"
          />
          <Button
            isDisabled={!serviceUrl || !urlValidation.isValidSync(serviceUrl)}
            onPress={() => dispatch(setYouTrackUrl(serviceUrl))}
          >
            Save
          </Button>
        </Row>
      </VStack>
    </ScreenContainer>
  );
}
