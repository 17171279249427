// import { logout } from '../../store/Auth';
import { isRejectedWithValue } from '@reduxjs/toolkit';
import { Middleware, MiddlewareAPI } from 'redux';
import { Toast } from 'native-base';

export const rtkQueryErrorLogger: Middleware =
  (storeAPI: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these use matchers!
    if (isRejectedWithValue(action)) {
      console.error(JSON.stringify(action, undefined, 1));
      // If session id is incorrect, logout user
      // if (error.textCode === 'WRONG_SESSION') {
      //   // storeAPI.dispatch(logout());
      //   Toast.show({
      //     title: 'Auth error',
      //     description: 'Please log in again.',
      //   });
      // } else {
      //   // In case of any other error, toast a message with error code.
      // }
      const title = action?.error?.message ?? 'Error';
      const message = action?.payload?.error;
      Toast.show({
        title,
        description: message,
      });
    }

    return next(action);
  };
