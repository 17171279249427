import { DAY_IN_MS } from '@/constants/time';
import { useAppTheme } from '@/Context/AppThemeContext';
import useOpenTask from '@/hooks/useOpenTask';
import useTask from '@/hooks/useTask';
import { useAppDispatch, useAppSelector } from '@/Store/store';
import { changeTaskStatus } from '@/Store/firestore/tasksSlice';
import { ITask } from '@/types/task.type';
import { isActive, isDelayed, isNumber, isObjective } from '@/utils';
import dayjs from 'dayjs';
import { Box, Button, Flex, Icon, IconButton, Row, Text } from 'native-base';
import React, { useCallback, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { List } from 'react-native-paper';
import AppIcon from '@/components/icons/AppIcon';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useAppStatus, useStatusStore } from '@/Context/AppStatusContext';

export const getCheckboxIconName = (item: ITask) => {
  const [active, delayed, objective] = [
    isActive(item),
    isDelayed(item),
    isObjective(item),
  ];
  if (active && !delayed) {
    return objective
      ? 'checkbox-blank-circle-outline'
      : 'checkbox-blank-outline';
  } else if (active && delayed) {
    return objective ? 'circle-off-outline' : 'checkbox-blank-off-outline';
  } else {
    return objective
      ? 'checkbox-marked-circle-outline'
      : 'checkbox-marked-outline';
  }
};

function TaskListItem({ id, inWork = false }: { id: string; inWork: boolean }) {
  const item = useTask(id);
  const dispatch = useAppDispatch();
  const openTask = useOpenTask();
  const { Common } = useAppTheme();
  const [isHiding, setIsHiding] = useState(false);

  // const _inWork = useStatusStore((state) => state.currentTask?.id) === id;

  const _inWork = useAppSelector(
    (state) => state.status.currentTask?.id === id,
  );

  console.debug('render', item?.title);

  const onCheckboxPress = useCallback(
    (task: ITask) => {
      if (!task) return;
      if (isDelayed(task)) return;
      if (task.origin === 'youtrack') return; // TODO: Check
      const status: ITask['status'] = isActive(task) ? 'completed' : 'active';
      setIsHiding(true);
      dispatch(changeTaskStatus({ id: task.id, status })).catch(() =>
        setIsHiding(false),
      );
    },
    [dispatch],
  );

  if (!item) return <Text>Error</Text>;

  let timeStatus: 'normal' | 'warning' | 'overdue' = 'normal';

  let textColor = Common.t_primary;
  if (isNumber(item.dueDate)) {
    if (item.dueDate <= Date.now()) {
      textColor = Common.t_error;
      timeStatus = 'warning';
    } else if (item.dueDate - DAY_IN_MS * 2 <= Date.now()) {
      textColor = Common.t_warning;
      timeStatus = 'overdue';
    }

    // textColor = item.dueDate < Date.now() ? Common.t_error :
  }

  // Unmount component immediately when changing status;
  if (isHiding) return null;

  return (
    <Box
      padding={2}
      marginBottom={2}
      borderLeftWidth={1}
      borderLeftColor="amber.400"
      borderRadius={6}
      minHeight={12}
      flex="1"
    >
      <TouchableOpacity onPress={() => openTask(item.id)}>
        <Row flex={1} justifyContent="space-between">
          <Row flex={1}>
            <Icon
              as={MaterialCommunityIcons}
              fontWeight="light"
              name={getCheckboxIconName(item)}
              onPress={() => onCheckboxPress(item)}
            />

            <Flex flex={1} pl={4}>
              <Text flex={1}>{item.title}</Text>
              {isNumber(item.dueDate) && (
                <Text style={textColor}>{dayjs(item.dueDate).fromNow()}</Text>
              )}
            </Flex>
          </Row>

          {/* {timeStatus !== 'normal' && (
            <Box>
              <IconButton
                onPress={() => {}}
                icon={<AppIcon name="forward" />}
              />
            </Box>
          )} */}

          <Box>
            {_inWork && (
              <Box>
                <AppIcon name="clock-outline" />
              </Box>
            )}
            {item.origin === 'youtrack' && <Text>YT</Text>}
          </Box>
        </Row>
      </TouchableOpacity>
    </Box>
  );

  return (
    <List.Item
      key={item.id}
      title={item.title}
      titleNumberOfLines={2}
      onPress={() => openTask(item.id)}
      description={() => (
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          {/* <View><Text>{item.priority}</Text></View> */}
          {isNumber(item.dueDate) && (
            <View>
              <Text style={textColor}>{dayjs(item.dueDate).fromNow()}</Text>
            </View>
          )}
        </View>
      )}
      left={(props) => (
        <TouchableOpacity onPress={() => onCheckboxPress(item)}>
          <List.Icon {...props} icon={getCheckboxIcon(item)} />
        </TouchableOpacity>
      )}
      right={
        !inWork
          ? undefined
          : () => (
              <Box>
                <AppIcon name="clock-outline" />
              </Box>
            )
      }
    />
  );
}
// export default TaskListItem;
export default React.memo(TaskListItem);
