import { POPULAR_COLOR_NAMES } from '@/constants/colors';
import { useTasks } from '@/hooks/useTask';
import projectsSlice, { NewProject } from '@/Store/firestore/projectsSlice';
import { useAppDispatch } from '@/Store/store';
import { CreateProjectScreenProps } from '@/types/navigation.type';
import { IProject } from '@/types/project.type';
import { getObjectFromHookstate } from '@/utils';
import { getDoc } from '@/utils/firebase';
import { useHookstate } from '@hookstate/core';
import { uniqueId } from 'lodash';
import {
  Box,
  Button,
  Checkbox,
  Column,
  Input,
  Row,
  ScrollView,
  Text,
  useDisclose,
  useToken,
} from 'native-base';
import React, { useMemo, useState } from 'react';
import { Dimensions } from 'react-native';
import { SelectColorModal } from '../../components/ColorsModal';
import ProjectEditor from './components/ProjectEditor';

export const COLOR_WIDTH = Math.floor(
  Dimensions.get('screen').width / POPULAR_COLOR_NAMES.length,
);

const createCleanProject = (): NewProject => ({
  name: '',
  description: '',
  color: 'blue',
  tasks: [],
});

export default function CreateProjectScreen({
  navigation,
}: CreateProjectScreenProps) {
  return <ProjectEditor />;
}
