import { useCallback } from 'react';
import { useNavigation } from '@react-navigation/native';

import AppVibration from '@/services/AppVibration';
import { ITask } from '@/types/task.type';

const useOpenTask = () => {
  const navigation = useNavigation();
  return useCallback(
    (id: ITask['id']) => {
      AppVibration.touch();
      navigation.navigate('Task', { id });
    },
    [navigation],
  );
};

export default useOpenTask;
