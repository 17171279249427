import React, { useCallback, useContext, useState } from 'react';

import { ITask } from '@/types/task.type';
import taskSlice from '@/Store/firestore/tasksSlice';
import useTask from '@/hooks/useTask';

import create from 'zustand';

export type AppStatusContextValue = {
  currentTask: ITask | null;
  timerStatus: TimerStatus;
  timerStartDate: Date | null;
  setCurrentTask: (task: ITask) => void;
  setTimerStatus: (status: TimerStatus) => void;
  // setTimerStartDate: React.Dispatch<React.SetStateAction<Date | null>>
};

export type TimerStatus = 'inactive' | 'work' | 'break';

export const useStatusStore = create<AppStatusContextValue>((set) => ({
  currentTask: null,
  timerStatus: 'inactive',
  timerStartDate: null,
  setCurrentTask: (task: ITask) => set({ currentTask: task }),
  setTimerStatus: (status: TimerStatus) =>
    set((state) => {
      const startDate = status === 'work' ? new Date() : state.timerStartDate;
      return { timerStatus: status, timerStartDate: startDate };
    }),
}));

export const AppStatusContext =
  React.createContext<AppStatusContextValue | null>(null);

export const AppStatusProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [_currentTask, setCurrentTask] = useState<ITask | null>(null);
  const currentTask = useTask(_currentTask?.id ?? '');
  const [timerStatus, _setTimerStatus] = useState<TimerStatus>('inactive');
  const [timerStartDate, _setTimerStartDate] = useState<Date | null>(null);
  const setTimerStatus = useCallback((status: TimerStatus) => {
    if (status === 'work') _setTimerStartDate(new Date());
    _setTimerStatus(status);
  }, []);
  const value = {
    currentTask,
    setCurrentTask,
    timerStatus,
    setTimerStatus,
    timerStartDate,
  };
  // console.log(value)
  return (
    <AppStatusContext.Provider value={value}>
      {children}
    </AppStatusContext.Provider>
  );
};

export const useAppStatus = () => {
  const appStatus = useContext(AppStatusContext);
  return appStatus as AppStatusContextValue;
};
