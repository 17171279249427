import React, { useContext } from 'react'

import { useTheme, usePaperTheme } from '@/Theme'

export type ThemeContextValue = ReturnType<typeof useTheme> & {
  paperTheme: ReturnType<typeof usePaperTheme>
}

export const AppThemeContext = React.createContext<ThemeContextValue | null>(
  null,
)

export const AppThemeProvider: React.FC<React.ReactNode> = ({ children }) => {
  const appTheme = useTheme()
  const paperTheme = usePaperTheme()
  const value = { ...appTheme, paperTheme }
  return (
    <AppThemeContext.Provider value={value}>
      {children}
    </AppThemeContext.Provider>
  )
}

export const useAppTheme = () => {
  const theme = useContext(AppThemeContext)
  return theme as ThemeContextValue
}
