import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';

import { navigate } from '@/Navigators/navigation';
import Constants from 'expo-constants';

console.info('Load web firebase modules');

// Web app's configuration
const firebaseConfig = Constants.manifest?.web?.config?.firebase;

if (!firebaseConfig) throw new Error('Missing firebase config');

export const firebaseApp = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const database = firebase.database();
export const analytics = firebase.analytics();

export const signInWithGoogle = async () => {
  const googleAuthProvider = new firebase.auth.GoogleAuthProvider();

  return firebase
    .auth()
    .signInWithPopup(googleAuthProvider)
    .then((result) => {
      /** @type {firebase.auth.OAuthCredential} */
      const credential = result.credential?.toJSON();

      // This gives you a Google Access Token. You can use it to access the Google API.
      const token = credential?.accessToken;
      // The signed-in user info.
      const user = result.user;
      console.debug('googleAuth', user, credential);
      // ...
    })
    .catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      const credential = error.credential;
      // ...
    });
};

export const init = () => {
  auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

  firebase
    .firestore()
    .enablePersistence()
    .catch((error) => {
      console.error(error);
      if (error.code === 'failed-precondition') {
        navigate('Info' as any, {
          title: 'Only one app instance can be opened at a time.',
          message: 'Please close all extra tabs.',
        });
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (error.code === 'unimplemented') {
        // navigate('Info' as any, { title: 'Error' });
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });
};
