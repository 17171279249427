import { BlankSpacer } from '@/components';
import AppHeader from '@/components/AppHeader';
import ScreenContainer from '@/components/ScreenContainer';
import { useAppTheme } from '@/Context/AppThemeContext';
import useInterval from '@/hooks/useInterval';
import useOpenTask from '@/hooks/useOpenTask';
import usePrioritizedTasks from '@/hooks/usePrioritizedTasks';
import AppVibration from '@/services/AppVibration';
import { setCurrentTask, setTimerStatus } from '@/Store/firestore/statusSlice';
import { addTime, syncedTasksSelectors } from '@/Store/firestore/tasksSlice';
import { useAppDispatch, useAppSelector } from '@/Store/store';
import { toggleSubtask } from '@/Store/Tasks';
import { TimerScreenProps } from '@/types/navigation.type';
import { PrioritizedTask } from '@/types/task.type';
import { isWeb } from '@/utils/env';
import { useFocusEffect } from '@react-navigation/native';
import dayjs from 'dayjs';
import { Audio } from 'expo-av';
import { scheduleNotificationAsync } from 'expo-notifications';
import {
  Box,
  Button,
  FlatList,
  Row,
  Text,
  // useDisclose,
  useTheme,
} from 'native-base';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Animated,
  Pressable,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  View,
} from 'react-native';
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer';
import { List } from 'react-native-paper';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import SubtasksList from '../Tasks/components/SubtasksList';

const timerSound = require('assets/sounds/sharp.mp3');

const formatNumber = (number: number) => `0${number}`.slice(-2);

const clockSize = 200; // points
// const workTime = 60 * 45;
// const breakTime = 60 * 15;

function TimerContainer({ navigation }: TimerScreenProps) {
  const [isLoaded, setIsLoaded] = useState(false);

  const { prioritizedTasks } = usePrioritizedTasks();
  // const [isPlaying, setIsPlaying] = useState(false)
  // const [isPaused, setIsPaused] = useState(false)
  const { Colors } = useAppTheme();
  const { colors } = useTheme();

  const { currentTask, timerStatus, timerStartDate, timerEndDate } =
    useAppSelector((state) => state.status);

  const [subtasksExpanded, setSubtasksExpanded] = useState(true);
  // const currentTask = useSelectTask(currentTask?.id)
  const dispatch = useAppDispatch();
  // const workTime = useSelector(state => state.settings.workTime);
  // const breakTime = useSelector(state => state.settings.breakTime);

  // Unmount calendar on unfocus to avoid expensive rerenders
  const [isFocused, setIsFocused] = useState<boolean>(navigation.isFocused);
  useFocusEffect(
    useCallback(() => {
      setIsFocused(true);
      return () => setIsFocused(false);
    }, []),
  );

  useEffect(() => setIsLoaded(true), []);

  const openTask = useOpenTask();

  const duration = useMemo(() => {
    // return 5;
    if (!timerEndDate || !timerStartDate) return 0;
    const dur = Math.floor((timerEndDate - Date.now()) / 1000);
    if (dur < 1) return 0;
    return dur;
    // return timerStatus === 'break' ?  breakTime : workTime;
  }, [timerEndDate, timerStartDate]);

  const updateTaskElapsedTime = useCallback(() => {
    if (currentTask && timerStatus === 'work' && timerStartDate) {
      const end = Math.min(
        Date.now(),
        timerEndDate ?? Number.POSITIVE_INFINITY,
      );
      const elapsed = Math.floor(-dayjs(timerStartDate).diff(end, 'minutes'));
      dispatch(addTime({ id: currentTask.id, minutes: elapsed }));
    }
  }, [currentTask, timerStatus, timerStartDate, timerEndDate, dispatch]);

  const toggleMode = () => {
    updateTaskElapsedTime();
    const status = timerStatus === 'work' ? 'break' : 'work';
    setTimerStatus(status);
    AppVibration.short();
  };

  // const [soundState, setSound] = React.useState<Audio.Sound | null>(null);
  const soundRef = useRef<Audio.Sound | null>(null);

  async function playSound() {
    console.log('Loading Sound');
    const { sound } = await Audio.Sound.createAsync(timerSound);
    soundRef.current = sound;

    console.log('Playing Sound');
    await sound.playAsync();
  }

  React.useEffect(() => {
    return soundRef.current
      ? () => {
          console.log('Unloading Sound');
          soundRef.current?.unloadAsync();
        }
      : undefined;
  }, []);

  const onTimerEnd = () => {
    if (timerStatus === 'inactive') return;
    const title =
      timerStatus === 'work' ? 'Take a break ☕️' : 'Start working 👨‍💻';
    if (!isWeb) {
      scheduleNotificationAsync({
        content: {
          title,
        },
        trigger: {
          seconds: 1,
        },
      });
    }
    if (isWeb) {
      if (Notification?.permission !== 'granted') return;
      navigator.serviceWorker.ready.then((registration) => {
        console.debug('service worker ready');
        registration.showNotification(title, {
          vibrate: [100, 50, 100],
          requireInteraction: true,
        });
      });
    }
    playSound();
    toggleMode();
  };

  const togglePlay = () => {
    updateTaskElapsedTime();
    const status = timerStatus === 'inactive' ? 'work' : 'inactive';
    setTimerStatus(status);
    AppVibration.long();
  };
  const stop = () => {
    updateTaskElapsedTime();
    if (timerStatus !== 'inactive') {
      setTimerStatus('inactive');
    }
    AppVibration.long();
  };

  // TODO fix not changing subtask status
  const onToggleSubtask = useCallback(
    (index: number) => {
      if (!currentTask) return;
      dispatch(toggleSubtask({ id: currentTask.id, index: index }));
    },
    [currentTask, dispatch],
  );

  const isPlaying = timerStatus !== 'inactive';

  const filteredTasks = useMemo(
    () =>
      prioritizedTasks
        .filter((item) => item.status !== 'completed')
        .filter((item) => (currentTask ? item.id !== currentTask.id : true)),
    [currentTask, prioritizedTasks],
  );

  const [timerKey, setTimerKey] = useState(0);

  // const taskId = currentTask?.id;

  // eslint-disable-next-line no-undef

  const checkTimer = () => {
    if (timerStatus === 'inactive') return;
    // if (timerStartDate && timerStartDate + duration * 1000 < Date.now()) {
    //   onTimerEnd();
    // }
    if (timerEndDate && timerEndDate < Date.now()) {
      onTimerEnd();
    }
  };

  // const { isOpen, onClose, onOpen } = useDisclose();

  useInterval(checkTimer, 1000);

  useEffect(() => {
    setTimerKey((v) => v + 1);
  }, [timerStatus, timerStartDate, timerEndDate]);

  useEffect(() => {
    if (isWeb) {
      Notification?.requestPermission();
    }
  }, []);

  return (
    <ScreenContainer noScroll>
      <AppHeader title="Timer" />
      <ScrollView>
        <Button
          mode="contained"
          style={s.currentTaskButton}
          onPress={() => setCurrentTask(null)}
        >
          {currentTask ? currentTask.title : 'No active tasks'}
        </Button>
        <Pressable
          onLongPress={stop}
          onPress={toggleMode}
          style={s.timerPressable}
        >
          <CountdownCircleTimer
            key={timerKey}
            isPlaying={isPlaying}
            duration={duration}
            size={clockSize}
            colors={[
              [colors.primary[500], 0.4],
              ['#F7B801', 0.4],
              ['#A30000', 0.2],
            ]}
            onComplete={() => {
              // onTimerEnd();
              // toggleMode();
              return [false, 200];
            }}
          >
            {({ remainingTime, animatedColor }) => {
              const minutes = formatNumber(
                Math.floor((remainingTime % 3600) / 60),
              );
              const seconds = formatNumber(remainingTime % 60);
              const text = `${minutes}:${seconds}`;
              return isPlaying ? (
                <View>
                  <Animated.Text style={{ color: animatedColor, fontSize: 40 }}>
                    {text}
                  </Animated.Text>
                  <Text style={{ alignSelf: 'center' }}>{timerStatus}</Text>
                </View>
              ) : (
                <Icon name="play" size={clockSize / 3} color="white" />
              );
            }}
          </CountdownCircleTimer>
        </Pressable>
        <View style={s.controlButtons}>
          <Button mode="contained" onPress={togglePlay}>
            {isPlaying ? 'Stop' : 'Play'}
          </Button>
          <BlankSpacer width={20} />
          <Button mode="contained" onPress={toggleMode}>
            Toggle Mode
          </Button>
        </View>
        {/* <Text style={s.statusIndicator}>{isBreak ? 'BREAK' : 'WORK'}</Text> */}
        {/* {!!currentTask && !!currentTask.subtasks && (
          <SubtasksList
            taskId={currentTask.id}
            subtasks={currentTask.subtasks}
          />
        )} */}
        {!!currentTask && currentTask.subtasks && (
          <List.Accordion
            title="Subtasks"
            expanded={subtasksExpanded}
            onPress={() => setSubtasksExpanded(!subtasksExpanded)}
          >
            <SubtasksList
              taskId={currentTask.id}
              subtasks={currentTask.subtasks}
            />
            {/* {currentTask.subtasks?.map((item, index) => {
              return (
                <TodoItem
                  key={index}
                  title={item.title}
                  titleStyle={{
                    textDecorationLine:
                      item.status === 'completed' ? 'line-through' : null,
                  }}
                  onPress={() => onToggleSubtask(index)}
                />
              );
            })} */}
          </List.Accordion>
        )}

        <FlatList
          data={filteredTasks}
          renderItem={({ item }) => (
            <TimerTask key={item.id} id={item.id} onPress={setCurrentTask} />
          )}
        />
      </ScrollView>
    </ScreenContainer>
  );
}

const TimerTask = React.memo(
  ({
    onPress,
    id,
  }: {
    onPress: (task: PrioritizedTask) => void;
    id: string;
  }) => {
    const task = useAppSelector((state) =>
      syncedTasksSelectors.selectById(state, id),
    );
    if (!task) return null;
    return (
      <TouchableOpacity onPress={() => onPress(task)}>
        <Box p="1" mx="4">
          <Row>
            <Text>{task.title}</Text>
          </Row>
          <Row space={2}>
            <Text>Estimated: {task.duration}</Text>
            <Text>Elapsed: {task.elapsedTime}</Text>
          </Row>
        </Box>
      </TouchableOpacity>
    );
  },
);

const s = StyleSheet.create({
  container: {
    flex: 1,
    // backgroundColor: 'white',
    // justifyContent: 'center',
    // alignItems: 'center',
    // paddingTop: 20,
    // backgroundColor: '#ecf0f1',
    // padding: 8,
  },
  currentTaskButton: {
    marginTop: 20,
    marginBottom: 20,
    marginHorizontal: 20,
  },
  timerPressable: {
    // backgroundColor: 'red',
    alignSelf: 'center',
  },
  statusIndicator: {
    margin: 10,
    alignSelf: 'center',
  },
  controlButtons: {
    margin: 10,
    flexDirection: 'row',
    justifyContent: 'center',
  },
});

export default TimerContainer;
