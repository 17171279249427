import { IStory } from './../../types/story.type';
import { initialTasks } from './initial';
import { useAppSelector } from '../store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ITask, TaskId, TaskList } from '@/types/task.type';
import { RootState, useAppDispatch } from '@/Store/store';

export const slice = createSlice({
  name: 'stories',
  initialState: [] as IStory[],
  reducers: {
    addStory: (state, action: PayloadAction<IStory>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.push(action.payload);
    },
    removeStory: (state, action: PayloadAction<TaskId>) => {
      return state.filter((story) => story.id !== action.payload);
    },
    modifyStory: (
      state,
      action: PayloadAction<{ id: TaskId; fields: Partial<ITask> }>,
    ) => {
      return state.map((story) => {
        if (story.id === action.payload.id) {
          return { ...story, ...action.payload.fields };
        }
        return story;
      });
    },
  },
});

export const { addStory, modifyStory, removeStory } = slice.actions;

/* The function below is called a thunk and allows us to perform async logic. It
can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
will call the thunk with the `dispatch` function as the first argument. Async
code can then be executed and other actions can be dispatched */
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementTime(amount))
//   }, 1000)
//}

/* The function below is called a selector and allows us to select a value from
the state. Selectors can also be defined inline where they're used instead of
in the slice file. For example: `useSelector((state) => state.counter.value)` */

export const selectStories = (state: RootState) => state.stories;
export const selectStory = (id: TaskId) => (state: RootState) =>
  state.stories.find((story) => story.id === id);

export const useSelectStories = () => {
  return useAppSelector(selectStories);
};

export const useSelectStory = (id?: IStory['id']) => {
  return useAppSelector((state: RootState) => {
    if (!id) return;
    return state.stories.find((story) => story.id === id);
  });
};

export default slice.reducer;
