import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import { RootState } from '../store';
// import type { IStory, PrioritizedTask as PTask } from '../../types/task.type';

import { IStory } from '@/types/story.type';
import fb from '@/utils/firebase';

export const createStory = ({
  title,
  content,
  tasks = [],
  visibility = 'private',
  tags = [],
}: Omit<IStory, 'id' | 'ownerId' | 'creationTime' | 'updateTime'>): Omit<
  IStory,
  'id'
> => {
  const now = Date.now();
  const ownerId = fb.auth.currentUser?.uid;
  if (!ownerId) throw new Error('Cannot create story for unknown user');
  return {
    ownerId,
    title,
    content,
    creationTime: now,
    updateTime: now,
    tasks,
    tags,
    visibility,
  };
};

export const addStory = createAsyncThunk<Promise<any>, Omit<IStory, 'id'>>(
  'stories/addStory',
  async (story) => {
    const { tasks, ...rest } = story;
    const batch = fb.store.batch();
    const s = fb.store.collection('stories').doc();
    if (tasks.length > 0 && tasks.length < 490) {
      tasks.forEach((t) => batch.delete(fb.store.doc(`tasks/${t.id}`)));
    }
    batch.set(s, { ...rest, id: s.id, tasks });
    return await batch.commit();
  },
);

export const removeStory = createAsyncThunk<Promise<any>, string>(
  'stories/removeStory',
  async (id) => {
    return await fb.store.collection('stories').doc(id).delete();
  },
);

export const modifyStory = createAsyncThunk<Promise<any>, IStory>(
  'stories/modifyStory',
  async (story) => {
    return await fb.store.collection('stories').doc(story.id).update(story);
  },
);

const storiesAdapter = createEntityAdapter<IStory>({
  // Assume IDs are stored in a field other than `book.id`
  selectId: (story) => story.id,
  // Keep the "all IDs" array sorted based on book titles
  sortComparer: (a, b) => b.updateTime - a.updateTime,
});

const storiesSlice = createSlice({
  name: 'syncedStories',
  initialState: storiesAdapter.getInitialState(),
  reducers: {
    setStories: storiesAdapter.setAll,
  },
  extraReducers: (builder) => {
    builder.addCase(removeStory.fulfilled, () => {
      console.debug('Removed story');
    });
  },
});

// Can create a set of memoized selectors based on the location of this entity state
export const syncedStoriesSelectors = storiesAdapter.getSelectors<RootState>(
  (state) => state.syncedStories,
);

// And then use the selectors to retrieve values
// const selectSyncedTasks = tasksSelectors.selectAll(store.getState())

export default storiesSlice;
