import AppIcon from '@/components/icons/AppIcon';
import { TimerStatus } from '@/Context/AppStatusContext';
import { setCurrentTask, setTimerStatus } from '@/Store/firestore/statusSlice';
import { dispatch, useAppSelector } from '@/Store/store';
import { ITask } from '@/types/task.type';
import { Box, IconButton } from 'native-base';
import React from 'react';

export default function TaskActionPanel({ task }: { task: ITask }) {
  const { currentTask, timerStatus } = useAppSelector((state) => state.status);

  const isSelected = currentTask?.id === task.id;
  const isActive = isSelected && timerStatus === 'work';
  // const isPaused = isSelected && timerStatus === 'break';

  // const status: TimerStatus =
  //   !isSelected || timerStatus === 'inactive' ? 'inactive' : timerStatus;

  return (
    <Box>
      <IconButton
        icon={<AppIcon name={isActive ? 'pause' : 'play'} />}
        onPress={() => {
          setCurrentTask(task);
          setTimerStatus(timerStatus !== 'work' ? 'work' : 'break');
        }}
        onLongPress={() => setTimerStatus('inactive')}
      />
    </Box>
  );
}
