import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import { Box } from 'native-base';
import { State, useHookstate } from '@hookstate/core';
import { ITimeblock } from '@/types/timeblock.type';
import { timespacesSelectors } from '@/Store/firestore/timespacesSlice';
import { useAppSelector } from '@/Store/store';
import { useTimespacesById } from '@/hooks/useTask';
import { COLORS, NamedColor } from '@/constants/colors';

type Props = {
  timeblockState: State<ITimeblock>;
  onPress: (tb: State<ITimeblock>) => void;
};

export default function Timeblock({ timeblockState, onPress }: Props) {
  const timeblock = useHookstate(timeblockState);
  // const timespaces = useTimespacesById(timeblockState.timespaces.get());
  const entities = useAppSelector(timespacesSelectors.selectEntities);
  const mainTimespace = useAppSelector(timespacesSelectors.selectMainTimespace);

  return (
    <TouchableOpacity
      key={timeblock.day.get()}
      style={[layout.block, layout.borderBlock]}
      onPress={() => onPress(timeblock)}
    >
      <Box
        flex={1}
        style={
          timeblock.mainTimespace.get()
            ? {
                borderWidth: 1,
                borderRadius: 5,
                borderColor: COLORS[mainTimespace.color],
              }
            : undefined
        }
      >
        {timeblock.timespaces.get().map((tsname) => {
          return (
            <Box
              flex="1"
              key={tsname}
              style={{
                backgroundColor: COLORS[entities[tsname]?.color as NamedColor],
              }}
            />
          );
        })}
      </Box>
    </TouchableOpacity>
  );
}

export const layout = StyleSheet.create({
  container: {
    flex: 1,
  },
  block: {
    flex: 1,
    height: 50,
  },
  borderBlock: {
    borderRightWidth: 1,
    borderTopWidth: 1,
  },
  hourBlock: {
    flex: 1,
    borderRightWidth: 1,
    paddingRight: 20,
  },
});
