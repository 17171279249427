import React, { useEffect, useMemo, useState } from 'react';
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInputSubmitEditingEventData,
  TouchableOpacity,
} from 'react-native';
import { List } from 'react-native-paper';
import {
  Box,
  Center,
  CheckIcon,
  CircleIcon,
  CloseIcon,
  IconButton,
  Input,
  Row,
  Text,
} from 'native-base';
import { Entypo } from '@expo/vector-icons';

import { getObjectFromHookstate, noop } from '@/utils';
import { Subtask } from '@/types/task.type';
import { State, useHookstate } from '@hookstate/core';
import { cloneDeep, isEqual } from 'lodash';
import { useKeyboard } from '@react-native-community/hooks';
import { shallowEqual, useDispatch } from 'react-redux';
import { changeSubtasks } from '@/Store/firestore/tasksSlice';
import useComponentWillUnmount from '@/hooks/useComponentWillUnmount';
import { dispatch } from '@/Store/store';

type SubtaskItemProps = {
  item: State<Subtask>;
  onRemove: () => void;
  mode?: 'view' | 'edit';
};

// TODO:
export const SubtaskItem = ({ item: _item, onRemove }: SubtaskItemProps) => {
  const item = useHookstate(_item);

  const [isEditing, setIsEditing] = useState(false);

  const isActive = item.status.get() === 'active';

  const toggle = () => {
    item.status.set((s) => (s === 'active' ? 'completed' : 'active'));
  };

  // const isKeyboardShown = useKeyboard().keyboardShown;

  // useEffect(() => {
  //   if (!isKeyboardShown) setIsEditing(false);
  // }, [isKeyboardShown]);

  const Icon = isActive ? (
    <Entypo name="dot-single" size={24} color="white" />
  ) : (
    <Entypo name="check" size={24} color="white" />
  );

  return (
    <Row justifyContent={'space-between'}>
      <Row flex={1}>
        <TouchableOpacity
          onPress={toggle}
          // onLongPress={() => setIsEditing(true)}
          style={styles.subtaskContent}
        >
          <IconButton onPress={toggle} icon={Icon} />

          {false ? (
            <Input
              value={item.title.get()}
              onChangeText={item.title.set}
              fontSize="md"
              mx="2"
              multiline
              flex="1"
              autoFocus
              // onBlur={() => setIsEditing(false)}
              rightElement={
                <IconButton onPress={onRemove} icon={<CloseIcon />} />
              }
            />
          ) : (
            <Text
              mx="2"
              fontSize="md"
              flex="1"
              strikeThrough={!isActive}
              numberOfLines={1}
            >
              {item.title.get()}
            </Text>
          )}
        </TouchableOpacity>
      </Row>

      {isEditing && (
        <Center>
          <IconButton onPress={onRemove} icon={<CloseIcon />} />
        </Center>
      )}
    </Row>
  );
};

type OnSubmit =
  | ((e: NativeSyntheticEvent<TextInputSubmitEditingEventData>) => void)
  | undefined;

type SubtasksListProps = {
  taskId: string;
  subtasks: Subtask[];
  // isEditable?: boolean;
  mode?: 'view' | 'edit';
  onSave?: () => void;
  // onToggle?: (index: number) => any;
  // onRemove?: (index: number) => any;
};

const SubtasksList = React.memo(
  ({ taskId, subtasks, mode = 'view' }: SubtasksListProps) => {
    const localSubtasks = useHookstate(
      useMemo(() => cloneDeep(subtasks), [subtasks]),
    );
    const [newSubtask, setNewSubtask] = useState('');

    const onSubmit: OnSubmit = ({ nativeEvent: { text } }) => {
      const subtask: Subtask = {
        title: text,
        status: 'active',
        creationTime: Date.now(),
      };
      localSubtasks.merge([subtask]);
      setNewSubtask('');
    };

    // Save tasks on unmount
    useComponentWillUnmount(() => {
      const subs = getObjectFromHookstate(localSubtasks);
      if (isEqual(subs, subtasks)) return;
      dispatch(
        changeSubtasks({
          id: taskId,
          subtasks: subs,
        }),
      );
    });

    return (
      <Box>
        {localSubtasks.map((item, index) => {
          return (
            <SubtaskItem
              key={index}
              item={item}
              mode={mode}
              onRemove={() =>
                localSubtasks.set((subs) =>
                  subs.filter((s, idx) => idx !== index),
                )
              }
            />
          );
        })}
        {false && (
          <Input
            variant="unstyled"
            value={newSubtask}
            onChangeText={setNewSubtask}
            onSubmitEditing={onSubmit}
            placeholder="Add subtask"
            blurOnSubmit={false}
          />
        )}
      </Box>
    );
  },
  (prevProps, nextProps) =>
    prevProps.subtasks.length === nextProps.subtasks.length &&
    prevProps.taskId === nextProps.taskId,
);

const styles = StyleSheet.create({
  subtaskContent: { flexDirection: 'row', flex: 1 },
});

export default SubtasksList;
