import { SelectColorModal } from '@/components/ColorsModal';
import ImportanceToggles from '@/components/ImportanceToggles';
import timespacesSlice, {
  createTimespace,
  upsertTimespace,
} from '@/Store/firestore/timespacesSlice';
import { useAppDispatch } from '@/Store/store';
import { CreateTimespaceScreenProps } from '@/types/navigation.type';
import { ITimespace } from '@/types/timespace.type';
import { getObjectFromHookstate } from '@/utils';
import { useHookstate } from '@hookstate/core';
import { Box, Button, Input, Text, useDisclose } from 'native-base';
import React from 'react';
import TimespaceEditor from './components/TimespaceEditor';

export default function CreateTimespaceScreen({
  navigation,
  route,
}: CreateTimespaceScreenProps) {
  const oldTimespace = route.params?.timespace;
  const dispatch = useAppDispatch();
  const timespace = useHookstate<Partial<ITimespace>>(
    () => oldTimespace ?? (createTimespace({ name: '' }) as ITimespace),
  );

  const onSave = (data: ITimespace) => {
    dispatch(upsertTimespace(data));
    navigation.goBack();
  };

  return <TimespaceEditor onSave={onSave} />;
}
