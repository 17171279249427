import {
  RootState,
  store,
  useAppDispatch,
  useAppSelector,
} from '@/Store/store';
import { syncedTasksSelectors } from '@/Store/firestore/tasksSlice';

import { TextInput, TouchableOpacity } from 'react-native';

import {
  Box,
  Input,
  Pressable,
  Row,
  Text,
  TextArea,
  Button,
  Divider,
  ArrowBackIcon,
  Spinner,
} from 'native-base';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useImmer } from 'use-immer';
import { addStory, createStory } from '@/Store/firestore/storiesSlice';
import { ITask } from '@/types/task.type';
import { uniq, without } from 'lodash';
import create from 'zustand';

const selectCompletedTasks = (state: RootState) => {
  const tasks = syncedTasksSelectors.selectAll(state);
  return tasks?.filter((t) => t.status === 'completed');
};

type Props = {
  navigation: any; // TODO:
};

// type Store = {
//   title: string;
//   text: string;
//   tagText: string;
//   tags: string[];
//   setState: (data: Partial<Store>) => void;
// };

// const useStore = create<Store>((set, get) => ({
//   title: '',
//   text: '',
//   tagText: '',
//   tags: [],
//   setState: (data) => set(data),
//   // increasePopulation: () => set((state) => ({ bears: state.bears + 1 })),
//   // removeAllBears: () => set({ bears: 0 }),
// }));

export default function NewStoryScreen({ navigation }: Props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const inputRef = useRef<TextInput>(null);
  const textRef = useRef<TextInput>(null);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [tagText, setTagText] = useState('');
  const [tags, setTags] = useState<string[]>([]);

  const clear = () => {
    setText('');
    setTitle('');
    setTags([]);
    setTagText('');
  };

  const dispatch = useAppDispatch();
  const completedTasks = useAppSelector(selectCompletedTasks);
  const [selectedTasks, updateSelectedTasks] = useImmer<{
    [K: string]: boolean;
  }>({});

  const selectTask = (id: string) =>
    updateSelectedTasks((draft) => {
      if (draft[id] === true) delete draft[id];
      else draft[id] = true;
    });

  const selectAll = () => {
    updateSelectedTasks((draft) => {
      completedTasks.forEach((task) => (draft[task.id] = true));
    });
  };

  const deselectAll = useCallback(() => {
    updateSelectedTasks(() => ({}));
  }, [updateSelectedTasks]);

  const save = useCallback(() => {
    if (!title) return;
    const tasksEntities = syncedTasksSelectors.selectEntities(store.getState());
    const bindedTasks = Object.keys(selectedTasks).map((id) => {
      const t = tasksEntities[id] as ITask & { priority?: number };
      if (t.priority) delete t.priority;
      return t;
    });
    dispatch(
      addStory(
        createStory({
          title,
          content: text,
          tasks: bindedTasks.length > 0 ? bindedTasks : [],
          tags,
          visibility: 'private',
        }),
      ),
    );
    setTitle('');
    setText('');
    deselectAll();
  }, [deselectAll, dispatch, selectedTasks, text, title, tags]);

  const addTag = useCallback((tag) => {
    setTags((tags_) => uniq([...tags_, tag]));
  }, []);

  const removeTag = useCallback((tag) => {
    setTags((tags_) => without(tags_, tag));
  }, []);

  const saveAndExit = useCallback(() => {
    save();
    navigation.goBack();
  }, [save, navigation]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          onPress={() => {
            clear();
          }}
        >
          Clear
        </Button>
      ),
    });
  }, [navigation]);

  // useEffect(() => {
  //   console.log('beforeRemoveUseEffect');
  //   const unsubscribe = props.navigation.addListener('beforeRemove', () => {
  //     save();
  //   });

  //   return unsubscribe;
  // }, [props.navigation, save]);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  return (
    <>
      <KeyboardAwareScrollView style={{ padding: 6 }}>
        <Input
          autoFocus
          multiline
          fontSize="xl"
          value={title}
          onChangeText={setTitle}
          ref={inputRef}
          placeholder="Title"
          variant="unstyled"
          onSubmitEditing={() => textRef.current?.focus()}
        />
        <TextArea
          value={text}
          onChangeText={setText}
          fontSize="md"
          placeholder="Subtitle"
          ref={textRef}
          variant="filled"
          style={{ padding: 5 }}
        />

        <Text pt="4">Tags</Text>
        <Row flexWrap="wrap" alignItems="center">
          <Row>
            <Input
              placeholder="#addtag  "
              variant="unstyled"
              autoCapitalize={'none'}
              value={tagText}
              onChangeText={setTagText}
              onSubmitEditing={() => {
                addTag(tagText.split(' ').join('').toLowerCase());
                setTagText('');
              }}
              blurOnSubmit={false}
            />
          </Row>
          {tags.map((tag) => (
            <TouchableOpacity key="tag" onPress={() => removeTag(tag)}>
              <Text key={tag} m="1">
                #{tag}
              </Text>
            </TouchableOpacity>
          ))}
        </Row>

        <Text pt="10">Assign completed tasks to this story</Text>
        <Row>
          <Button onPress={selectAll} variant="ghost">
            Select all
          </Button>
          <Button onPress={deselectAll} variant="ghost">
            Remove selection
          </Button>
        </Row>

        <Divider />

        <Box flexDirection={'row'} flexWrap="wrap" paddingBottom="10">
          {!isLoaded ? (
            <Spinner />
          ) : (
            completedTasks.slice(0, 100).map((item) => {
              const isSelected = selectedTasks[item.id] === true;
              return (
                <Pressable key={item.id} onPress={() => selectTask(item.id)}>
                  <Box
                    borderWidth={isSelected ? '1' : undefined}
                    borderRadius="md"
                    margin="1"
                    borderColor="blue.200"
                  >
                    <Text fontSize={12} margin="1px">
                      {item.title.slice(0, 100)}
                    </Text>
                  </Box>
                </Pressable>
              );
            })
          )}
        </Box>
      </KeyboardAwareScrollView>

      <Row>
        <Button onPress={saveAndExit} isDisabled={!title} flex="1">
          Save
        </Button>
      </Row>
    </>
  );
}
