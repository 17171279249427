import React, { useLayoutEffect } from 'react';
import { NavigationProp } from '@react-navigation/native';

const useHeaderIcon = (
  navigation: NavigationProp<any>,
  Component: React.ReactNode,
) => {
  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => Component,
    });
  }, [navigation, Component]);
};

export default useHeaderIcon;
