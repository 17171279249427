import { BlankSpacer } from '@/components';
import { useTasksById } from '@/hooks/useTask';
import { ITask, TaskUpdateFunc } from '@/types/task.type';
import { getDurationTime, isLongTerm, isNumber } from '@/utils';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Event } from '@react-native-community/datetimepicker';
import dayjs from 'dayjs';
import { Box, Center, Icon, IconButton, Row, Text, VStack } from 'native-base';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Platform, StyleSheet, TouchableOpacity, View } from 'react-native';
import { CalendarList } from 'react-native-calendars';
import InputSpinner from 'react-native-input-spinner'; // https://github.com/marcocesarato/react-native-input-spinner
import { Headline, Switch } from 'react-native-paper';
import { DateRow } from './DateRow';
import TaskSearchModal from './TasksSearch';

enum DTPickerState {
  close,
  date,
  time,
}

type IOSMode = 'date' | 'time' | 'datetime' | 'countdown';
type AndroidMode = 'date' | 'time';
type Display = 'spinner' | 'default' | 'clock' | 'calendar';
type IOSDisplay = 'default' | 'compact' | 'inline' | 'spinner';
type MinuteInterval = 1 | 2 | 3 | 4 | 5 | 6 | 10 | 12 | 15 | 20 | 30;
type DAY_OF_WEEK = 0 | 1 | 2 | 3 | 4 | 5 | 6;

type Modes = 'close' | 'date' | 'time';

export const ModeDisplayMap: Record<Modes, IOSDisplay | Display | undefined> = {
  close: undefined,
  // close: undefined,
  date: undefined,
  time: undefined,
  ...Platform.select({
    ios: {
      date: 'inline',
      // time: 'spinner',
    } as Partial<Record<IOSMode, IOSDisplay>>,
    // @ts-ignore
    android: {
      date: 'calendar',
    } as Partial<Record<AndroidMode, Display>>,
  }),
  // date: ''
};

console.debug('displaymAp', ModeDisplayMap);

const pickerItems = [{ label: 'item', value: 1 }];

const pickerStates = ['close', 'date', 'time'];

// const maxDuration = 10_000;

type Props = Pick<ITask, 'startDate' | 'dueDate' | 'duration'> & {
  onChange: TaskUpdateFunc;
};

export const toDayStart = (date: Date | dayjs.ConfigType) =>
  dayjs(date).startOf('day').valueOf();

export default function DatesPicker({
  startDate,
  dueDate,
  duration,
  onChange,
}: Props) {
  const [dueState, setDueState] = useState(0);
  const [startState, setStartState] = useState(0);
  const [isObjective, setIsObjective] = useState(isLongTerm(duration));
  const calendarRef = useRef<CalendarList>(null);
  const [depsModalVisible, setDepsModalVisible] = useState(false);
  const hasDependencies = startDate !== null && typeof startDate !== 'number';
  const dependencies = useTasksById(hasDependencies ? startDate : []);

  const startDay = useMemo(
    () => (isNumber(startDate) ? toDayStart(startDate) : null),
    [startDate],
  );
  const dueDay = useMemo(
    () => (isNumber(dueDate) ? toDayStart(dueDate) : null),
    [dueDate],
  );

  const [date, now] = [new Date(), Date.now()];

  const changeMode = useCallback(
    (picker: 'due' | 'start', mode?: 0 | 1 | 2) => {
      const [state, setState] =
        picker === 'start'
          ? [startState, setStartState]
          : [dueState, setDueState];
      setState(mode ?? (state + 1) % pickerStates.length);
    },
    [dueState, startState],
  );

  const onChangeDate = useCallback(
    (picker: 'start' | 'due') => (event: Event, date_?: Date) => {
      if (event.type === 'dismissed') {
        changeMode(picker, 0);
        return;
      }
      const dateType = picker === 'start' ? 'startDate' : 'dueDate';
      const state = picker === 'start' ? startState : dueState;

      changeMode(picker, 0);
      if (state === 1) onChange(dateType, toDayStart(date_));
      else onChange(dateType, new Date(date_ || Date.now()).getTime());
    },
    [startState, dueState, onChange, changeMode],
  );

  const { minutes, hours } = getDurationTime(duration);

  const onChangeDuration = (hrs: number) => {
    onChange('duration', minutes + hrs * 60);
  };

  const onToggleSwitch = (value: boolean) => {
    if (!value) onChange('duration', 0);
    else onChange('duration', 60 * 2);
    setIsObjective(value);
  };

  return (
    <React.Fragment>
      <Box>
        <VStack space={2}>
          <Box>
            <Text>Start date</Text>
            <DateRow
              style={s.dateContainer}
              date={startDate}
              onChange={(time) => onChange('startDate', time)}
            />
          </Box>

          <Box>
            <Text>Due date</Text>
            <DateRow
              style={s.dateContainer}
              date={dueDate}
              onChange={(time) => onChange('dueDate', time)}
            />
          </Box>
        </VStack>

        <Row>
          <TouchableOpacity
            onPress={() => setDepsModalVisible((val) => !val)}
            style={{ marginTop: 6 }}
          >
            {hasDependencies &&
              dependencies?.map((t) =>
                t ? <Text key={t.id}>{t.title}</Text> : undefined,
              )}
            <Center>
              <Text color="muted.500">Add dependency</Text>
            </Center>
          </TouchableOpacity>
          {hasDependencies && (
            <IconButton
              variant="ghost"
              size="sm"
              onPress={() => onChange('startDate', null)}
              icon={<Icon as={MaterialCommunityIcons} name="close" />}
            />
          )}
        </Row>
      </Box>

      <View style={s.inputSpinnerContainer}>
        <BlankSpacer height={20} />
        <View style={s.objectiveSection}>
          <Headline>Objective</Headline>
          <Switch value={isObjective} onValueChange={onToggleSwitch} />
        </View>
        {isObjective && (
          <Text style={{ alignSelf: 'center' }}>
            Set task duration in hours
          </Text>
        )}
        <BlankSpacer height={10} />
        {isObjective && (
          <InputSpinner
            placeholder={'Hours'}
            buttonStyle={s.inputSpinnerButton}
            // buttonTextStyle={s.inputSpinnerButtonText}
            buttonFontSize={40}
            skin="clean"
            prepend={<Text>Min: {2}</Text>}
            append={<Text>Max: {10000}</Text>}
            rounded={false}
            accelerationDelay={300}
            buttonTextColor="black"
            min={2}
            max={1000}
            step={1}
            colorMax={'#f04048'}
            // colorMin={'#40c5f4'}
            value={hours}
            /* convert hours to minutes */
            onChange={onChangeDuration}
          />
        )}
      </View>
      <TaskSearchModal
        visible={depsModalVisible}
        onRequestClose={() => setDepsModalVisible(false)}
        onSave={(tasks) => onChange('startDate', tasks)}
        excludeIds={hasDependencies ? startDate : []}
      />
    </React.Fragment>
  );
}

const s = StyleSheet.create({
  buttonsContainer: {
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
  },
  dateHeading: {
    alignSelf: 'center',
  },
  inputSpinnerButton: {
    backgroundColor: 'transparent',
    // color: 'red',
    // buttonTextColor:
  },
  inputSpinnerButtonText: {
    fontSize: 25,
  },
  inputSpinnerContainer: {
    marginTop: 10,
  },

  objectiveSection: {
    // marginTop: 20,
    flexDirection: 'row',
    // alignItems: 'flex-end',
    justifyContent: 'center',
  },
});
