import prioritizeList from '@/priority';
import settingsSlice from '@/Store/firestore/settingsSlice';
import { syncedTasksSelectors } from '@/Store/firestore/tasksSlice';
import { useAppSelector } from '@/Store/store';
import { useSelectTimeblocks } from '@/Store/Timeblocks';
import { ITask, PrioritizedTask, TaskList } from '@/types/task.type';
import { isObjective } from '@/utils';
import dayjs from 'dayjs';
import produce from 'immer';
import { intersection, uniqBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import create from 'zustand';
import { isDelayed } from './../utils';

export type TasksState = {
  activeTasks: PrioritizedTask[];
  prioritizedTasks: PrioritizedTask[];
  completedTasks: PrioritizedTask[];
  delayedTasks: PrioritizedTask[];
  todos: PrioritizedTask[];
  objectives: PrioritizedTask[];
  todayTasks: PrioritizedTask[];
  _set: (
    partial:
      | TasksState
      | Partial<TasksState>
      | ((state: TasksState) => TasksState | Partial<TasksState>),
    replace?: boolean | undefined,
  ) => void;
};

export type TimerStatus = 'inactive' | 'work' | 'break';

export const useTasksGroups = create<TasksState>((set) => ({
  activeTasks: [],
  prioritizedTasks: [],
  completedTasks: [],
  delayedTasks: [],
  todos: [],
  objectives: [],
  todayTasks: [],
  _set: set,
}));

export function useSubscribeToTasksGroups() {
  const prioritizedTasks = useAppSelector(syncedTasksSelectors.selectAll);
  const weekday = useMemo(() => dayjs().isoWeekday() - 1, []);
  const timeblocks = useSelectTimeblocks();
  const todayBlocks = useMemo(() => timeblocks[weekday], [weekday, timeblocks]);
  // const pomodoroWorkTime = useAppSelector(
  //   (state) => state.settings.pomodoroWorkTime ?? 45, // TODO: Check
  // );
  const elapsed = 45;
  // const weekBlocks = useMemo(
  //   () => [...timeblocks.slice(weekday), ...timeblocks.slice(0, weekday)],
  //   [timeblocks, weekday],
  // );

  return useMemo(() => {
    const now = Date.now();
    const activeTasks: PrioritizedTask[] = [];
    const completedTasks: PrioritizedTask[] = [];
    const delayedTasks: PrioritizedTask[] = [];
    const todos: PrioritizedTask[] = [];
    const objectives: PrioritizedTask[] = [];

    let todayTasks: PrioritizedTask[] = [];

    prioritizedTasks.forEach((item) => {
      if (item.status === 'completed') completedTasks.push(item);
      else if (isDelayed(item, now)) delayedTasks.push(item);
      else {
        if (isObjective(item)) objectives.push(item);
        else todos.push(item);
        activeTasks.push(item);
      }
    });

    // const todayTasks = activeTasks.filter((t) => {

    let possibleToday = prioritizedTasks;

    todayBlocks.forEach((tb) => {
      if (!tb.mainTimespace && tb.timespaces.length === 0) return;
      const startTime = dayjs().hour(tb.start).startOf('hour');
      let i = 0;
      let matches = [];
      for (; i < possibleToday.length; i++) {
        const t = possibleToday[i];
        matches = intersection(t.timespaces, tb.timespaces);
        if (matches.length > 0) break;
        if (t.mainTimespace && tb.mainTimespace) break;
      }
      if (i >= possibleToday.length - 1) return;
      const selectedTask = { ...possibleToday[i] };
      todayTasks.push(selectedTask);

      possibleToday = prioritizeList(
        produce(possibleToday, (draft) => {
          // console.log(draft[0].elapsedTime, elapsed);
          draft[i].elapsedTime += elapsed;
        }),
        startTime.valueOf(),
        undefined,
        true,
      );
    });
    todayTasks = uniqBy(todayTasks, 'id');

    // });
    return {
      activeTasks,
      prioritizedTasks,
      completedTasks,
      delayedTasks,
      todos,
      objectives,
      todayTasks,
    };
  }, [prioritizedTasks, todayBlocks]);
}

export const useGroupedTasksSubscription = () => {
  const groupedTasks = useSubscribeToTasksGroups();
  const setTasks = useTasksGroups((state) => state._set);
  useEffect(() => {
    setTasks(groupedTasks);
  }, [groupedTasks, setTasks]);
};

export default function usePrioritizedTasks() {
  return useTasksGroups();
}
