import { DayNumbers, ITimeblock } from './../../types/timeblock.type';
import { useAppSelector } from '../store';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '@/Store/store';
import { WEEKDAYS, HOURS_IN_DAY } from '@/constants/time';
import range from 'lodash/range';
import { AsyncThunkConfig } from '@/types/types';
import { getCollection, getUser } from '@/utils/firebase';
import { IAppData } from '@/types/appData';

const defaultTimeblocks: ITimeblock[][] = WEEKDAYS.map((day, index) =>
  range(HOURS_IN_DAY).map(
    (h) =>
      ({
        day: index,
        start: h,
        end: h + 1,
        timespaces: [],
        mainTimespace: false,
      } as ITimeblock),
  ),
);

const toggleTimespaceAndReturn = (
  timespaces: ITimeblock['timespaces'],
  name: string,
) => {
  let _timespaces = [...timespaces]; // To make sure we don't mutate anything
  if (_timespaces.includes(name)) {
    _timespaces = _timespaces.filter((item) => item !== name);
  } else {
    _timespaces.push(name);
  }
  return _timespaces;
};

// export const fixTasks = createAsyncThunk(
//   'syncedTasks/changeTaskStatus',
//   async (_, thunk) => {
//     const tasks = await firestore().collection('tasks').get();
//     tasks.forEach(
//       async (taskDoc) =>
//         await firestore()
//           .collection('tasks')
//           .doc(taskDoc.id)
//           .update({ timespaces: ['default'] }),
//     );
//   },
// );

export const updateTimeblocks = createAsyncThunk<
  void,
  ITimeblock[][],
  AsyncThunkConfig
>('timeblocks/uploadTimeblocks', (timeblocks) => {
  const uid = getUser()?.uid;
  if (!uid) return console.error('No uid');
  return getCollection('appData')
    .doc(uid)
    .set({ timeblocks: timeblocks.flat(2) } as Partial<IAppData>, {
      merge: true,
    });
});

export const uploadTimeblocks = createAsyncThunk<
  void,
  undefined,
  AsyncThunkConfig
>('timeblocks/uploadTimeblocks', (_, thunkApi) => {
  const timeblocks = thunkApi.getState().timeblocks.flat(2);
  const uid = getUser()?.uid;
  if (!uid) return console.error('No uid');
  return getCollection('appData')
    .doc(uid)
    .set({ timeblocks } as Partial<IAppData>, { merge: true });
});

type TimespacePayload = { day: DayNumbers; start: number; name: string };

export const timeblocksSlice = createSlice({
  name: 'timeblocks',
  initialState: defaultTimeblocks,
  reducers: {
    setAllTimeblocks: (state, action: PayloadAction<ITimeblock[][]>) => {
      return action.payload;
    },
    resetTimeblocks: () => {
      return defaultTimeblocks;
    },
    setAllTimespaces: (state, action: PayloadAction<ITimeblock[][]>) => {
      return action.payload;
    },

    addTimespace: (state, action: PayloadAction<TimespacePayload>) => {
      const p = action.payload;
      state[p.day][p.start].timespaces.push(p.name);
    },

    removeTimespace: (state, action: PayloadAction<TimespacePayload>) => {
      const p = action.payload;
      const _timespaces = state[p.day][p.start].timespaces.filter(
        (ts) => ts !== p.name,
      );
      state[p.day][p.start].timespaces = _timespaces;
    },

    toggleTimespace: (state, action: PayloadAction<TimespacePayload>) => {
      const p = action.payload;
      let _timespaces = state[p.day][p.start].timespaces;
      if (_timespaces.includes(p.name)) {
        _timespaces = _timespaces.filter((item) => item !== p.name);
      } else {
        _timespaces.push(p.name);
      }
      state[p.day][p.start].timespaces = _timespaces;
    },

    toggleTimespacesMultiple: (
      state,
      action: PayloadAction<{ timeblocks: ITimeblock[]; name: string }>,
    ) => {
      const p = action.payload;
      p.timeblocks.forEach((tb) => {
        state[tb.day][tb.start].timespaces = toggleTimespaceAndReturn(
          tb.timespaces,
          p.name,
        );
      });
    },

    clearTimespaces: (
      state,
      action: PayloadAction<{ day: DayNumbers; start: number }>,
    ) => {
      const p = action.payload;
      state[p.day][p.start].timespaces = [];
    },
  },
});

export const {
  setAllTimeblocks,
  setAllTimespaces,
  addTimespace,
  clearTimespaces,
  resetTimeblocks,
  removeTimespace,
  toggleTimespace,
  toggleTimespacesMultiple,
} = timeblocksSlice.actions;

export const selectTimeblocks = (state: RootState) => state.timeblocks;
export const selectTimeblock =
  (day: DayNumbers, start: number) => (state: RootState) =>
    state.timeblocks[day][start];

export const useSelectTimeblocks = () => {
  return useAppSelector(selectTimeblocks);
};

// export const useSelectStory = (id?: IStory['id']) => {
//   return useAppSelector((state: RootState) => {
//     if (!id) return undefined
//     return state.stories.find(story => story.id === id)
//   })
// }

export default timeblocksSlice.reducer;
