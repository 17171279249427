import { SelectColorModal } from '@/components/ColorsModal';
import AppIcon from '@/components/icons/AppIcon';
import { POPULAR_COLOR_NAMES } from '@/constants/colors';
import { getCheckboxIconName } from '@/Containers/Tasks/components/TaskListItem';
import useImmerState from '@/hooks/useImmerState';
import { useTasksGroups } from '@/hooks/usePrioritizedTasks';
import { useTasks } from '@/hooks/useTask';
import projectsSlice, { createProject } from '@/Store/firestore/projectsSlice';
import { syncedTasksSelectors } from '@/Store/firestore/tasksSlice';
import { useAppDispatch, useAppSelector } from '@/Store/store';
import { IProject } from '@/types/project.type';
import { ITask } from '@/types/task.type';
import {
  Box,
  Button,
  Checkbox,
  Column,
  TextArea,
  Input,
  Modal,
  Row,
  ScrollView,
  Text,
  useDisclose,
  useToken,
  Spinner,
  Radio,
} from 'native-base';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Dimensions } from 'react-native';

export const COLOR_WIDTH = Math.floor(
  Dimensions.get('screen').width / POPULAR_COLOR_NAMES.length,
);

export const TasksSelectModal = ({
  tasks,
  selectedTasks,
  onChange,
  isOpen,
  onClose,
}: {
  tasks: ITask[];
  selectedTasks: string[];
  onChange: (values: string[]) => void;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const [filterText, setFilterText] = useState('');
  const [statusFilter, setStatusFilter] = React.useState<
    'all' | 'completed' | 'active'
  >('all');

  const filteredTasks = useMemo(() => {
    const filteredByStatus =
      statusFilter === 'all'
        ? tasks
        : tasks.filter((t) => t.status === statusFilter);
    return filteredByStatus.filter((i) => i.title.includes(filterText));
  }, [statusFilter, tasks, filterText]);

  // const Tasks = useMemo(() => {
  //   return filteredTasks.map((t) => (
  //     <Checkbox key={t.id} value={t.id}>
  //       {t.title}
  //     </Checkbox>
  //   ));
  // }, [filteredTasks]);

  if (!isMounted) return null;
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Content
        marginTop={20}
        marginBottom="auto"
        height={'3/4'}
        width={'5/6'}
      >
        <Modal.CloseButton />
        <Modal.Header>Add tasks</Modal.Header>
        <Input
          leftElement={<AppIcon ml="2" pr="2" name="text-search" size={5} />}
          placeholder="Task name"
          pl="4"
          mx="1"
          variant="filled"
          onChangeText={setFilterText}
          mb="2"
          blurOnSubmit
        />
        <Radio.Group
          name="myRadioGroup"
          accessibilityLabel="favorite number"
          value={statusFilter}
          flexDirection="row"
          onChange={(nextValue) => {
            setStatusFilter(nextValue as typeof statusFilter);
          }}
          px="2"
          justifyContent={'space-around'}
          // flex="1"
        >
          <Radio value={'all'} my={1}>
            All
          </Radio>
          <Radio value="active" my={1}>
            Active
          </Radio>
          <Radio value="completed" my={1}>
            Completed
          </Radio>
        </Radio.Group>
        <Modal.Body>
          <Checkbox.Group
            onChange={onChange}
            value={selectedTasks}
            accessibilityLabel="choose numbers"
          >
            {/* {Tasks} */}
            {isMounted && isOpen ? (
              filteredTasks.map((t) => (
                <Checkbox key={t.id} value={t.id}>
                  {t.title}
                </Checkbox>
              ))
            ) : (
              <Spinner />
            )}
          </Checkbox.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button flex="1" onPress={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default function ProjectEditor({
  project: _project,
}: {
  project?: IProject;
}) {
  const dispatch = useAppDispatch();

  const { prioritizedTasks } = useTasksGroups();
  const taskEntities = useAppSelector(syncedTasksSelectors.selectEntities);

  const [project, setProject, update] = useImmerState(
    _project ?? createProject({ name: '' }),
  );

  const [projectTasks, setProjectTasks] = useState<string[]>(
    _project?.tasks ?? [],
  );

  const { isOpen, onClose, onToggle } = useDisclose();

  const backgroundColor = useToken('colors', 'background');
  const isValid = !!project.name;

  const {
    isOpen: isColorOpen,
    onClose: onColorClose,
    onOpen: onColorOpen,
  } = useDisclose();

  const save = () => {
    if (!isValid) return;
    // const newProject = getObjectFromHookstate(project) as IProject;
    dispatch(
      projectsSlice.actions.upsertProject({
        ...project,
        tasks: projectTasks,
      }),
    );
    setProject(createProject({ name: '' }));
    setProjectTasks([]);
    onClose();
    onColorClose();
  };

  return (
    <>
      <Box flex={1} backgroundColor={backgroundColor}>
        <ScrollView>
          <Column space="md">
            <Input
              placeholder="Project name"
              value={project.name}
              onChangeText={update.name}
            />
            <TextArea
              placeholder="Description"
              value={project.description}
              onChangeText={update.description}
            />
            <Button flexDirection={'row'} onPress={onColorOpen}>
              <Text>Color: {project.color}</Text>
            </Button>
            <SelectColorModal
              isOpen={isColorOpen}
              onClose={onColorClose}
              onSelect={(color) => update.color(color)}
            />
          </Column>

          <Button p="2" onPress={onToggle}>
            {isOpen ? 'Save tasks' : 'Select tasks'}
          </Button>

          <Column mt="2">
            {projectTasks.map((id) => {
              const task = taskEntities[id];
              if (!task) return null;
              return (
                <Row key={task.id}>
                  <AppIcon name={getCheckboxIconName(task)} />
                  <Text px="2">{task.title}</Text>
                </Row>
              );
            })}
          </Column>
        </ScrollView>
      </Box>

      <TasksSelectModal
        isOpen={isOpen}
        onChange={setProjectTasks}
        onClose={onClose}
        selectedTasks={projectTasks}
        tasks={prioritizedTasks}
      />

      <Button onPress={save} isDisabled={!isValid}>
        Save
      </Button>
    </>
  );
}
