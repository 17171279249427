import { Vibration, Platform } from 'react-native'

const ios = Platform.OS === 'ios'
const android = Platform.OS === 'android'

// TODO check on IOS

const AppVibration = {
  ...Vibration,
  touch() {
    if (android) Vibration.vibrate(15)
    // else if (ios) Vibration.vibrate()
  },
  short() {
    if (android) Vibration.vibrate(50)
    else if (ios) Vibration.vibrate()
  },
  long() {
    if (android) Vibration.vibrate(100)
    else if (ios) Vibration.vibrate()
  },
}

export default AppVibration
