// TODO https://www.npmjs.com/package/color

import defaultTheme from '@/Theme/base';
import { ThemeColors, ThemeNavigationColors } from '@/Theme/theme.type';

const Colors: ThemeColors = {
  primary: defaultTheme.colors.neutral.secondary,
  text: defaultTheme.colors.neutral.secondary,
  // inputBackground: 'gray',
};

const NavigationColors: Partial<ThemeNavigationColors> = {
  primary: Colors.primary,
};

export default {
  Colors,
  NavigationColors,
};
