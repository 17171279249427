import type { ITask } from '../../types/task.type';
import { update } from '@/utils';
import fb, { getDoc } from '@/utils/firebase';

/**
 * Create task from params
 * @param {string} title - title of the new task
 * *TODO Consider changing to class
 */
export const createTask = (props?: Partial<ITask>): ITask => {
  // Get unix time in seconds
  // const unixTimeSec = Math.floor(time / 1000);
  const id = getDoc('tasks', props?.id).id;
  const ownerId = fb.auth.currentUser?.uid;
  if (!ownerId) throw new Error('No user id');
  return update({
    id,
    ownerId,
    origin: 'tastik',
    title: props?.title ?? id,
    status: 'active',
    notes: '',
    subtasks: [],
    importance: 2,
    dependencies: [],
    timespaces: [],
    mainTimespace: true,
    goalId: null, // TODO: Replace with projectId
    projectId: null,
    startDate: Date.now(),
    dueDate: null,
    duration: 0,
    elapsedTime: 0,
    ...props,
  });
};
