import { isObjective, isNumber } from '@/utils';
import { TaskList, ITask, PrioritizedTask, date } from '@/types/task.type';
import { NonNegativeInteger } from 'types';
import { debounce, uniqueId } from 'lodash';

const MINUTE_IN_MS = 1000 * 60;
const DAY_IN_MS = 1000 * 60 * 60 * 24;

// Start from minutes
const MONTH = 60 * 24 * 30;
const WEEK = 60 * 24 * 7;

type PositiveOrZero<T extends number> = T extends NonNegativeInteger<T>
  ? NonNegativeInteger<T>
  : 0;

/** Convert value to minutes. */
const toMinutes = (ms: number) => Math.floor(ms / MINUTE_IN_MS);
const toDays = (ms: number) => Math.floor(ms / DAY_IN_MS);

// To natural numbers eg 0, 1, 2
const toNat = <T extends number>(num: T) =>
  Math.max(num, 0) as PositiveOrZero<T>;

export function getPriority(
  task: ITask,
  time: date = Date.now(),
  sessionTime = 45,
  decreaseWhenElapsed = false,
) {
  // status
  if (task.status !== 'active') return 0;
  else if (!task.startDate) return 0;
  else if (isNumber(task.startDate) && time < task.startDate) return 0;
  else if (!isNumber(task.startDate) && task.startDate !== null) return 0;

  let priority = 0;

  priority += 1000 * task.importance;
  priority += task.duration - (decreaseWhenElapsed ? task.elapsedTime : 0);
  // If it is a simple task then give it more priority
  priority += task.duration === 0 ? 500 : 0;
  // priority += task.elapsedTime / 3;

  const basicPriority = priority;

  if (!task.dueDate) {
    priority += toDays(time - task.startDate);
    return priority;
  }

  const t0 = toMinutes(task.startDate);
  const tx = toMinutes(time);
  const t = toMinutes(task.dueDate);
  const td = task.duration || 60;
  const lifetime = t - t0;
  const timeLeft = toNat(t - tx);
  priority += toNat(MONTH + task.duration - timeLeft);
  // if (task.duration && task.elapsedTime && task.dueDate) {
  //   // const timeLeftToSpend = td - task.elapsedTime;
  // }
  // priority -= timeLeft - task.elapsedTime; //timeLeftToSpends

  // const timeTillDeadline = notNegative(toMinutes(task.dueDate - time));
  // priority += task.duration - timeLeft;
  // priority += lifetime - timeLeft;
  // if (timeLeft * lifetime > 0) priority *= lifetime / timeLeft;
  // priority += -timeLeft - task.duration;
  // const td = task.duration || 60;
  // const timeLeftToSpend = td - task.elapsedTime;
  // priority -= timeLeft - task.elapsedTime; //timeLeftToSpend
  const lostTime = Math.max(tx - (t - td + task.elapsedTime), 0);
  priority += Math.max(lostTime, 0) * task.importance;

  // if (!isNumber(task.startDate)) return 0;

  // if (time < task.startDate) {
  // } else if (isNumber(task.dueDate)) {
  //   // priority += 1000;
  //   const diff = task.dueDate - task.startDate;
  //   const startDueDiff = toMinutes((task.dueDate - task.startDate) / 2);
  //   const dueDiff = toMinutes(time - task.dueDate);
  //   const duePriority = Math.max(dueDiff + startDueDiff, 0);
  //   priority += duePriority;

  //   // Start priority
  // }
  // const startDiff = toMinutes((time - task.startDate) / 200);
  // const startPriority = startDiff;
  // priority += startPriority;

  // const elapsedRatio = Math.min(task.elapsedTime / task.duration || 1, 1);
  // // priority = priority - elapsedRatio * priority;
  // if (task.duration !== 0) {
  //   console.debug(task.elapsedTime, task.duration);
  //   console.debug('elapsedRatio', elapsedRatio);
  // }
  // } else if (task.duration < task.elapsedTime) {
  //   priority += task.duration - task.elapsedTime;
  // }

  // priority -= timeTillDeadline;
  // if (timeTillDeadline * timeLeftToSpend > 0)
  //   priority *= timeLeftToSpend / timeTillDeadline;
  // const ratio = ;
  // priority *= ratio || 1;
  // task.elapsedTime -

  // priority += (task.duration - task.elapsedTime) * 1.5;

  // dueDate
  // duration
  // importance
  // elapsedTime
  // startDate
  // dependencies
  // priority *= task.importance;
  // priority /= task.elapsedTime - task.duration || 1 / (task.duration || 1);

  // Decrease priority for partly completed tasks
  if (!decreaseWhenElapsed) {
  } else if (task.duration > 0) {
    const completion = 1 - task.elapsedTime / (task.duration || 1);
    priority *= completion;
  } else if (task.elapsedTime > sessionTime) {
    priority /= task.elapsedTime;
  }

  // x = x + 2 - 4
  // priority += task.duration - task.elapsedTime;
  // priority = priority / (task.elapsedTime || 1) / (task.duration || 1);

  console.log(task.title, priority, basicPriority);
  // return priority;
  return priority < basicPriority ? basicPriority : priority;
  // return priority === 0 ? basicPriority : priority;
}

export default function prioritizeList(
  tasks: TaskList,
  time = Date.now(),
  sessionTime = 45,
  decreaseWhenElapsed = false,
): PrioritizedTask[] {
  console.debug('Call prioritization', uniqueId());
  const res = tasks.map((task) => {
    return {
      ...task,
      priority: getPriority(task, time, sessionTime, decreaseWhenElapsed),
    };
  });
  // This is unnecessary, because redux automatically prioritizes all tasks
  const prioritized = res.sort((a, b) => b.priority - a.priority);
  return prioritized;
}

export const prioritizeListDebounce = debounce(prioritizeList, 1000, {
  leading: true,
});

// if (isObjective(task)) {
//   const hoursLeft = (new Date(task.dueDate) - Date.now()) / 1000 / 3600
//   priority = task.duration * task.importance // * (1 / Math.abs(hoursLeft)) ;
// } else {
// }
