import { RootNavigator } from '@/Navigators';
import { persistor, store } from '@/Store/store';
import AppLoading from 'expo-app-loading';
import { extendTheme, NativeBaseProvider } from 'native-base';
import React, { useEffect } from 'react';
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { useComfortaaFont, useMulishFont } from '../config/fonts';
import { AppStatusProvider } from './Context/AppStatusContext';
import { AppThemeProvider } from './Context/AppThemeContext';
import defaultTheme from './Theme/base';
import './Translations';
import { getEnv } from './utils/env';

console.info(`Running in ${__DEV__ ? 'DEVELOPMENT' : 'PRODUCTION'} mode`);
console.log('NODE_ENV:', getEnv('NODE_ENV'));
console.log('CHANNEL:', getEnv('CHANNEL'));

// if (
//   Platform.OS === 'android' &&
//   UIManager.setLayoutAnimationEnabledExperimental
// ) {
//   UIManager.setLayoutAnimationEnabledExperimental(true);
// }

const nbTheme = extendTheme(defaultTheme);

export function App() {
  const [fontsLoaded] = useMulishFont();
  useComfortaaFont();

  useEffect(() => {
    // uploadTasks();
  }, []);

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return (
    <Provider store={store}>
      {/**
       * PersistGate delays the rendering of the app's UI until the persisted state has been retrieved
       * and saved to redux.
       * The `loading` prop can be `null` or any react instance to show during loading (e.g. a splash screen),
       * for example `loading={<SplashScreen />}`.
       * @see https://github.com/rt2zz/redux-persist/blob/master/docs/PersistGate.md
       */}
      <PersistGate loading={null} persistor={persistor}>
        {/* App theme depends on Redux persisted state. Please do not initiate it before redux loads its state.*/}
        <AppThemeProvider>
          <SafeAreaProvider>
            {/* <SafeAreaView
              style={{ backgroundColor: nbTheme.colors.background, flex: 1 }}
            > */}
            <NativeBaseProvider theme={nbTheme}>
              <AppStatusProvider>
                <RootNavigator />
              </AppStatusProvider>
            </NativeBaseProvider>
            {/* </SafeAreaView> */}
          </SafeAreaProvider>
        </AppThemeProvider>
      </PersistGate>
    </Provider>
  );
}
